<template>
  <div style="width: 100%; height: 100%">
    <ag-grid-vue style="width: 100%; height: 100%" class="ag-theme-wts" :columnDefs="grid.columnDefs"
      :rowData="grid.rowData" :headerHeight="rowHeight" :rowHeight="rowHeight" @grid-ready="onGridReady" />
  </div>
</template>

<script>
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
import { AgGridVue } from "ag-grid-vue"; // Vue Grid Logic
import PaginationComp from '@/components/agTables/PaginationComp.vue';
import AgBtn from '@/components/agTables/AgBtn.vue';
import { buySellType, cellClassRulesMinus } from '@/modules/grid-utils.js';
import gridUtils from '@/modules/grid-utils';

export default {
  name: "agCoinPendingPositionTablePartner",
  components: {
    AgGridVue,
    PaginationComp,
    AgBtn,
  },
  props: {
    setGridSearch: {
      type: Object
    },
    symbolList: {
      type: Array
    },
  },
  watch: {
    setGridSearch: {
      handler(newVal) {
        this.gridSearch = newVal;
        this.search();
      },
      deep: true
    }
  },
  data() {
    return {
      grid: {
        columnDefs: [
          {
            headerName: "ID",
            field: "usrId",
            minWidth: 50,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
            sortable: true,
          },
          {
            headerName: "이름",
            field: "usrNm",
            minWidth: 50,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
            sortable: true,
          },
          {
            headerName: "주문번호",
            field: "ordId",
            minWidth: 50,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
            sortable: true,
          },
          {
            headerName: "종목",
            field: "symCd",
            minWidth: 50,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
            sortable: true,
          },
          {
            headerName: "구분",
            field: "ordSdCd",
            minWidth: 50,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
            sortable: true,
            cellClassRules: buySellType,
          },
          {
            headerName: "수량",
            field: "ordQty",
            minWidth: 50,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
            sortable: true,
            valueFormatter: gridUtils.numberComma
          },
          // { 
          //   headerName: "미체결량",
          //   field: "ordQty",
          //   minWidth: 50,
          //   flex: 1,
          //   cellStyle: {
          //     justifyContent: 'center'
          //   },
          //   headerClass: "center-align-header",
          //   suppressMovable: true,
          // },
          {
            headerName: "주문가",
            field: "ordPrc",
            minWidth: 50,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
            sortable: true,
          },
          /*    { 
                headerName: "관리자취소",
                field: "actionName",
                minWidth: 50,
                flex: 1,
                cellStyle: {
                  justifyContent: 'center'
                },
                headerClass: "center-align-header",
                suppressMovable: true,
                cellRenderer: 'AgBtn',
                cellRendererParams: { actionName: '취소' },
              },*/
        ],
        rowData: [],
        autoSizeStrategy: null,
        rowHeight: '',
      },
      gridApi: null,
      gridSearch: null,
    };
  },
  created() {
    this.isMobile();
  },
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
    },
    isMobile() {
      if (window.innerWidth <= 1200) {
        return this.rowHeight = 40;
      } else {
        return this.rowHeight = 30;
      }
    },
    async search() {
      this.grid.rowData = [];
      if (this.gridSearch.usrId == "") { return }
      const response = await this.$lhttp.post('/api/coin/partner/usliveordlist', this.gridSearch);

      if (response && response.data) {
        if (response.data.length > 0) {
          this.grid.rowData = [];
          response.data.forEach(data => {
            //실시간 
            data.valPl = 0; //실시간손익
            data.totValPl = 0; //평가담보금
            data.nowPrice = 0; //현재시세
            data.actionName = '취소';
            this.grid.rowData.push(data);
          })
        } else {
          this.grid.rowData = [];
        }
      }
    },
  },
};

</script>

<style lang="scss" scoped>
@import '@/assets/scss/agGridStyles.scss';
</style>