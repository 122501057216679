import { stringCount } from '@/modules/helper';
import bigDecimal from 'js-big-decimal';

// object 초기화
const initObject = function (object, deep) {
    let self = this;
    self.obj = object;
  
    for( var key in object ) {
      switch ( typeof object[key] ) {
        case 'number':          // 정수
          self.obj[key] = 0;   
          break;
        case 'boolean':         // boolean
          self.obj[key] = false;   
          break;
        case 'array':           // array
          self.obj[key] = [];   
          break;
        case 'object':          // object
          if (deep) {
            initObject(self.obj[key]);
          } else {
            self.obj[key] = {};
          }
          break;                
        default:                // 문자
          self.obj[key] = '';
      }    
    }
  }
  
  // 숫자 3자리 콤마
  const numberComma = function( value ) {
    var len, point, str; 
         
    if ( value ) {
      var isMinus = (value < 0) ? true : false;
      value = Math.abs(value) + ""; 
      var pointValue = '';
  
      if ( value.indexOf('.') > -1 ) {
        var arrValue = value.split('.');
        value = arrValue[0];
        pointValue = '.'+ arrValue[1];
      }
  
      point = value.length % 3 ;
      len = value.length; 
     
      str = value.substring(0, point); 
      while (point < len) { 
          if (str != "") str += ","; 
          str += value.substring(point, point + 3); 
          point += 3; 
      } 
  
      if (pointValue != '') {
        str += pointValue;
      }
  
      if (isMinus) {
        str = '-'+ str;
      }
  
    } else {
      str = value;
    }
     
    return str;
  }

// 오늘 날짜 yyyy-MM-dd
const getToday = function( ) {
  var d = new Date()

  return getDateStr(d)
}

// ?일전 날짜
const getDatePrevDay = function(day) {
  var d = new Date()
  var dayOfMonth = d.getDate()
  d.setDate(dayOfMonth - day)

  return getDateStr(d)
}

// ?달전 날짜
const getDatePrevMonth = function(month) {
  var d = new Date()
  var monthOfYear = d.getMonth()
  d.setMonth(monthOfYear - month)

  return getDateStr(d)
}

// date yyyy-MM-dd
const getDateStr = function( date ) {
  var month = '' + (date.getMonth() + 1), 
  day = '' + date.getDate(), 
  year = date.getFullYear(); 
  
  if (month.length < 2) month = '0' + month; 
  if (day.length < 2) day = '0' + day; 
  
  return [year, month, day].join('-');
}

const getUuidv4 = function() {
  return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  )
}  

/**
 * NVL: expr1 값이 null(undefined) 또는 공백인 경우 expr2 값을 반환, 그렇지 않으면 expr1 값 반환
 * @return expr1
 */
const nvl = function(expr1, expr2) {
	if (expr1 === undefined || expr1 == null || expr1 == "") {
		expr1 = expr2;
	}
	return expr1;
}

// 배열에 값 추가
const addValueInArray = function(array, value) {
  if ( array !== null && Array.isArray(array) ) {
    if ( array.indexOf(value) < 0 )  {
      array.push(value)
    }
  }
}

// 배열에 값 삭제
const delValueInArray = function(array, value) {
  if ( array !== null && Array.isArray(array) ) {
    for ( let i = 0; i < array.length; i++ ) {
      if(array[i] === value)  {
        array.splice(i, 1)
        i--;
      }
    }
  }
}

/**
 * 우측문자열채우기
 * @params
 *  - str : 원 문자열
 *  - padLen : 최대 채우고자 하는 길이
 *  - padStr : 채우고자하는 문자(char)
 */
const rpad = function(str, padLen, padStr) {
  if (padStr.length > padLen) {
      console.log("오류 : 채우고자 하는 문자열이 요청 길이보다 큽니다");
      return str + "";
  }
  str += ""; // 문자로
  padStr += ""; // 문자로
  while (str.length < padLen)
      str += padStr;
  str = str.length >= padLen ? str.substring(0, padLen) : str;
  return str;
}

//현재시간
const getNowTime = function(){
  const curr = new Date();
  // UTC 시간 계산
  const utc = curr.getTime() + (curr.getTimezoneOffset() * 60 * 1000);

  // UTC to KST (UTC + 9시간)
  const KR_TIME_DIFF = 9 * 60 * 60 * 1000;
  const kr_curr = new Date(utc + (KR_TIME_DIFF));

  const month = String(kr_curr.getMonth()+1).padStart(2,"0");
  const date = String(kr_curr.getDate()).padStart(2,"0");
  const h = String(kr_curr.getHours()).padStart(2,"0");
  const m = String(kr_curr.getMinutes()).padStart(2,"0");
  const s = String(kr_curr.getSeconds()).padStart(2,"0");
  // const weekday = ["일", "월", "화", "수", "목", "금", "토"];
  // const day = weekday[kr_curr.getDay()];
  //var korGmt = `GMT+09:00 ${month}-${date}`

  //this.serverGmt = korGmt;
  const serverTime = `${h}${m}${s}`;
  return serverTime;
}

//종목 소수점 자리수
const tikDotSz = function(symbol){
  if (symbol == null){
    return 0;
  }
  return stringCount(symbol.pricescale);
}

//종목별 틱
const tikVal = function(symbol){
  if (symbol == null){
    return 0;
  }
  return (symbol.minmov / symbol.pricescale);
}

const symbolCalPrc = function(data, ovcData, symbolList, usdExr, usdExHkd, hkdExR, lvTpCd, currency){
  let resData = {data:data, valProLoss: 0};
  let symbolData = null;
  let pricescale = 1;
    symbolList.forEach(item => {
      if (item.name == data.symCd){
        symbolData = item;
        if (item.pricescale > 0){
          pricescale = item.pricescale;
        }
        return;
      }
    });

  if (ovcData != undefined){
    //현재가
    data.nowPrice = Number(ovcData.curPr).toFixed(tikDotSz(symbolData));

    //평가손익 계산
    let val1 = 0;
    let val2 = 0;
    let valProLoss = 0;
    let trdUnt = data.trdUnt;
    if (lvTpCd != undefined && lvTpCd == "Y"){ //레버리지 여부
      trdUnt = data.lvTrdUnt; //레버리지 금액 / 배율 한 금액
      usdExr = 1;
      usdExHkd = 1;
      hkdExR = 1;
    } 
    if (ovcData.curPr == 0 || data.ordSdCd == 0){
      valProLoss = 0;
    }else{
      if (data.ordSdCd == "1"){ //매도
        val1 = ((data.execPrc.toFixed(tikDotSz(symbolData)) * data.balQty) *  pricescale);
        val2 = ((ovcData.curPr * data.balQty) * pricescale);
      }else if (data.ordSdCd == "2"){ //매수
        val1 = ((ovcData.curPr * data.balQty) * pricescale) ;
        val2 = (((data.execPrc.toFixed(tikDotSz(symbolData)) * data.balQty) * pricescale));
      }

      //소수점 계산에서 오차가 발생 ex> 0.0001 + 0.0009 = 0.000999 같은경우
      //1. execPrc의 평균가를 toFixed로 종목별 자릿수 만큼 반올림
      //2. pricescale 곱하여 소수점없이 계산
      //3. val1,val2 계산된값에 trunc을 하여 소수점을 버림
      //4. 최종 가격에서 pricescale로 나눠서 정상 가격을 표시

      //달러로 계산
      if (data.symNo == "2"){ //홍콩달러
        valProLoss = ((val1 - val2) * trdUnt); //홍콩달러계산
      }else{
        valProLoss = ((val1 - val2) / tikVal(symbolData)) * trdUnt; //달러로 계산
      }
      
      if (isNaN(valProLoss)) valProLoss = 0;

      //원화로 표시
      if (currency == "WON"){
        if (data.symNo == "2"){
          valProLoss = Math.round((valProLoss * hkdExR) / pricescale);
          data.valPl = valProLoss;
        }else if (data.symNo == "10"){
          //국내선물
          valProLoss = Math.round(valProLoss) / pricescale;
          data.valPl = valProLoss;
        }else{
          //원화는 통화를 원화로 변경
          valProLoss = Math.round((valProLoss * usdExr) / pricescale);
          data.valPl = valProLoss;              
        }                  
      }else{ //달러
        if (data.symNo == "2"){
          valProLoss = (valProLoss * usdExHkd);
          data.valPl = valProLoss.toFixed(2);
        }else if (data.symNo == "10"){
          valProLoss = (valProLoss / usdExr);
          data.valPl = valProLoss.toFixed(2);
        }else{
          data.valPl = valProLoss.toFixed(2);
        }
      }

      resData.data = data;
      resData.valProLoss = valProLoss;
    }
  }

  return resData;
}

//코인 평가손익 계산
const coinSymbolCalPrc = function(data, cocData, coinSymbolList, lv, buffer){
  let resData = {data:data, valProLoss: 0, crossValPl: 0, isolUsdt: 0};
  let symbolData = null;
  let pricescale = 1;

  coinSymbolList.forEach(item => {
      if (item.name == data.symCd){
        symbolData = item;
        if (item.pricescale > 0){
          pricescale = item.pricescale;
        }
        return;
      }
    });

  if (cocData != undefined){
    //현재가
    data.nowPrice = Number(cocData.curPr).toFixed(tikDotSz(symbolData));

    //평가손익 계산
    let val1 = 0;
    let val2 = 0;
    let valProLoss = 0;

    let pnl = 0; //평가손익 usdt
    let roi = 0; //평가손익 %
    let entryUsdt = 0;

    let isolUsdt = 0; //격리 사용금액

    if (cocData.curPr == 0 || data.ordSdCd == 0){
      valProLoss = 0;
    }else{
        //subtract 빼기
        //add 더하기
        //multiply 곱하기
        //divide 나누기

        // data.balQty = 0.202;
        // curPr = 91020.0;
        // data.execPrc = 90927.91;

        //소수점 3자리 버림처리
        //let execPrc = Math.floor(data.execPrc * 100) / 100;

        let execPrc = Number(data.execPrc);

        //console.log("execPrc>>> ", execPrc)

      if (data.ordSdCd == "1"){ //매도
        // (구매가 / 현재가 -1) * 구매usdt = 손익usdt
        val1 = bigDecimal.multiply(execPrc, data.balQty);
        val2 = bigDecimal.multiply(cocData.curPr, data.balQty);
        entryUsdt = val1;
      }else if (data.ordSdCd == "2"){ //매수
        // (현재가 / 구매가 -1) * 구매usdt = 손익usdt
        val1 = bigDecimal.multiply(cocData.curPr, data.balQty);
        val2 = bigDecimal.multiply(execPrc, data.balQty);
        entryUsdt = val2;
      }

      //청산가 계산
      if (data.marginMode == "2"){ //격리 (isolated)
        let coinSymbolInfoList = window.$store.getters['Symbol/getCoinSymbolInfoList'];

        let coinSymbolInfo = null;

        //종목별 버퍼 조회
        for (let item of coinSymbolInfoList){
          if (item.symCd == data.symCd){
            coinSymbolInfo = item;
            break;
          }
        }

        let mrgAmt = data.mrgAmt; //마진비율금액
        let isBuffer = bigDecimal.divide(coinSymbolInfo.isBuffer, 100); //버퍼퍼센트 100분율로 만듬
        let posAmt = bigDecimal.multiply(data.execPrc, data.balQty); //진입금액 * 수량

        let clrCmsR = bigDecimal.divide(coinSymbolInfo.clrCmsR, 100); //청산수수료율 100분율로 만듬
        let clrCms = bigDecimal.multiply(clrCmsR, posAmt); //체결가로 수수료 계산
        

        //버퍼비율 적용
        let isBufferRat = bigDecimal.subtract(1, isBuffer);
        

        //청산가격비율
        let clsRat = bigDecimal.subtract(mrgAmt, clrCms); //수수료 빼기
        clsRat = bigDecimal.multiply(clsRat, isBufferRat); //버퍼비율 곱하기
        clsRat = bigDecimal.divide(clsRat, posAmt, 10, bigDecimal.ROUND_CEILING); //포지션금액 나누기        
     
        //청산비율 계산
        let clsAmtRat = 1;
        if (data.ordSdCd == "1") { //매도
          clsAmtRat = bigDecimal.add(clsAmtRat, clsRat);
        }else if (data.ordSdCd == "2") { //매수
          clsAmtRat = bigDecimal.subtract(clsAmtRat, clsRat);
        }

        let dotCnt = coinSymbolInfo.dotCnt;
        if (dotCnt == 0){
          dotCnt = 1;
        }else if (dotCnt == 1){
          dotCnt = 10;
        }

        //청산가격
        //진입금액 * 청산비율
        let clsAmt = bigDecimal.multiply(data.execPrc, clsAmtRat);
        clsAmt = bigDecimal.multiply(clsAmt, dotCnt); //소수점개수만큼 곱하기

        //청산금액을 올림, 내림
        if (data.ordSdCd == "1") { //매도
          clsAmt = bigDecimal.ceil(clsAmt); //소수점 올림
        }else if (data.ordSdCd == "2") { //매수
          clsAmt = bigDecimal.floor(clsAmt); //소수점 버림
        }

        clsAmt = bigDecimal.divide(clsAmt, dotCnt); //소수점개수로 곱하여 소수점 원복
        data.liqPrc = Number(clsAmt).toFixed(coinSymbolInfo.dotCnt);


        //COALESCE(C.MRG_AMT,0) - (COALESCE(C.EXEC_PRC * C.BAL_QTY, 0) / C.LV)

        //mrgAmt posAmt
        
        
        // (COALESCE(C.EXEC_PRC * C.BAL_QTY, 0)) * (1 + (COALESCE(F.CLR_CMS_R, 0.04)/100)) * ((COALESCE(G.BUFFER + 1, 3)/100))
        //buffer


        //posAMt
        //버퍼 + 이너버퍼 10% 추가
        let innerBufferPercentage = 0.1;  // 10% 이너버퍼
        innerBufferPercentage = bigDecimal.add(1, innerBufferPercentage);
        let bufferRat = bigDecimal.divide(buffer, 100); //버퍼 100분율
      
        // 합산버퍼 계산: 버퍼 * (1 + 이너버퍼)
        let totalBuffer = bigDecimal.multiply(bufferRat, innerBufferPercentage);

        //posAmt * totalBuffer
        let bufferAmt = bigDecimal.add(posAmt, clrCms); //진입가 + 수수료
        bufferAmt = bigDecimal.multiply(bufferAmt, totalBuffer); //진입가 + 수수료 * 버퍼

        //마진추가 금액
        let lvPosAmt = bigDecimal.divide(posAmt, data.lv); //진입금액 / 레버리지
        let addMrgAMt = bigDecimal.subtract(mrgAmt, lvPosAmt); // 마진 - 진입금액(레버리지)
        if (addMrgAMt < 0) addMrgAMt = 0;

        //버퍼금액 + 마진추가금액
        isolUsdt = bigDecimal.add(bufferAmt, addMrgAMt);

        //console.log("isolUsdt ", bufferAmt, addMrgAMt)


        //isolUsdt = bigDecimal.add(orgMrgAMt)

      }

      //구입가 구하기 (구입가 * btc 수량)
      // let entryUsdt = bigDecimal.multiply(data.execPrc.toFixed(tikDotSz(symbolData)), data.balQty);

      //현재가 구하기 (현재가 * btc 수량)
      // let curUsdt = bigDecimal.multiply(curPr, data.balQty);
      
      // valProLoss = bigDecimal.divide(val1, val2);
      // valProLoss = bigDecimal.subtract(valProLoss, '1');
      // valProLoss = bigDecimal.multiply(valProLoss, entryUsdt);

      //pnl
      //--마이너스 소수점 3자리 0이면 버림, 1이상 올림
      //--플러스 소수점 3자리 버림    
      pnl = bigDecimal.subtract(val1, val2);
      pnl = bigDecimal.multiply(pnl, '100');
      pnl = Math.floor(pnl);
      pnl = bigDecimal.divide(pnl, '100');



      //roi 공식 다른방법
      //((90303.9 - 90927.91) / 90927.91) * 100

      roi = bigDecimal.divide(val1, val2);
      roi = bigDecimal.subtract(roi, '1');
      roi = bigDecimal.multiply(roi, 100);

      //roi 소수점 3자리 0이상 올림
      //testnet binance랑 비교하여 수정필요
      if (roi < 0){
        roi = Math.abs(roi);

        //소수점 3자리에서 올림처리
        roi = Math.floor(roi * 100) / 100;

        if (roi != 0){
          roi = roi * -1;
        }
      }else{
        //소수점 3자리에서 올림처리, 바이낸스 올림아님???
        //roi = Math.ceil(roi * 100) / 100;
        //roi = Math.floor(roi * 100) / 100;
        roi = bigDecimal.multiply(roi, 100);
        roi = bigDecimal.floor(roi);
        roi = bigDecimal.divide(roi, 100);
      }

      // pnl = Number(pnl).toFixed(tikDotSz(symbolData));
      // roi = Number(roi).toFixed(tikDotSz(symbolData));

      data.valPl = pnl;
      data.valRoi = roi;

      if (data.marginMode == "1"){ //교차 (cross)
        resData.crossValPl = bigDecimal.add(resData.crossValPl, pnl);
      }else{
        resData.isolUsdt = bigDecimal.add(resData.isolUsdt, isolUsdt);
      }
    }
  }

  return resData;
}

//USDT로 코인 수량 구하기
const coinUsdtToBtc = function(usdt, ordPrc, cms, dotCnt){
  let btcSize = 0;
  let scale = 1;

  if (dotCnt != undefined && dotCnt > 0){
    scale = Math.pow(10,dotCnt);
  }
  
  if (usdt != '' && ordPrc != '' && usdt > 0 && ordPrc > 0){
    const ableLvUsdt = usdt; //레버리지 적용된 사용가능 usdt
    // const cmsR = bigDecimal.divide(cms, 100); //수수료율
    // const ableLvUsdtCms = bigDecimal.multiply(ableLvUsdt, bigDecimal.subtract(1, cmsR));
    //console.log("coinUsdtToBtc >>> ", usdt, ordPrc)
    btcSize = Number(bigDecimal.divide(usdt, ordPrc));
  
    btcSize = bigDecimal.multiply(btcSize, scale);
    btcSize = bigDecimal.floor(btcSize);
    btcSize = Number(bigDecimal.divide(btcSize, scale));

    // btcSize = bigDecimal.divide(usdt, ordPrc);
    // btcSize = bigDecimal.multiply(btcSize, scale);
    // btcSize = bigDecimal.floor(btcSize);
    // btcSize = Number(bigDecimal.divide(btcSize, scale));
  }

  return btcSize;
}
const krwToUsdt = function(krw, usdExr, type, cms){
  let usdt = 0;

  if (krw == 0 || usdExr == 0){
    return 0;
  }
  //수수료 퍼센트
  if (type != undefined && cms != undefined && type == "1"){
    
    //let calCms = bigDecimal.divide(cms, 100);
    let calCms = bigDecimal.divide(cms, 100);
    calCms = bigDecimal.multiply(krw, calCms);
    calCms = bigDecimal.floor(calCms);


    krw = bigDecimal.subtract(krw, calCms);
  }else if (type != undefined && cms != undefined && type == "2"){ // 고정
    krw = Number(krw) - Number(cms);
  }else{ //수수료 설정이 없을경우
    krw = Number(krw);
  }

  usdt = bigDecimal.divide(krw, usdExr);

  usdt = Math.floor(usdt * 100000) / 100000;

  return usdt;

}
const usdtToKrw = function(usdt, usdExr, type, cms){
  let krw = 0;

  if (usdt == 0 || usdExr == 0){
    return 0;
  }

  krw = bigDecimal.multiply(usdt, usdExr);

  krw = Math.floor(krw);
  

  //수수료 퍼센트
  if (type != undefined && cms != undefined && type == "1"){
    let calCms = bigDecimal.divide(cms, 100);
    calCms = bigDecimal.multiply(krw, calCms);
    calCms = bigDecimal.floor(calCms);

    krw = bigDecimal.subtract(krw, calCms);
  }else if (type != undefined && cms != undefined && type == "2"){ // 고정
    krw = Number(krw) - Number(cms);
  }else{
    //수수료 설정이 없으면
    krw = Number(krw);
  }

  if (Number(krw) < 0){
    return 0;
  }

  return krw;
}

const toFixNum = function(fromNum, fixNum){
  let scale = 1;

  if (fixNum != undefined && fixNum > 0){
    // for (let i=0; i<fixNum; i++){
    //   scale = bigDecimal.multiply(scale, 10);
    // }
    scale = Math.pow(10,fixNum);
  }

  let toNum = bigDecimal.multiply(fromNum, scale);
  toNum = bigDecimal.floor(toNum);
  toNum = Number(bigDecimal.divide(toNum, scale));
  
  return toNum;
}

//입력값의 소수점 값을 리턴 ex> (0,2) > 0.00, (1,3) > 1.000
const toFixInit = function(fromNum, fixNum){
  let value = fromNum+".";
  for (let i=0; i<fixNum; i++){
    value += "0";
  }

  return value;
}

// input 숫자만 입력, 3자리마다 콤마표시
// v-model 대신 :value로 대체하여 사용
// <input ... :value="depositForm.dpsReqMn" @input="depositForm.dpsReqMn = checkValue($event)" maxlength="13" />
// checkValue(event){
//  return Utils.inputComma(event);
// },
const inputComma = function(event){
  event.target.value = event.target.value.replace(/[^0-9]/g, '');
  let value = event.target.value;

  if (value != undefined && value.length > 0){
    value = Number(value);
  }

  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

//event 키 입력 0~9 소수점
//입력한 숫자(소수점) 값을 3자리 , 처리
const inputCommaPoint = function(event){
  event.target.value = event.target.value.replace(/[^0-9.]/g, '');
  let value = event.target.value;
  let pointValue = "";

  if ( value.indexOf('.') > -1 ) {
    var arrValue = value.split('.');
    value = arrValue[0];
    pointValue = '.'+ arrValue[1];
  }

  if (value != undefined && value.length > 0){
    value = Number(value);
  }

  value = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  value += pointValue;

  return value;
}

//bigdecimal 두변수를 더하기 처리
const decimalAdd = function(val1, val2){
  let value = bigDecimal.add(val1, val2);
  value = Number(value);
  return value;
}

//bigdecimal 두변수를 빼기 처리
const decimalSubtract = function(val1, val2){
  let value = bigDecimal.subtract(val1, val2);
  value = Number(value);
  return value;
}

//bigdecimal 두변수를 나누기 처리
const decimalDivide = function(val1, val2){
  let value = bigDecimal.divide(val1, val2);
  value = Number(value);
  return value;
}

//bigdecimal 두변수를 곱하기 처리
const decimalMultiply = function(val1, val2){
  let value = bigDecimal.multiply(val1, val2);
  value = Number(value);
  return value;
}

//bigdecimal floor 자리수 버림
const decimalFloor = function(val1, scale){
  let scaleVal = scale;
  if (scaleVal == 1){
    scaleVal = 10;
  }else if (scaleVal == 0){
    scaleVal = 1
  }

  let value = bigDecimal.multiply(val1, scaleVal);
  value = bigDecimal.floor(value);
  value = Number(bigDecimal.divide(value, scaleVal));
  value = Number(value);
  return value;
}

//bigdecimal ceil 자리수 올림
const decimalCeil = function(val1, scale){
  let scaleVal = scale;
  if (scaleVal == 1){
    scaleVal = 10;
  }else if (scaleVal == 0){
    scaleVal = 1
  }

  let value = bigDecimal.multiply(val1, scaleVal);
  value = bigDecimal.ceil(value);
  value = Number(bigDecimal.divide(value, scaleVal));
  value = Number(value);
  return value;
}

//가격에 대한 구매 가능 수량 구하기 (사용가능usdt, 배율, 시세 or 지정가, 수수료)
const getCoinOrdQty = function(coinDailyPlMn, coinLeverage, ordPrc, lmtCmsR, costDotCnt, coinTier, buffer, sellBuy, cpos){
  // 구매 금액 제한 비율 (버퍼 비율, 예: 1%가 가장 많은 수량을 구매)
  let bufferPercentage = buffer;  // 10% 버퍼 (버퍼가 커질수록 구매 금액이 줄어듬)
  if (buffer != null && buffer > 0){
    bufferPercentage = bigDecimal.divide(buffer, 100); 
  }

  // 이너버퍼 비율 (로스컷 기준을 추가로 제한하는 비율, 예: 10%)
  let innerBufferPercentage = 0.1;  // 10% 이너버퍼
  innerBufferPercentage = bigDecimal.add(1, innerBufferPercentage);

  // 합산버퍼 계산: 버퍼 * (1 + 이너버퍼)
  //let totalBuffer = bufferPercentage * (1 + innerBufferPercentage);
  let totalBuffer = bigDecimal.multiply(bufferPercentage, innerBufferPercentage);


  let leverage = coinLeverage;

  // 최대 레버리지 계산: 1 / 합산버퍼
  //let maxLeverage = Math.floor(1 / totalBuffer);
  let maxLeverage = bigDecimal.divide(1, totalBuffer);
  maxLeverage = bigDecimal.floor(maxLeverage);

  //레버리지가 maxLeverage 보다 높으면 maxLeverage 레버리지로 고정
  if (maxLeverage < coinLeverage){
    leverage = maxLeverage;
  }

  let scale = 1;

  if (costDotCnt != undefined && costDotCnt > 0){
    scale = Math.pow(10,costDotCnt);
  }

  //배율에 따른 제한 금액
  let tier = null;
  if (coinTier != undefined && coinTier.length > 0){
    for (let item of coinTier){
      if (item.maxLv >= leverage){
        tier = item;
      }
    }
  }

  //배율별 금액, 보유자산이 없으면
  if (tier == null || coinDailyPlMn == undefined || coinDailyPlMn == null){
    return 0;
  }

  //레버리지 적용된 보유 usdt
  let usdt = bigDecimal.multiply(coinDailyPlMn.usdt, leverage); 
  //레버리지 적용된 사용가능 usdt
  //let ableLvUsdt = bigDecimal.multiply(coinDailyPlMn.ableUsdt, leverage);
  let ableLvUsdt = bigDecimal.multiply(coinDailyPlMn.orgAbleUsdt, leverage);
  //레버리지 적용된 사용한 usdt
  //let useLvUsdt = bigDecimal.multiply(coinDailyPlMn.useUsdt, leverage);
  let useLvUsdt = coinDailyPlMn.orgUseUsdt;

  //사용중인 금액이 있으면
  if (coinDailyPlMn.useUsdt > 0){
    //보유자산보다 사용중자산이 많으면
    if (Number(coinDailyPlMn.usdt) <= Number(coinDailyPlMn.useUsdt)){
      ableLvUsdt = 0;
    }
  }

  //금액이 마이너스면 0으로 변경
  if (ableLvUsdt < 0){
    ableLvUsdt = 0;
  }

  //사용가능 + 사용중 금액이 배율금액보다 높으면 배율금액으로 제한
  if (bigDecimal.add(ableLvUsdt, useLvUsdt) > tier.maxOrdAmt){
    ableLvUsdt = Number(tier.maxOrdAmt) - Number(useLvUsdt);
  }
  
  const cmsR = bigDecimal.divide(lmtCmsR, 100); //수수료율
  const ableLvUsdtCms = bigDecimal.multiply(ableLvUsdt, bigDecimal.subtract(1, cmsR));
  
  let ordQty = Number(bigDecimal.divide(ableLvUsdtCms, ordPrc));
  //console.log("ordQty", ordQty, ordPrc)

  ordQty = bigDecimal.multiply(ordQty, scale);
  ordQty = bigDecimal.floor(ordQty);
  ordQty = Number(bigDecimal.divide(ordQty, scale));

  if (ordQty < 0){ //계산 수량이 마이너스면 0으로 변경
    ordQty = 0;
  }

  //sellBuy, marginMode
  // let coinPosOrdSlList = window.$store.getters['SocketCoinOms/getCoinPosOrdStList'];

  // //청산가능 수량이 있으면 반대포지션에 수량을 더하기 (마진모드 단방향만 사용)
  // if (coinPosOrdSlList != undefined && coinPosOrdSlList.length > 0 && cpos == "1"){
  //   const selCoinSymbol = window.$store.getters['SocketCoinPrice/getSelectCoinSymbol'];

  //   for (let item of coinPosOrdSlList){
  //     if (item.symCd == selCoinSymbol.name){
  //       if (sellBuy == "sell" && item.ordSdCd == "2"){
  //         ordQty = Number(bigDecimal.add(ordQty, item.balQty));
  //       }if (sellBuy == "buy" && item.ordSdCd == "1"){
  //         ordQty = Number(bigDecimal.add(ordQty, item.balQty));
  //       }
  //     }
  //   }
  // }

  return ordQty.toFixed(costDotCnt);
}

//가격에 대한 구매 가능 usdt 구하기 (사용가능usdt, 배율, 시세 or 지정가, 수수료)
const getCoinOrdUsdt = function(coinDailyPlMn, coinLeverage, ordPrc, lmtCmsR, costDotCnt, coinTier, buffer, sellBuy, cpos){
  // 구매 금액 제한 비율 (버퍼 비율, 예: 1%가 가장 많은 수량을 구매)
  let bufferPercentage = buffer;  // 10% 버퍼 (버퍼가 커질수록 구매 금액이 줄어듬)
  if (buffer != null && buffer > 0){
    bufferPercentage = bigDecimal.divide(buffer, 100);
  }

  // 이너버퍼 비율 (로스컷 기준을 추가로 제한하는 비율, 예: 10%)
  let innerBufferPercentage = 0.1;  // 10% 이너버퍼
  innerBufferPercentage = bigDecimal.add(1, innerBufferPercentage);

  // 합산버퍼 계산: 버퍼 * (1 + 이너버퍼)
  //let totalBuffer = bufferPercentage * (1 + innerBufferPercentage);
  let totalBuffer = bigDecimal.multiply(bufferPercentage, innerBufferPercentage);


  let leverage = coinLeverage;

  // 최대 레버리지 계산: 1 / 합산버퍼
  //let maxLeverage = Math.floor(1 / totalBuffer);
  let maxLeverage = bigDecimal.divide(1, totalBuffer);
  maxLeverage = bigDecimal.floor(maxLeverage);

  //레버리지가 maxLeverage 보다 높으면 maxLeverage 레버리지로 고정
  if (maxLeverage < coinLeverage){
    leverage = maxLeverage;
  }

  let scale = 1;

  if (costDotCnt != undefined && costDotCnt > 0){
    scale = Math.pow(10,costDotCnt);
  }

  //배율에 따른 제한 금액
  let tier = null;
  if (coinTier != undefined && coinTier.length > 0){
    for (let item of coinTier){
      if (item.maxLv >= leverage){
        tier = item;
      }
    }
  }

  //배율별 금액, 보유자산이 없으면
  if (tier == null || coinDailyPlMn == undefined || coinDailyPlMn == null){
    return 0;
  }

  //레버리지 적용된 보유 usdt
  let usdt = bigDecimal.multiply(coinDailyPlMn.usdt, leverage); 
  //레버리지 적용된 사용가능 usdt
  //let ableLvUsdt = bigDecimal.multiply(coinDailyPlMn.ableUsdt, leverage);
  let ableLvUsdt = bigDecimal.multiply(coinDailyPlMn.orgAbleUsdt, leverage);
  //레버리지 적용된 사용한 usdt
  //let useLvUsdt = bigDecimal.multiply(coinDailyPlMn.useUsdt, leverage);
  let useLvUsdt = coinDailyPlMn.orgUseUsdt;

  //사용한 usdt가 총구매가능 수량보다 많거나 같으면 0

  //사용중인 금액이 있으면
  if (coinDailyPlMn.useUsdt > 0){
    //보유자산보다 사용중자산이 많으면
    if (Number(coinDailyPlMn.usdt) <= Number(useLvUsdt)){
      ableLvUsdt = 0;
    }
  }

  //금액이 마이너스면 0으로 변경
  if (ableLvUsdt < 0){
    ableLvUsdt = 0;
  }

  //사용가능 + 사용중 금액이 배율금액보다 높으면 배율금액으로 제한
  if (bigDecimal.add(ableLvUsdt, useLvUsdt) > tier.maxOrdAmt){
    ableLvUsdt = Number(tier.maxOrdAmt) - Number(useLvUsdt);
  }
  

  const cmsR = bigDecimal.divide(lmtCmsR, 100); //수수료율
  const ableLvUsdtCms = bigDecimal.multiply(ableLvUsdt, bigDecimal.subtract(1, cmsR));
  //const ableLvUsdtCms = ableLvUsdt;

  let ordQty = Number(bigDecimal.divide(ableLvUsdtCms, ordPrc));

  //let ordQty = Number(bigDecimal.divide(ableLvUsdtWithBuffer, ordPrc));

  //수량 최소수량 소수점까지만 사용
  ordQty = bigDecimal.multiply(ordQty, scale);
  ordQty = bigDecimal.floor(ordQty);
  ordQty = Number(bigDecimal.divide(ordQty, scale));



  //sellBuy, marginMode
  // let coinPosOrdSlList = window.$store.getters['SocketCoinOms/getCoinPosOrdStList'];

  // //청산가능 수량이 있으면 반대포지션에 수량을 더하기 (마진모드 단방향만 사용)
  // if (coinPosOrdSlList != undefined && coinPosOrdSlList.length > 0 && cpos == "1"){
  //   const selCoinSymbol = window.$store.getters['SocketCoinPrice/getSelectCoinSymbol'];

  //   for (let item of coinPosOrdSlList){
  //     if (item.symCd == selCoinSymbol.name){
  //       if (sellBuy == "sell" && item.ordSdCd == "2"){
  //         ordQty = Number(bigDecimal.add(ordQty, item.balQty));
  //       }if (sellBuy == "buy" && item.ordSdCd == "1"){
  //         ordQty = Number(bigDecimal.add(ordQty, item.balQty));
  //       }
  //     }
  //   }
  // }

  let ordUsdt = bigDecimal.multiply(ordPrc, ordQty);
  ordUsdt = bigDecimal.multiply(ordUsdt, 10000);
  ordUsdt = bigDecimal.floor(ordUsdt);
  ordUsdt = Number(bigDecimal.divide(ordUsdt, 10000));

  if (ordUsdt < 0){
    ordUsdt = 0;
  }

  return ordUsdt.toFixed(4);
}

//Cost Usdt 구하기
const coinUsdtCost = function(usdt, curPr, stdOrdType, costDotCnt){  
  let btcSize = 0;
  //let tikDotSz = 1;
  let costUsdt = 0;
  let scale = 1;

  //최수 주문수량의 소수점 자리수 구하기
  if (costDotCnt != undefined && costDotCnt > 0){
    scale = Math.pow(10,costDotCnt);
  }

  if (stdOrdType == "USDT"){ //USDT로 구매 

  
    if (usdt != '' && curPr != '' && usdt > 0 && curPr > 0){
      
      btcSize = bigDecimal.divide(usdt, curPr); 
      btcSize = bigDecimal.multiply(btcSize, scale);
      btcSize = bigDecimal.floor(btcSize);
      btcSize = Number(bigDecimal.divide(btcSize, scale));

      costUsdt = bigDecimal.multiply(btcSize, curPr);
      costUsdt = bigDecimal.multiply(costUsdt, 10000);
      costUsdt = bigDecimal.floor(costUsdt);
      costUsdt = Number(bigDecimal.divide(costUsdt, 10000));
      if (costUsdt < 0){
        costUsdt = 0;
      }
      costUsdt = costUsdt.toFixed(4);
    }
  }else{ //코인 수량으로 구매
    costUsdt = Number(bigDecimal.multiply(usdt, curPr));

    costUsdt = bigDecimal.multiply(costUsdt, 10000);
    costUsdt = bigDecimal.floor(costUsdt);
    costUsdt = Number(bigDecimal.divide(costUsdt, 10000));
    if (costUsdt < 0){
      costUsdt = 0;
    }
    costUsdt = costUsdt.toFixed(4);
  }

  return costUsdt;
}

//event 키 입력 0~9 소수점
const inputPoint = function(event){
  event.target.value = event.target.value.replace(/[^0-9.]/g, '');
  let value = event.target.value;
  let pointValue = "";

  if ( value.indexOf('.') > -1 ) {
    var arrValue = value.split('.');
    value = arrValue[0];
    pointValue = '.'+ arrValue[1];
  }

  if (value != undefined && value.length > 0){
    value = Number(value);
  }

  value += pointValue;

  if (value == "."){
    value = "";
  }

  // v-for 팝업화면에서 작동이 잘안되어 타겟에 value 입력
  event.target.value = value;

  return value;
}

//사용가능 자산 퍼센트 금액으로 구하기
const coinUsdtPercent = function(ableUsdt, percent, ordType){
  let result = 0;
  if (percent.indexOf("%") > -1){
    let per = percent.substring(0, percent.length-1);
    per = bigDecimal.divide(per, 100);
    result = bigDecimal.multiply(ableUsdt, per);
    if (percent == "100%"){
      result = ableUsdt;
    }
  } 
  return result;
}

//구매 가능 금액
const getAvbl = function(coinDailyPlMn, costDotCnt, coinTier, coinLeverage, buffer){
  // 구매 금액 제한 비율 (버퍼 비율, 예: 1%가 가장 많은 수량을 구매)
  let bufferPercentage = buffer;  // 10% 버퍼 (버퍼가 커질수록 구매 금액이 줄어듬)
  if (buffer != null && buffer > 0){
    bufferPercentage = bigDecimal.divide(buffer, 100);
  }

  // 이너버퍼 비율 (로스컷 기준을 추가로 제한하는 비율, 예: 10%)
  let innerBufferPercentage = 0.1;  // 10% 이너버퍼
  innerBufferPercentage = bigDecimal.add(1, innerBufferPercentage);

  // 합산버퍼 계산: 버퍼 * (1 + 이너버퍼)
  //let totalBuffer = bufferPercentage * (1 + innerBufferPercentage);
  let totalBuffer = bigDecimal.multiply(bufferPercentage, innerBufferPercentage);

  let leverage = coinLeverage;

  // 최대 레버리지 계산: 1 / 합산버퍼
  //let maxLeverage = Math.floor(1 / totalBuffer);
  let maxLeverage = bigDecimal.divide(1, totalBuffer);
  maxLeverage = bigDecimal.floor(maxLeverage);

  //레버리지가 maxLeverage 보다 높으면 maxLeverage 레버리지로 고정
  if (maxLeverage < coinLeverage){
    leverage = maxLeverage;
  }

  let scale = 1;

  if (costDotCnt != undefined && costDotCnt > 0){
    scale = Math.pow(10,costDotCnt);
  }

  //배율에 따른 제한 금액
  let tier = null;
  if (coinTier != undefined && coinTier.length > 0){
    for (let item of coinTier){
      if (item.maxLv >= leverage){
        tier = item;
      }
    }
  }

  //배율별 금액, 보유자산이 없으면
  if (tier == null || coinDailyPlMn == undefined || coinDailyPlMn == null){
    return 0;
  }
  

  //레버리지 적용된 보유 usdt
  let usdt = bigDecimal.multiply(coinDailyPlMn.usdt, leverage); 
  //레버리지 적용된 사용가능 usdt
  //let ableLvUsdt = bigDecimal.multiply(coinDailyPlMn.ableUsdt, leverage);
  let ableLvUsdt = bigDecimal.multiply(coinDailyPlMn.orgAbleUsdt, leverage);
  //레버리지 적용된 사용한 usdt
  //let useLvUsdt = bigDecimal.multiply(coinDailyPlMn.useUsdt, leverage);
  let useLvUsdt = coinDailyPlMn.orgUseUsdt;

  //사용중인 금액이 있으면
  if (coinDailyPlMn.useUsdt > 0){
    //보유자산보다 사용중자산이 많으면
    if (Number(coinDailyPlMn.usdt) <= Number(coinDailyPlMn.useUsdt)){
      ableLvUsdt = 0;
    }
  }

  //금액이 마이너스면 0으로 변경
  if (ableLvUsdt < 0){
    ableLvUsdt = 0;
  }

  //사용가능 + 사용중 금액이 배율금액보다 높으면 배율금액으로 제한
  if (bigDecimal.add(ableLvUsdt, useLvUsdt) > tier.maxOrdAmt){
    ableLvUsdt = Number(tier.maxOrdAmt) - Number(useLvUsdt);
  }

  //금액이 마이너스면 0으로 변경
  if (ableLvUsdt < 0){
    return 0;
  }

  if (ableLvUsdt > 0){
    ableLvUsdt = bigDecimal.divide(ableLvUsdt, leverage);
  }

  return ableLvUsdt;
}

//코인 TP/SL PNL 계산
const getTpSlCalPrc = function(data, tpSlPrc, dotCnt, lv){
  let scale = 1;

  if (dotCnt != undefined && dotCnt > 0){
    scale = Math.pow(10,dotCnt);
  }
  
  let resData = {data:data, valProLoss: 0};
  let symbolData = null;
  let pricescale = 1;
  let pnl = 0; //평가손익 usdt
  let nowPrc = 0;


  if (tpSlPrc != undefined){
    //평가손익 계산
    let val1 = 0;
    let val2 = 0;
    let entryUsdt = 0;

    if (tpSlPrc == 0 || data.ordSdCd == 0){
      pnl = 0;
    }else{
        let execPrc = Number(data.execPrc);
      if (data.ordSdCd == "1"){ //매도
        // (구매가 / 현재가 -1) * 구매usdt = 손익usdt
        val1 = bigDecimal.multiply(execPrc, data.balQty);
        val2 = bigDecimal.multiply(tpSlPrc, data.balQty);
        entryUsdt = val1;
      }else if (data.ordSdCd == "2"){ //매수
        // (현재가 / 구매가 -1) * 구매usdt = 손익usdt
        val1 = bigDecimal.multiply(tpSlPrc, data.balQty);
        val2 = bigDecimal.multiply(execPrc, data.balQty);
        entryUsdt = val2;
      }
     
      //pnl
      //--마이너스 소수점 3자리 0이면 버림, 1이상 올림
      //--플러스 소수점 3자리 버림
      pnl = bigDecimal.subtract(val1, val2);
      pnl = bigDecimal.multiply(pnl, '100');
      pnl = Math.floor(pnl);
      pnl = bigDecimal.divide(pnl, '100');
      pnl = Number(pnl);
    }
  }

  pnl.toFixed(2);

  return pnl;
}

//event 키 입력 0~9 소수점, 자리수 만큼 입력가능
const inputPointCoinPrc = function(event, stdOrdType, curPr, costDotCnt){
  event.target.value = event.target.value.replace(/[^0-9.]/g, '');
  let value = event.target.value;
  let pointValue = "";
  let pointLen = costDotCnt;
  let curLen = 0;

  //시세 자리수
  if (curPr != null && curPr > 0 && curPr.toString().length > 0){
    let arrCurPr = curPr.toString().split('.');
    curLen = arrCurPr[0].length;
  }

  //usdt 입력시 소수점2자리까지 허용
  if (stdOrdType == "USDT"){
    pointLen = 2;
  }

  if ( value.indexOf('.') > -1 ) {
    var arrValue = value.split('.');
    value = arrValue[0];
    pointValue = '.'+ arrValue[1];
  }

  if (value != undefined && value.length > 0){
    //시세보다 2자리수 더 입력하면
    if (value.length > curLen+1){
      value = value.substring(0, curLen+1);
    }
    value = Number(value);
  }

  if (pointValue != ""){
    if (pointValue.length > pointLen+1){
      pointValue = pointValue.substring(0, pointLen+1);
    }
  }

  value += pointValue;

  if (value == "."){
    value = "";
  }

  // v-for 팝업화면에서 작동이 잘안되어 타겟에 value 입력
  event.target.value = value;

  return value;
}

//event 키 입력 0~9 소수점, 자리수 만큼 입력가능
const inputPointCoinQty = function(event, stdOrdType, costDotCnt, coinDailyPlMn, coinLeverage, buffer){
  event.target.value = event.target.value.replace(/[^0-9.]/g, '');
  let value = event.target.value;
  let pointValue = "";
  let pointLen = costDotCnt;
  let maxLen = 10;

  //usdt 입력시 소수점2자리까지 허용
  if (stdOrdType == "USDT"){
    pointLen = 2;
  }

  if ( value.indexOf('.') > -1 ) {
    var arrValue = value.split('.');
    value = arrValue[0];
    pointValue = '.'+ arrValue[1];
  }

  if (value != undefined && value.length > 0){
    //최대 금액보다 더 입력하면
    if (value.length > maxLen){
      value = value.substring(0, maxLen);
    }
    value = Number(value);
  }

  if (pointValue != ""){
    if (pointValue.length > pointLen+1){
      pointValue = pointValue.substring(0, pointLen+1);
    }
  }

  value += pointValue;

  if (value == "."){
    value = "";
  }

  // v-for 팝업화면에서 작동이 잘안되어 타겟에 value 입력
  event.target.value = value;

  return value;
}

export default { 
  initObject,
  numberComma,  
  getToday,
  getDatePrevDay,
  getDatePrevMonth,
  getUuidv4,
  nvl,
  addValueInArray,
  delValueInArray,
  rpad,
  getNowTime,
  tikDotSz,
  tikVal,
  symbolCalPrc,
  coinSymbolCalPrc,
  coinUsdtToBtc,
  krwToUsdt,
  usdtToKrw,
  toFixNum,
  inputComma,
  inputCommaPoint,
  toFixInit,
  decimalAdd,
  decimalSubtract,
  decimalDivide,
  decimalMultiply,
  decimalFloor,
  decimalCeil,
  getCoinOrdQty,
  getCoinOrdUsdt,
  coinUsdtCost,
  inputPoint,
  coinUsdtPercent,
  getAvbl,
  getTpSlCalPrc,
  inputPointCoinPrc,
  inputPointCoinQty
}