<template>
  <div class="base-dropdown" :id="id" ref="dropdown">
    <div class="dropdown instrument" @click="handleDropdown()">
      <div class="selected">{{ selectedName }}</div>
      <img src="@/assets/icons/dropdown-triangle.svg" alt="" class="dropdown-triangle" />
    </div>
    <div class="dropdown-list instrument-list" :class="{ show: isDropdownVisible }" style="height : 340px">
      <table class="instrument-table">
        <thead>
          <tr>
            <th class="instrument">종목</th>
            <th class="price">가격</th>
            <th class="rate">변동률</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in options" :key="item.symCd" @click="
            selectedOption = item;
          isDropdownVisible = false;
          $emit(id, item);
          ">
            <td class="instrument">{{ item.base_name }}</td>
            <td class="price">{{ item.lstPrc }}</td>
            <td :class="{ 'color-blue': item.chPr > 0, 'color-red': item.chPr < 0, '': item.chPr == 0 }">
              {{ item.chgRate }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import TestPage from '@/views/TestPage.vue';

export default {
  name: 'SymbolDropDown',
  props: {
    options: {
      type: Array,
      required: true,
    },
    id: String,
    default: String,
    width: [String, Number],
    height: [String, Number],
    full: Boolean,
    popup: Boolean,
    cdHeight: {
      type: String,
      default: '',
    },
    value: {
      type: Object
    },
  },
  computed: {
    selectedName() {
      let rtn = ''
      if (this.value != undefined && this.value) {
        for (let i in this.options) {
          if (this.value == this.options[i].base_name) {
            rtn = this.options[i].base_name
            break
          }
        }
      } else {
        if (this.selectedOption != undefined) {
          rtn = this.selectedOption.base_name
        } else if (this.options != undefined && this.options.length > 0) {
          rtn = this.options[0].base_name
        }
      }
      return rtn
    }
  },
  watch: {
    default: {
      handler(newOption) {
        this.selectedOption = newOption;
      },
      deep: true
    }
  },
  data() {
    return {
      selectedOption: '',
      isDropdownVisible: false,
      timeOut: null,
    };
  },


  mounted() {
    this.selectedOption = this.default ? this.default : this.options[0];
    window.addEventListener('click', this.closeDropdown);
  },
  beforeDestroy() {
    window.removeEventListener('click', this.closeDropdown);
  },
  methods: {
    handleDropdown() {
      this.isDropdownVisible = !this.isDropdownVisible;
      if (this.isDropdownVisible == true) {
        this.timeOut = setInterval(() => {
          this.getOvcData();
        }, 2000);
      }
    },
    async getOvcData() {
      const dataMap = await window.$http.get('apisisecoin/getChlData', { params: {} });
      const dataMap1 = await window.$http.get('apisisecoin/getCocData', { params: {} });
      for (let item of this.options) {
        item.chgRate = dataMap.data[item.base_name].chPrate
        item.chPr = dataMap.data[item.base_name].chPr
        item.lstPrc = dataMap1.data.cocDataList[item.base_name].curPr
      }
    },
    closeDropdown(element) {
      if (!this.$refs.dropdown.contains(element.target)) {
        this.isDropdownVisible = false;
      }
      if (this.isDropdownVisible == false) {
        clearInterval(this.timeOut);
        this.timeOut = null;
      }
    },
  },
};
</script>