import Utils from '@/modules/utils'

const state = {
  channelToCoinPriceCoc: new Map(), //구독중 COC(체결) 종목
  channelToCoinPriceCoh: new Map(), //구독중 COH(호가) 종목
  socketCoinPrice : null,
  isConnectCoinPrice: false,
  stockCoinPrice: null,
  sessionStorage: null,
  cocData: null,
  cohData: null,
  cotData: null,
  comData: null,
  chlData: null,
  coinPriceInterval: null,
  connectCoinPriceTimeout: null,
  selectCoinSymbol: null, //선택한 종목
  //---폴링시세 관련---
  isPolingCoinPrice: false,
  coinPollingInterVal: null,
  cocDataList: null,
  cohDataList: null,
  cotDataList: null,
  comDataList: null,
  chlDataList: null,
  pollingCoinSymCd: null,
  pollingCoinSymList: null,
  pollingCoinDelayTime: 300, //폴링간격 0.1초
  //---폴링시세 관련---
  coinPriceReqType: "SCKTP02", //시세 받는방식 socket(SCKTP01), polling(SCKTP02/SCKTP03)
}
  
const getters = {
  getIsConnectCoinPrice(state){
      return state.isConnectCoinPrice;
  },
  //코인 시세
  getCocData(state){
    return state.cocData;
  },
  //코인 호가
  getCohData(state){
    return state.cohData;
  },
  //코인 센터가
  getCotData(state){
    return state.cotData;
  },
  //코인 청산가
  getComData(state){
    return state.comData;
  },
  //코인 시/고/저 지수등
  getChlData(state){
    return state.chlData;
  },
  getStockCoinPrice(state){
    return state.stockCoinPrice;
  },
  getSelectCoinSymbol(state){
    return state.selectCoinSymbol;
  },
  //---폴링시세 관련---
  getIsPolingCoinPrice(state){
    return state.isPolingCoinPrice;
  },
  //코인 시세
  getCocDataList(state){
    return state.cocDataList;
  },
  //코인 호가
  getCohDataList(state){
    return state.cohDataList;
  },
  //코인 센터가
  getCotDataList(state){
    return state.cotDataList;
  },
  //코인 청산가
  getComDataList(state){
    return state.comDataList;
  },
  //코인 시/고/저 지수등
  getChlDataList(state){
    return state.chlDataList;
  },
  //---폴링시세 관련---
  getCoinPriceReqType(state){
    return state.coinPriceReqType;
  }
}
  
const mutations = {
  setSessionStorage (state, {sessionStorage}){
    state.sessionStorage = sessionStorage
  },
  setConectCoinPrice (state, {socketCoinPrice}){
    state.socketCoinPrice = socketCoinPrice
  },
  setIsConnectCoinPrice(state, {isConnect}){
    // console.log("setIsConnectCoinPrice >>> ", isConnect)
    state.isConnectCoinPrice = isConnect
  },
  setStockCoinPrice(state, {stockCoinPrice}){
      state.stockCoinPrice = stockCoinPrice
  },
  sendCoinPriceMessage(state, {apiCode, apiType, coinSymbolList, coinSymbol}){
    //console.log("sendCoinPriceMessage >>>>> ", apiCode, apiType, coinSymbolList, coinSymbol)
    
    if (!coinSymbolList && !coinSymbol) return;

    if (coinSymbolList != undefined && coinSymbolList.length > 0){
      //
    }

    let cocReqData = {
      header: {
        apiCode : apiCode,
        token : state.sessionStorage
      },
      body : {
        apiType : 'COC',
        apiTypeData: ['COC'],
        symbolData : coinSymbolList
      }
    }

    let cotReqData = {
      header: {
        apiCode : apiCode,
        token : state.sessionStorage
      },
      body : {
        apiType : 'COT',
        apiTypeData: ['COT'],
        symbolData : []
      }
    }

    let comReqData = {
      header: {
        apiCode : apiCode,
        token : state.sessionStorage
      },
      body : {
        apiType : 'COM',
        apiTypeData: ['COM'],
        symbolData : coinSymbolList
      }
    }

    let chlReqData = {
      header: {
        apiCode : apiCode,
        token : state.sessionStorage
      },
      body : {
        apiType : 'CHL',
        apiTypeData: ['CHL'],
        symbolData : []
      }
    }

    if (apiType == 'COT'){
      cotReqData.body.symbolData = [coinSymbol];
    }

    // if (apiType == 'COM'){
    //   comReqData.body.symbolData = [coinSymbol];
    // }

    if (apiType == 'CHL'){
      chlReqData.body.symbolData = [coinSymbol];
    }

    if (apiCode == "DSA0004"){
      cocReqData.body = {
        apiType : 'COC',
        symbol : coinSymbol,
        apiTypeData: ['COC'],
        symbolData : [coinSymbol] 
      }

      cotReqData.body = {
        apiType : 'COT',
        symbol : coinSymbol,
        apiTypeData: ['COT'],
        symbolData : [coinSymbol] 
      }

      comReqData.body = {
        apiType : 'COM',
        symbol : coinSymbol,
        apiTypeData: ['COM'],
        symbolData : [coinSymbol] 
      }

      chlReqData.body = {
        apiType : 'CHL',
        symbol : coinSymbol,
        apiTypeData: ['CHL'],
        symbolData : [coinSymbol] 
      }
    }

    //호가는 선택한 종목 단건만 요청
    let cohReqData = {
      header: {
        apiCode : apiCode,
        token : state.sessionStorage
      },
      body : {
        apiType : 'COH',
        apiTypeData: ['COH'],
        symbol : coinSymbol,
        symbolData : coinSymbolList
      }
    }
    
    try{
      if (state.socketCoinPrice.readyState == 1){
        if (apiType == "COC"){          
          if (cocReqData.body.symbolData[0]){
            //console.log("sned coc??? ", coinSymbolList)  
            state.socketCoinPrice.send(JSON.stringify(cocReqData));
          }
        }

        if (apiType == 'COT'){
          //console.log("send COT data >>> ", cotReqData.body.symbolData[0])
          state.socketCoinPrice.send(JSON.stringify(cotReqData));
        }

        if (apiType == 'COM'){
          //console.log("send COM data >>> ", comReqData.body.coinSymbolData[0])
          state.socketCoinPrice.send(JSON.stringify(comReqData));
        }

        if (apiType == 'CHL'){
          //console.log("send CHL data >>> ", chlReqData.body.coinSymbolData[0])
          state.socketCoinPrice.send(JSON.stringify(chlReqData));
        }

        if (apiType == "COH"){
          //console.log("sned coh??? ", coinSymbolList)  
          state.socketCoinPrice.send(JSON.stringify(cohReqData));
        }
      }
    }catch(e){
      console.log("e >>> ", e, cocReqData, cohReqData)
    }  
  },
  setchannelToCoinPriceCoc(state,  {subscribeItem}){
    state.channelToCoinPriceCoc.set(subscribeItem.coinSymbol, subscribeItem)
  },
  delchannelToCoinPriceCoc(state,  {coinSymbol}){
    state.channelToCoinPriceCoc.delete(coinSymbol)
  },
  setchannelToCoinPriceCoh(state,  {subscribeItem}){
    state.channelToCoinPriceCoh.set(subscribeItem.coinSymbol, subscribeItem)
  },
  delchannelToCoinPriceCoh(state,  {coinSymbol}){
    state.channelToCoinPriceCoh.delete(coinSymbol)
  },
  sendPriceHeartBeat(state) {
    // console.log("sendPriceHeartBeat ", state.sessionStorage)
      const reqData = {
        header: {
        apiCode : 'DSA0000',
        token : state.sessionStorage
        },
        body : {
        }
      }
      if (state.socketCoinPrice.readyState == 1){
        state.socketCoinPrice.send(JSON.stringify(reqData))
      }
  },
  setCocData(state,  {cocData}){
    state.cocData = cocData
  },
  setCohData(state,  {cohData}){
    state.cohData = cohData
  },
  setCotData(state,  {cotData}){
    state.cotData = cotData
  },
  setComData(state,  {comData}){
    state.comData = comData
  },
  setChlData(state,  {chlData}){
    state.chlData = chlData
  },
  setSelectCoinSymbol(state, coinSymbol){
    state.selectCoinSymbol = coinSymbol
  },
  setCoinDisconnect(state){
    if (state.isConnectCoinPrice){
      state.socketCoinPrice.onclose = function () {}
      state.socketCoinPrice.close();
      state.isConnectCoinPrice = false
    }
    clearInterval(state.coinPriceInterval)
  },
  //---폴링시세 관련---
  setisPolingCoinPrice(state, {symCd:symCd, symbolList:symbolList}){
    state.isPolingCoinPrice = true;
    //시세,호가 받을 목록 저장
    state.pollingCoinSymCd = symCd;
    state.pollingCoinSymList = symbolList;
  },
  setClearCoinPolling(state){
    if (state.isPolingCoinPrice){
      state.isPolingCoinPrice = false
    }
    clearInterval(state.coinPollingInterVal)
  },
  setPollingData(state, {data:data}){
    //시세, 호가 데이터 초기화
    state.cocDataList = [];
    state.cohDataList = [];
    
    if (data != undefined && data.coh != null){
      state.cohDataList = data.coh;
    }
    
    if (data != undefined && data.cocDataList != undefined) {
      for (const key in data.cocDataList) {
        const item = data.cocDataList[key];
        if (item.symbol == null) return;
        const cocData = {          
          coinSymbol: item.symbol,
          ovsDate: item.ovsDate,
          korDate: item.korDate,
          trdTm: item.trdTm,
          korTm: item.korTm,
          curPr: item.curPr,
          ydiffPr: item.ydiffPr,
          ydiffSign: item.ydiffSign,
          open: item.open,
          high: item.high,
          low: item.low,
          chGrate: item.chGrate,
          trDq: item.trDq,
          totQ: item.totQ,
          cgubun: item.cgubun,
          mdVolume: item.mdVolume,
          msVolume: item.msVolume,
          ovsMkEnd: item.ovsMkEnd,
          buyerMaker: item.buyerMaker
        };
        state.cocDataList.push(cocData);
      }
    }
  },
  setDelayTime(state, {time:time}){
    if (time == null && isNaN(time)){
      return;
    }else{
      state.pollingCoinDelayTime = time;
      //console.log("setDelayTime ", state.pollingCoinDelayTime)
    }
  },
  //---폴링시세 관련---
  //소켓 폴링 설정
  setCoinPriceReqType(state, {sckTpCd:sckTpCd}){
    //console.log("setCoinPriceReqType >>>> ", sckTpCd)
    state.coinPriceReqType = sckTpCd;
  },
  resetSelectCoinSymbol(state){
    state.selectCoinSymbol = null;
  }
}
  
const actions = {
  //소켓 커넥션 생성
  setConectCoinPrice({commit, state, dispatch}, sessionStorage){
    commit('setSessionStorage', {sessionStorage:sessionStorage.sessionStorage})
    const websocketPath = window.$store.VUE_APP_QUOTE_COIN_SOCKET_BASE_URL

    if(state.socketCoinPrice && state.socketCoinPrice.readyState == WebSocket.OPEN){     
        state.socketCoinPrice.close()
    }
    let socketCoinPrice = new WebSocket(websocketPath);

    commit('setConectCoinPrice', {socketCoinPrice: socketCoinPrice})   

    state.socketCoinPrice.onopen = () => {
      commit('setIsConnectCoinPrice', {isConnect:true})
      //state.isConnectCoinPrice = true
      //commit('sendPriceHeartBeat', {})
      clearInterval(state.coinPriceInterval);
      state.coinPriceInterval = setInterval(() => {
        commit('sendPriceHeartBeat', {})
      }, 50000)
    }

    state.socketCoinPrice.onmessage = (e) => {
      //console.log("onMessage?", e.data)
      const returnData = JSON.parse(e.data)
      const data = returnData.body
      //state.stockCoinPrice = data;
      commit('setStockCoinPrice', {stockCoinPrice:data})
      if (data.apiType == 'COC'){
        data.trDq = data.qty;
        commit('setCocData', {cocData:data})
      }else if (data.apiType == 'COH'){
        commit('setCohData', {cohData:data})
      }else if (data.apiType == 'COT'){
        commit('setCotData', {cotData:data})
      }else if (data.apiType == 'COM'){
        commit('setComData', {comData:data})
      }else if (data.apiType == 'CHL'){
        commit('setChlData', {chlData:data})
      }
    }

    state.socketCoinPrice.onerror = (e) => {
      commit('setIsConnectCoinPrice', {isConnect:false})
      console.error("[Error] socket Error")
    }

    //socket.onclose = () => {
    state.socketCoinPrice.onclose = (e) => {
      commit('setIsConnectCoinPrice', {isConnect:false})

      if (e.wasClean) {
        console.log('[price] WS Closed')
        state.connectMsgTimeout = setTimeout(() => {
          dispatch('setConectPrice', {sessionStorage:state.sessionStorage});
        }, 5000);
      } else {
        console.log('[price] WS Connecting...')
        dispatch('setConectPrice', {sessionStorage:state.sessionStorage})
      }

      // console.error("[OnClose] socket close", e.code, e.wasClean);
      // state.connectCoinPriceTimeout = setTimeout(() => {
      //   dispatch('setConectPrice', {sessionStorage:state.sessionStorage})
      // }, 1000)
    }
  },
  setSubscribeOnPriceList({commit, state}, coinSymbolList) {
    if (!coinSymbolList || coinSymbolList.length == 0) return

    let subscriptionUID = sessionStorage.getItem('subscriptionUID')

    if ( subscriptionUID == null ) {
      subscriptionUID = Utils.getUuidv4()
      sessionStorage.setItem('subscriptionUID', subscriptionUID)
    }

    if (coinSymbolList && coinSymbolList.length > 0){
      coinSymbolList.forEach(coinSymbol=>{
        // 소켓 구독
        const subscribeItem = {
          coinSymbol: coinSymbol,
          subscriptionUID: sessionStorage.getItem('subscriptionUID'),
          data: new Array()
        }

        commit('setchannelToCoinPriceCoc', {subscribeItem:subscribeItem})
      })
    }



    //this.channelToPrice.set(coinSymbol, subscribeItem)
    //commit('setChannelToPrice', {subscribeItem:subscribeItem})
    commit('sendCoinPriceMessage', {apiCode:'DSA0003', apiType:'COC', coinSymbolList})
    //commit('setConnectcoinSymbol', {coinSymbol:coinSymbol})
  },
  setUpdateSubscribeOnPriceList({commit, state}, {coinSymbolList, popupSelcoinSymbolList}) {
    // 소켓 구독 수정
    
    let connectList = [];
    if (coinSymbolList && coinSymbolList.length > 0){
      
      
      for (let coinSymbol of state.channelToCoinPriceCoc.keys()) {
        if (coinSymbolList.indexOf(coinSymbol) == -1){ //coinSymbolList 접속중인 coinSymbol 없으면 구독 해지
          //구독 해지
          //console.log("구독 해지 종목? ", coinSymbol)
          commit('delchannelToCoinPriceCoc', {coinSymbol:coinSymbol});
          commit('sendCoinPriceMessage', {apiCode:'DSA0004', apiType:'COC', coinSymbolList:null, coinSymbol:coinSymbol})
          commit('sendCoinPriceMessage', {apiCode:'DSA0004', apiType:'COH', coinSymbolList:null, coinSymbol:coinSymbol})
          commit('sendCoinPriceMessage', {apiCode:'DSA0004', apiType:'COT', coinSymbolList:null, coinSymbol:coinSymbol})
          commit('sendCoinPriceMessage', {apiCode:'DSA0004', apiType:'COM', coinSymbolList:null, coinSymbol:coinSymbol})
          commit('sendCoinPriceMessage', {apiCode:'DSA0004', apiType:'CHL', coinSymbolList:null, coinSymbol:coinSymbol})
        }
      }
      
      coinSymbolList.forEach(coinSymbol=>{
        if (state.channelToCoinPriceCoc.get(coinSymbol) == undefined){ //접속중인 목록이 없는 목록만 추가
          // 소켓 구독
          const subscribeItem = {
            coinSymbol: coinSymbol,
            subscriptionUID: sessionStorage.getItem('subscriptionUID'),
            data: new Array()
          }

          commit('setchannelToCoinPriceCoc', {subscribeItem:subscribeItem})
          connectList.push(coinSymbol);
        }else{
          connectList.push(coinSymbol);
        }
      })
    }  


    //팝업창 호가 업데이트
    let cohConnectList = [];

    //선택중인 심볼 추가
    if (state.selectCoinSymbol != undefined){
      const selectCoinSymbol = state.selectCoinSymbol.name;
      cohConnectList = [selectCoinSymbol];

      //COH구독해지
      for (let coinSymbol of state.channelToCoinPriceCoh.keys()) {
        if (state.channelToCoinPriceCoh.get(selectCoinSymbol) != undefined){ //현재 종목과 다르면
          commit('sendCoinPriceMessage', {apiCode:'DSA0004', apiType:'COH', coinSymbolList:null, coinSymbol:coinSymbol})
        }
      }

      if (connectList.indexOf(selectCoinSymbol) == -1){
          // 소켓 구독
          const subscribeItem = {
            coinSymbol: selectCoinSymbol,
            subscriptionUID: sessionStorage.getItem('subscriptionUID'),
            data: new Array()
          }

          commit('setchannelToCoinPriceCoc', {subscribeItem:subscribeItem})

          connectList.push(selectCoinSymbol);
      }

      // 소켓 구독
      const subscribeItem = {
        coinSymbol: selectCoinSymbol,
        subscriptionUID: sessionStorage.getItem('subscriptionUID'),
        data: new Array()
      }

      commit('setchannelToCoinPriceCoh', {subscribeItem:subscribeItem})
      cohConnectList = [selectCoinSymbol];

      //console.log("selectCoinSymbol", selectCoinSymbol)

      commit('sendCoinPriceMessage', {apiCode:'DSA0003', apiType:'COT', coinSymbolList:null, coinSymbol:selectCoinSymbol});
      //commit('sendCoinPriceMessage', {apiCode:'DSA0003', apiType:'COM', coinSymbolList:null, coinSymbol:selectCoinSymbol});
      commit('sendCoinPriceMessage', {apiCode:'DSA0003', apiType:'CHL', coinSymbolList:null, coinSymbol:selectCoinSymbol});

    }   
    
    if (popupSelcoinSymbolList && popupSelcoinSymbolList.length > 0){
      for (let coinSymbol of state.channelToCoinPriceCoh.keys()) {
        if (popupSelcoinSymbolList.indexOf(coinSymbol) == -1){ //popupSelcoinSymbolList 접속중인 coinSymbol 없으면 구독 해지
          //구독 해지
          commit('delchannelToCoinPriceCoh', {coinSymbol:coinSymbol});
          let apiType = 'COH';

          //console.log("팝업 구독해지", coinSymbol)

          commit('sendCoinPriceMessage', {apiCode:'DSA0004', apiType:apiType, coinSymbolList:null, coinSymbol:coinSymbol})
        }
      }
      
      //COC 추가
      coinSymbolList.forEach(coinSymbol=>{
        if (state.channelToCoinPriceCoc.get(coinSymbol) == undefined){ //접속중인 목록이 없는 목록만 추가
          // 소켓 구독
          const subscribeItem = {
            coinSymbol: coinSymbol,
            subscriptionUID: sessionStorage.getItem('subscriptionUID'),
            data: new Array()
          }

          commit('setchannelToCoinPriceCoc', {subscribeItem:subscribeItem})
          connectList.push(coinSymbol);
        }
      })

      //COH 추가
      popupSelcoinSymbolList.forEach(coinSymbol=>{
        if (state.channelToCoinPriceCoh.get(coinSymbol) == undefined){ //접속중인 목록이 없는 목록만 추가
          // 소켓 구독
          const subscribeItem = {
            coinSymbol: coinSymbol,
            subscriptionUID: sessionStorage.getItem('subscriptionUID'),
            data: new Array()
          }

          commit('setchannelToCoinPriceCoh', {subscribeItem:subscribeItem})
          cohConnectList.push(coinSymbol);
        }else{ //호가 시세를 받기위해 목록에 추가
          cohConnectList.push(coinSymbol);
        }
      })
    }

    commit('sendCoinPriceMessage', {apiCode:'DSA0003', apiType:'COC', coinSymbolList:connectList});
    commit('sendCoinPriceMessage', {apiCode:'DSA0003', apiType:'COM', coinSymbolList:connectList});
    commit('sendCoinPriceMessage', {apiCode:'DSA0003', apiType:'COH', coinSymbolList:cohConnectList});
  },
  setSubscribeOnPrice({commit, state}, coinSymbol) {
    let subscriptionUID = sessionStorage.getItem('subscriptionUID')

    if ( subscriptionUID == null ) {
      subscriptionUID = Utils.getUuidv4()
      sessionStorage.setItem('subscriptionUID', subscriptionUID)
    }

    // 소켓 구독
    const subscribeItem = {
      coinSymbol,
      subscriptionUID: sessionStorage.getItem('subscriptionUID'),
      data: new Array()
    }

    commit('setchannelToCoinPriceCoc', {subscribeItem:subscribeItem})

    //this.channelToPrice.set(coinSymbol, subscribeItem)
    //commit('setChannelToPrice', {subscribeItem:subscribeItem})
    //commit('sendCoinPriceMessage', {apiCode:'DSA0003', apiType:'', coinSymbol:coinSymbol})
    commit('setConnectcoinSymbol', {coinSymbol:coinSymbol})
    
  },
  setUpdateSubscribeOnPrice({commit, state}, coinSymbol) {
    // 소켓 구독 수정
    const subscribeItem = {
      //coinSymbol,
      subscriptionUID: sessionStorage.getItem('subscriptionUID'),
      data: new Array()
    }

    commit('setchannelToCoinPriceCoc', {subscribeItem:subscribeItem})

    //commit('sendCoinPriceMessage', {apiCode:'DSA0003', apiType:'', coinSymbol:coinSymbol})
    
  },
  setUnSubscribeOnPrice({commit, state}, coinSymbol){
    // 소켓 구독 종료
    const data = state.channelToCoinPriceCoc.get(coinSymbol)
    if(data != null){
      if(state.isConnectCoinPrice){
        commit('sendCoinPriceMessage', {apiCode:'DSA0004', apiType:'COC', coinSymbol:coinSymbol})
        commit('delchannelToCoinPriceCoc', {coinSymbol:coinSymbol})
      }
    }    
  },
  setSelectCoinSymbol({commit, state}, coinSymbol){
    //시세 변경시 호가 구독 종료 호출
    if (window.name == undefined || window.name == null || window.name == ""){
      // let apiType = 'COH';
      // if (state.isConnectCoinPrice && state.selectCoinSymbol){
      //   apiType = 'COH';
      //   if (state.selectCoinSymbol.coinSymbolno == 10){ //국내선물
      //     apiType = 'FH0';
      //   }
      //   //coh 접속 목록 삭제
      //   commit('delchannelToCoinPriceCoh', {coinSymbol:state.selectCoinSymbol.name});
      //   commit('sendCoinPriceMessage', {apiCode:'DSA0004', apiType:apiType, coinSymbolList:null, coinSymbol:state.selectCoinSymbol.name});
      // }       
      
      // if (coinSymbol.coinSymbolno == 10){ //국내선물
      //   apiType = 'FH0';
      // }else{
      //   apiType = 'COH';
      // }
      // //coh 접속 목록 추가
      // const subscribeItem = {
      //   coinSymbol: coinSymbol,
      //   subscriptionUID: sessionStorage.getItem('subscriptionUID'),
      //   data: new Array()
      // }
      // commit('setchannelToCoinPriceCoh', {subscribeItem:subscribeItem});
      // commit('sendCoinPriceMessage', {apiCode:'DSA0003', apiType:apiType, coinSymbolList:null, coinSymbol:coinSymbol.name});
    }else{
      //팝업창일때
      if (window.name == "orderPop1"){
        window.opener.postMessage({ type: 'addcoinSymbol', popupId:window.name, coinSymbol:coinSymbol}, window.location.origin);
      }else if (window.name == "orderPop2"){
        window.opener.postMessage({ type: 'addcoinSymbol', popupId:window.name, coinSymbol:coinSymbol }, window.location.origin);
      }
    }

    commit('setSelectCoinSymbol', coinSymbol);
  },
  //구독중인 종목 모두 삭제
  resetCoinSymbol({commit, state}){
    for (let coinSymbol of state.channelToCoinPriceCoc.keys()) {
        //구독 해지
        //console.log("COC 구독 해지 종목? ", coinSymbol);
        commit('delchannelToCoinPriceCoc', {coinSymbol:coinSymbol});

        commit('sendCoinPriceMessage', {apiCode:'DSA0004', apiType:'COC', coinSymbolList:null, coinSymbol:coinSymbol})
        commit('sendCoinPriceMessage', {apiCode:'DSA0004', apiType:'COT', coinSymbolList:null, coinSymbol:coinSymbol})
        commit('sendCoinPriceMessage', {apiCode:'DSA0004', apiType:'COM', coinSymbolList:null, coinSymbol:coinSymbol})
    }

    for (let coinSymbol of state.channelToCoinPriceCoh.keys()) {
        //구독 해지
        //console.log("COH 구독 해지 종목? ", coinSymbol);
        commit('delchannelToCoinPriceCoh', {coinSymbol:coinSymbol});
        commit('sendCoinPriceMessage', {apiCode:'DSA0004', apiType:'COH', coinSymbolList:null, coinSymbol:coinSymbol})
    }

    //선택종목 초기화
    commit("resetSelectCoinSymbol");
  },
  //---폴링시세 관련---
  setPollingCoinPrice({commit, state, dispatch}, {symCd:symCd, symbolList:symbolList}){
    commit('setisPolingCoinPrice', { symCd: symCd, symbolList: symbolList });
    if (state.priceInterval == null){
      dispatch('getPollingCoinData');
    }
  },
  getPollingCoinData({commit, state, dispatch}){
    state.coinPriceInterval = setInterval(() => {
      return new Promise((resolve, reject) => { 
        window.$http
          .get('apisisecoin/getCoinCurPrice', { params: { symCd:state.pollingCoinSymCd, symbolList: state.pollingCoinSymList } })
          .then(response => {
            if (response) {
              if (response.data !== null) {
                commit('setPollingData', {data:response.data});
              }
            }
            resolve()
          })
          .catch(e => reject(e))
      }) 
    }, state.pollingCoinDelayTime)
  },
  setPollingType({commit, state, dispatch}, {sckTpCd:sckTpCd, sckTpDsc:sckTpDsc}){
    let type = sckTpCd;
    let time = 1000;
    if (type == undefined || type == null || type.trim() == ""){
      type = "SCKTP02"; //디폴트 폴링
    }
    commit('setCoinPriceReqType', {sckTpCd:type});
    if (type == "SCKTP02"){
      time = 100;
    }else if (type == "SCKTP03"){
      time = 300;
    }else if (type == "SCKTP04"){
      time = 1000;
    }

    commit('setDelayTime', {time:time});
  },
  //---폴링시세 관련---
  //---팝업시세 관련---
  setChannelData({ commit, dispatch }, {type, data}) {
    if (type == 'COC') {
      commit('setCocData', {cocData:data})
    }else if (type == 'COH'){
      commit('setCohData', {cohData:data})
    }
  },
  //---팝업시세 관련---

  // getCOCReqData({commit, state}, {apiCode:apiCode, coinSymbol:coinSymbol}) {
  //   let apiTypeData = ['COC'];
  //   let apiType = 'COC';

  //   const reqData = {
  //     header: {
  //       apiCode : apiCode,
  //       token : state.sessionStorage,
  //     },
  //     body : {
  //       apiType : apiType,
  //       coinSymbol : coinSymbol,
  //       apiTypeData: apiTypeData,
  //       coinSymbolData : [coinSymbol]
  //     }
  //   }
  //   return reqData

  // },    
  // getCOHReqData({commit, state}, {apiCode:apiCode, coinSymbol:coinSymbol}) {
  //     let apiTypeData = ['COH'];
  //     let apiType = 'COH';
      
  //     const reqData = {
  //         header: {
  //         apiCode : apiCode,
  //         token : state.sessionStorage,
  //         },
  //         body : {
  //         apiType : apiType,
  //         coinSymbol : coinSymbol,
  //         apiTypeData: apiTypeData,
  //         coinSymbolData : [coinSymbol]
          
  //         }
  //     }
  //     return reqData
  // },
  //커넥션 종료
  setCoinDisconnect({commit, state}){
    commit('setCoinDisconnect', {})
  },
  //폴링 종료
  setClearCoinPolling({commit, state}){
    commit("setCoinClearCoinPolling")
  }
}
  
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}