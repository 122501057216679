<template>
  <div>
    <header class="partners-header">
      <img :src="imageSrc" alt="" class="logo" />
      <div class="page-title">PARTNER</div>
      <div class="user">
        <div class="user-id" v-if="this.futUseYn == 'Y' && this.coinUseYn == 'Y'" @click="goToFut"
          style="margin-right: 20px; cursor: pointer;"><span>선물</span></div>
        <div class="user-id" v-if="this.futUseYn == 'Y' && this.coinUseYn == 'Y'" @click="goToCoin"
          style="margin-right: 50px; cursor: pointer;"><span>코인</span></div>
        <img src=" @/assets/icons/user-white.svg" alt="" class="user-icon" />
        <div class="user-id"><span>{{ loginUserInfo.usrId }}</span> / <span>{{ loginUserInfo.authNm }}</span></div>
        <div class="logout" @click="logout" v-if="getLoginUserInfo != undefined && getLoginUserInfo.authId">
          <img class="icon" src="@/assets/icons/logout.svg" alt="" />
          로그아웃
        </div>
      </div>
    </header>
    <div class="page-container">
      <div class="page-container-inner">
        <!-- tab nav -->
        <div class="tab-nav-container">
          <div class="tab" :class="{ active: selectedTab === 'tab1' }" @click="selectedTab = 'tab1'">회원정보</div>
          <div class="tab" :class="{ active: selectedTab === 'tab2' }" @click="selectedTab = 'tab2'">실시간현황</div>
          <div class="tab" :class="{ active: selectedTab === 'tab3' }" @click="selectedTab = 'tab3'">입출금</div>
          <div class="tab" :class="{ active: selectedTab === 'tab4' }" @click="selectedTab = 'tab4'">거래내역</div>
          <div class="tab" :class="{ active: selectedTab === 'tab5' }" @click="selectedTab = 'tab5'">로그내역</div>
          <div class="tab" :class="{ active: selectedTab === 'tab6' }" @click="selectedTab = 'tab6'">개별손익</div>
          <div class="tab" :class="{ active: selectedTab === 'tab7' }" @click="selectedTab = 'tab7'">정산</div>
        </div>

        <!-- partner level -->
        <div class="partner-level-container" v-if="checkAuth3(this.authId)">
          <div class="filter-container">
            <div class="filter-row">
              <div class="inner">
                <button class="btn-line" @click="resetData()">초기화</button>
              </div>
            </div>
          </div>
          <div class="partner-level" v-if="checkAuth1(this.authId)">
            <div class="partner-level-header">3레벨</div>
            <ul class="partner-level-list">
              <li v-for="partner in partnerList4" v-bind:key="partner.usrId"
                :class="{ 'active': part3Id == partner.usrId }" @click=selectPart3(partner)>
                {{ partner.usrId }}
              </li>
            </ul>
          </div>
          <div class="partner-level" v-if="checkAuth2(this.authId)">
            <div class="partner-level-header">2레벨</div>
            <ul class="partner-level-list">
              <li v-for="partner in partnerList3" v-bind:key="partner.usrId"
                :class="{ 'active': part2Id == partner.usrId }" @click=selectPart2(partner)>
                {{ partner.usrId }}
              </li>
            </ul>
          </div>
          <div class="partner-level" v-if="checkAuth3(this.authId)">
            <div class="partner-level-header">1레벨</div>
            <ul class="partner-level-list">
              <li v-for="partner in partnerList2" v-bind:key="partner.usrId"
                :class="{ 'active': part1Id == partner.usrId }" @click=selectPart1(partner)>
                {{ partner.usrId }}
              </li>
            </ul>
          </div>
        </div>

        <div class="tab2-container" v-if="selectedTab === 'tab2'">
          <div class="filter-container">
            <div class="filter-row">
              <div class="inner">
                <div class="base-dropdown">
                  <BaseDropdown id="tp1" :options="usrTypeList" :default="usrTypeList[0].name" @tp1="setTp1" />
                  <!-- <div class="dropdown">
                    <div class="selected">1. 일반</div>
                    <img src="@/assets/icons/dropdown-triangle.svg" alt="" class="dropdown-triangle" />
                  </div> -->
                </div>
                <input type="text" v-model="searchFormLive.userInfoNm" />
              </div>
              <div class="inner">
                <div class="checkbox-default">
                  <input type="checkbox" id="tp2" v-model="searchFormLive.tp2Flag" />
                  <label for="tp2">증거금</label>
                </div>
                <div class="checkbox-default">
                  <input type="checkbox" id="tp3" v-model="searchFormLive.tp3Flag" />
                  <label for="tp3">금일매매</label>
                </div>
                <div class="radio-default">
                  <input type="radio" id="usrTypeAll" name="usrTypeLong" value="1" v-model="selectedOption" />
                  <label for="usrTypeAll">전체</label>
                </div>
                <div class="radio-default">
                  <input type="radio" id="usrTypeNormal" name="usrTypeLong" value="2" v-model="selectedOption" />
                  <label for="usrTypeNormal">정회원</label>
                </div>
                <div class="radio-default">
                  <input type="radio" id="usrTypeTester" name="usrTypeLong" value="3" v-model="selectedOption" />
                  <label for="usrTypeTester">테스터</label>
                </div>
                <div class="checkbox-default">
                  <input type="checkbox" id="tp4" v-model="isChecked1" />
                  <label for="tp4">10초</label>
                </div>
              </div>
              <div class="inner">
                <button class="btn-search" @click="searchTab2">
                  <img src="@/assets/icons/icon-search.svg" alt="" />
                  조회
                </button>
                <button class="btn-search" @click="searchAllTab2">
                  <img src="@/assets/icons/icon-search.svg" alt="" />
                  전체회원조회
                </button>
              </div>
            </div>
          </div>
          <!-- <div class="white-label-table-container" style="width: 720px">
            <table class="white-label-table">
              <tbody>
                <tr>
                  <th>실시간</th>
                  <td>{{ dailyPlMn.totalValPl }}</td>
                  <th>총접속</th>
                  <td>안됨</td>
                  <th>총매매</th>
                  <td>0</td>
                </tr>
              </tbody>
            </table>
          </div> -->
          <div class="white-label-table-container">
            <table class="white-label-table">
              <tbody>
                <tr>
                  <th>실시간손익</th>
                  <td><span
                      :class="{ 'color-red': dailyPlMn.totalValPl > 0, 'color-blue': dailyPlMn.totalValPl < 0 }">{{
                        thousand(dailyPlMn.totalValPl) }}</span></td>
                  <th>매매손익</th>
                  <td><span :class="{ 'color-red': dailyPlMn.totPlMn > 0, 'color-blue': dailyPlMn.totPlMn < 0 }">{{
                    thousand(dailyPlMn.totPlMn) }}</span></td>
                  <th>수수료</th>
                  <td><span class="color-blue">{{ thousand(dailyPlMn.totCms) }}</span></td>
                </tr>
                <tr>
                  <th>실현손익</th>
                  <td><span
                      :class="{ 'color-red': dailyPlMn.totPlMn + dailyPlMn.totCms > 0, 'color-blue': dailyPlMn.totPlMn + dailyPlMn.totCms < 0 }">{{
                        thousand(dailyPlMn.totPlMn + dailyPlMn.totCms) }}</span></td>
                  <th>증거금</th>
                  <td>{{ thousand(dailyPlMn.totBalMn) }}</td>
                  <th></th>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="partner-table-container">
            <div class="ag-container">
              <AgRealTimeTable :setGridSearch="setSearchFrom" :symbolList="symbolList"
                :memberValPlList="memberValPlList" @updateTotalValPl="updateBalAmtCms" />
            </div>
          </div>
        </div>
        <div class="division-line"></div>
        <div class="partner-table-container" v-if="selectedTab === 'tab2'" style="margin-top: 40px;">
          <div class="table-title">보유포지션(실시간)</div>
          <div class="white-label-table-container">
            <table class="white-label-table">
              <tbody>
                <tr>
                  <th style="width:20%">국내선물(매도/매수)</th>
                  <td><span>{{ thousand(dailyPlMn.futQty) }}</span></td>
                  <th style="width:20%">해외선물(매도/매수)</th>
                  <td><span>{{ thousand(dailyPlMn.fxQty) }}</span></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="ag-container" Style="display:none">
            <AgCurrentPositionTable :setGridSearch="setSearchFrom" :symbolList="symbolList"
              @updateLivePosition="updateLivePosition" />
          </div>
        </div>
        <div class="partner-table-container" v-if="selectedTab === 'tab2'" style="margin-top: 40px;">
          <div class="table-title">회원별포지션(실시간)</div>
          <div class="ag-container">
            <AgMemberPositionTable :setGridSearch="setSearchFrom" :symbolList="symbolList"
              @updateTotalValPl="updateTotalValPl" />
          </div>
        </div>
        <div class="partner-table-container" v-if="selectedTab === 'tab2'" style="margin-top: 40px;">
          <div class="table-title">미체결(실시간)</div>
          <div class="ag-container">
            <AgPendingPositionTable :setGridSearch="setSearchFrom" :symbolList="symbolList" />
          </div>
        </div>

        <div class="partner-table-container" v-if="selectedTab === 'tab1'">
          <!-- <div class="table-title">입출금내역</div> -->
          <div class="filter-container">
            <div class="filter-row">
              <!--<div class="inner">
                <div class="radio-default">
                  <input type="radio" id="member" name="memberType" value="" />
                  <label for="member">정회원</label>
                </div>
                <div class="radio-default">
                  <input type="radio" id="tester" name="memberType" value="" />
                  <label for="tester">테스터</label>
                </div>
                <div class="radio-default">
                  <input type="radio" id="event" name="memberType" value="" />
                  <label for="event">이벤트</label>
                </div>
              </div>-->
              <div class="inner">
                <div class="base-dropdown">
                  <BaseDropdown id="usrType" :options="usrTypeList" :default="usrTypeList[0].name"
                    @usrType="setUsrType1" />
                </div>
                <input type="text" value="" v-model="searchFormInfo.userInfoNm" />
              </div>
              <div class="inner">
                <button class="btn-search" @click="searchUserInfo1()">
                  <img src="@/assets/icons/icon-search.svg" alt="" />
                  조회
                </button>
                <button class="btn-search" @click="searchAllUserInfo1()">
                  <img src="@/assets/icons/icon-search.svg" alt="" />
                  전체회원조회
                </button>
              </div>
            </div>
          </div>
          <div class="ag-container">
            <AgUserInfoTable :setGridSearch="setSearchUserInfo" :userInfoList="userInfoList" />
          </div>
        </div>

        <div class="partner-table-container" v-if="selectedTab === 'tab3'" style="margin-top: 40px">
          <div class="filter-container">
            <div class="filter-row">
              <div class="inner">
                <div class="base-dropdown">
                  <BaseDropdown id="usrType" :options="usrTypeList" :default="usrTypeList[0].name"
                    @usrType="setUsrType2" />
                </div>
                <input type="text" value="" v-model="searchFormInout.userInoutNm" />
              </div>
              <div class="inner">
                <button class="btn-search" @click="searchUserInfo2()">
                  <img src="@/assets/icons/icon-search.svg" alt="" />
                  조회
                </button>
                <button class="btn-search" @click="searchAllUserInfo2()">
                  <img src="@/assets/icons/icon-search.svg" alt="" />
                  전체회원조회
                </button>
              </div>
            </div>
          </div>
          <div class="ag-container">
            <AgUserTable :setGridSearch="setSearchUser" :userInoutList="userInoutList"
              @event:clickSearchInout="SearchInoutData" />
          </div>
        </div>
        <div class="partner-table-container" v-if="selectedTab === 'tab4'" style="margin-top: 40px">
          <div class="filter-container">
            <div class="filter-row">
              <div class="inner">
                <div class="base-dropdown">
                  <BaseDropdown id="usrType" :options="usrTypeList" :default="usrTypeList[0].name"
                    @usrType="setUsrType3" />
                </div>
                <input type="text" value="" v-model="searchFormOrder.userInoutNm" />
              </div>
              <div class="inner">
                <button class="btn-search" @click="searchUserInfo3()">
                  <img src="@/assets/icons/icon-search.svg" alt="" />
                  조회
                </button>
                <button class="btn-search" @click="searchAllUserInfo3()">
                  <img src="@/assets/icons/icon-search.svg" alt="" />
                  전체회원조회
                </button>
              </div>
            </div>
          </div>
          <div class="ag-container">
            <AgUserTable :setGridSearch="setSearchUser" :userInoutList="userInoutList"
              @event:clickSearchInout="SearchInoutData" />
          </div>
        </div>
        <div class="partner-table-container" v-if="selectedTab === 'tab5'" style="margin-top: 40px">
          <div class="filter-container">
            <div class="filter-row">
              <div class="inner">
                <div class="base-dropdown">
                  <BaseDropdown id="usrType" :options="usrTypeList" :default="usrTypeList[0].name"
                    @usrType="setUsrType4" />
                </div>
                <input type="text" value="" v-model="searchFormLog.userInoutNm" />
              </div>
              <div class="inner">
                <button class="btn-search" @click="searchUserInfo4()">
                  <img src="@/assets/icons/icon-search.svg" alt="" />
                  조회
                </button>
                <button class="btn-search" @click="searchAllUserInfo4()">
                  <img src="@/assets/icons/icon-search.svg" alt="" />
                  전체회원조회
                </button>
              </div>
            </div>
          </div>
          <div class="ag-container">
            <AgUserTable :setGridSearch="setSearchUser" :userInoutList="userInoutList"
              @event:clickSearchInout="SearchInoutData" />
          </div>
        </div>
        <div class="partner-table-container" v-if="selectedTab === 'tab6'" style="margin-top: 40px">
          <div class="filter-container">
            <div class="filter-row">
              <div class="inner">
                <div class="base-dropdown">
                  <BaseDropdown id="usrType" :options="usrTypeList" :default="usrTypeList[0].name"
                    @usrType="setUsrType5" />
                </div>
                <input type="text" value="" v-model="searchFormProfit.userInoutNm" />
              </div>
              <div class="inner">
                <button class="btn-search" @click="searchUserInfo5()">
                  <img src="@/assets/icons/icon-search.svg" alt="" />
                  조회
                </button>
                <button class="btn-search" @click="searchAllUserInfo5()">
                  <img src="@/assets/icons/icon-search.svg" alt="" />
                  전체회원조회
                </button>
              </div>
            </div>
          </div>
          <div class="ag-container">
            <AgUserTable :setGridSearch="setSearchUser" :userInoutList="userInoutList"
              @event:clickSearchInout="SearchInoutData" />
          </div>
        </div>
        <div class="partner-table-container" v-if="selectedTab === 'tab3'" style="margin-top: 40px">
          <div class="filter-container">
            <div class="table-title">입출금내역</div>
            <div class="filter-row">
              <div class="inner">
                <label for="" class="row-label">조회일자</label>
                <div class="datepicker-container">
                  <DatePicker v-model="gridSearch.fromDate" />
                  ~
                  <DatePicker v-model="gridSearch.toDate" />
                </div>
                <div class="base-dropdown">
                  <BaseDropdown id="inoutType" :options="inoutTypeList" :default="inoutTypeList[0].name"
                    @inoutType="setInoutType" v-model="searchFormInout.inoutType" />
                </div>
              </div>
              <button class="btn-search" @click="searchUserInout()">
                <img src="@/assets/icons/icon-search.svg" alt="" />
                조회
              </button>
            </div>
          </div>

          <div class="ag-container" style="height: 424px">
            <AgDepositWithdrawTablePartner :setGridSearch="setSearchWithdraw" :userInoutDataList="userInoutDataList" />
          </div>
        </div>

        <div class="partner-table-container" v-if="selectedTab === 'tab4'" style="margin-top: 40px">
          <div class="table-title">거래내역</div>
          <div class="filter-container">
            <div class="filter-row">
              <div class="inner">
                <label for="" class="row-label">영업일</label>
                <DatePicker v-model="gridSearch.trdDt" />
              </div>
              <div class="checkbox-default">
                <input type="checkbox" id="all" value="" v-model="isChecked" @change="setOrdType" />
                <label for="all">체결</label>
              </div>
              <div class="inner">
                <button class="btn-search" @click="searchUserOrder()">
                  <img src="@/assets/icons/icon-search.svg" alt="" />
                  조회
                </button>
              </div>
            </div>
          </div>
          <div class="ag-container" style="height: 424px">
            <AgTransactionTablePartner :setGridSearch="setSearchOrder" :userOrderDataList="userOrderDataList" />
          </div>
        </div>

        <div class="partner-table-container" v-if="selectedTab === 'tab5'" style="margin-top: 40px">
          <div class="table-title">로그내역</div>
          <div class="filter-container">
            <div class="filter-row">
              <div class="inner">
                <label for="" class="row-label">조회일자</label>
                <div class="datepicker-container">
                  <DatePicker v-model="gridSearch.fromDate" />
                  ~
                  <DatePicker v-model="gridSearch.toDate" />
                </div>
              </div>
              <button class="btn-search" @click="searchUserLog()">
                <img src="@/assets/icons/icon-search.svg" alt="" />
                조회
              </button>
            </div>
          </div>
          <div class="ag-container" style="height: 424px">
            <AgLogTablePartner :setGridSearch="setSearchLog" :userLogDataList="userLogDataList" />
          </div>
        </div>

        <div class="partner-table-container" v-if="selectedTab === 'tab6'" style="margin-top: 40px">
          <div class="table-title">개별손익</div>
          <div class="filter-container">
            <div class="filter-row">
              <div class="inner">
                <label for="" class="row-label">조회일자</label>
                <div class="datepicker-container">
                  <DatePicker v-model="gridSearch.fromDate" />
                  ~
                  <DatePicker v-model="gridSearch.toDate" />
                </div>
              </div>
              <button class="btn-search" @click="searchUserProfit()">
                <img src="@/assets/icons/icon-search.svg" alt="" />
                조회
              </button>
            </div>
          </div>

          <div class="ag-container" style="height: 424px">
            <AgProfitLossTablePartner :setGridSearch="setSearchProfit" :userProfitDataList="userProfitDataList" />
          </div>
        </div>

        <div class="partner-table-container" v-if="selectedTab === 'tab7'" style="margin-top: 40px">
          <div class="table-title">정산내역</div>
          <div class="filter-container">
            <div class="filter-row">
              <div class="inner">
                <label for="" class="row-label">조회일자</label>
                <div class="datepicker-container">
                  <DatePicker v-model="gridSearch.fromDate" />
                  ~
                  <DatePicker v-model="gridSearch.toDate" />
                </div>
              </div>

              <div class="inner">
                <div class="base-dropdown">
                  <BaseDropdown id="usrType" :options="usrTypeList" :default="usrTypeList[0].name"
                    @usrType="setUsrType6" />
                </div>
                <input type="text" value="" v-model="searchFormAllProfit.userProfitNm" />
              </div>
              <div class="inner">
                <button class="btn-search" @click="searchUserAllProfit()">
                  <img src="@/assets/icons/icon-search.svg" alt="" />
                  조회
                </button>
                <button class="btn-search" @click="searchAllUserAllProfit()">
                  <img src="@/assets/icons/icon-search.svg" alt="" />
                  전체회원조회
                </button>
              </div>
            </div>
          </div>
          <div class="ag-container">
            <AgSettlementTable :setGridSearch="setSearchAllProfit" :userAllProfitDataList="userAllProfitDataList" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AgUserInfoTable from '@/components/agTables/AgUserInfoTable.vue';
import AgUserTable from '@/components/agTables/AgUserTable.vue';
import DatePicker from '@/components/DatePicker.vue';
import AgDepositWithdrawTablePartner from '@/components/agTables/AgDepositWithdrawTablePartner.vue';
import AgTransactionTablePartner from '@/components/agTables/AgTransactionTablePartner.vue';
import AgLogTablePartner from '@/components/agTables/AgLogTablePartner.vue';
import AgProfitLossTablePartner from '@/components/agTables/AgProfitLossTablePartner.vue';
import AgSettlementTable from '@/components/agTables/AgSettlementTable.vue';
import AgRealTimeTable from '@/components/agTables/AgRealTimeTable.vue';
import AgCurrentPositionTable from '@/components/agTables/AgCurrentPositionTable.vue';
import AgPendingPositionTable from '@/components/agTables/AgPendingPositionTable.vue';
import AgMemberPositionTable from '@/components/agTables/AgMemberPositionTable.vue';
import Utils from '@/modules/utils'

import BaseDropdown from '@/components/BaseDropdown.vue'; //콤보박스
import CodeDropDown from '@/components/CodeDropDown.vue'; //공통코드콤보박스
import { stringCount } from '@/modules/helper';
import Const from '@/const';
import defaultImage1 from '@/assets/images/logo-white1.png'; // import를 사용하여 이미지 가져오기
import defaultImage2 from '@/assets/images/logo-white.png'; // import를 사용하여 이미지 가져오기
import defaultImage3 from '@/assets/images/logo-bein.png'; // import를 사용하여 이미지 가져오기
import defaultImage4 from '@/assets/images/logo-white-sp.png';

export default {
  components: {
    AgUserInfoTable,
    AgUserTable,
    DatePicker,
    AgDepositWithdrawTablePartner,
    AgTransactionTablePartner,
    AgLogTablePartner,
    AgProfitLossTablePartner,
    AgSettlementTable,
    AgRealTimeTable,
    AgCurrentPositionTable,
    AgPendingPositionTable,
    AgMemberPositionTable,
    BaseDropdown,
    CodeDropDown,
  },
  name: 'WhiteLabelPartnerPage',
  computed: {
    imageSrc() {
      const domain = window.location.hostname; // 현재 도메인 가져오기
      if (domain === 'mng.n-globalinv.com' || domain === 'n-globalinv.com') {
        return defaultImage1
      } else if (domain === 'beinasset.com') {
        return defaultImage3
      } else if (domain === 'sp-globalinv.com') {
        return defaultImage4
      } else {
        return defaultImage2
      }
    },
    // 로그인 여부
    isLogin() {
      return window.$store.getters['Login/isLogin'];
    },
    //소켓시세
    changeOvcData: function () {
      let ovcData = window.$store.getters['SocketPrice/getOvcData'];
      return ovcData;
    },
    //폴링시세
    changeOvcDataList: function () {
      let ovcData = window.$store.getters['SocketPrice/getOvcDataList'];
      return ovcData;
    },
    changeFvSymbol: function () {
      let custom = window.$store.getters['Layout/getFavoriteGridCustom'];
      if (custom == undefined) {
        return null;
      }
      return { socketSymbol: custom.socketSymbol, socketSymbolCnt: this.socketSymbolCnt + 1 };
    },
    tikDotSz() {
      return stringCount(this.symbol.pricescale);
    },
    resultOms: function () { //OMS 주문 리턴
      return window.$store.getters['SocketOms/getCompleteOrder'];
    },
    resultMsg: function () { //MSG 메시지 리턴
      return window.$store.getters['SocketMsg/getMessage'];
    },
    getLoginUserInfo: function () {
      let loginInfo = window.$store.getters['Login/getLoginUserInfo'];
      return loginInfo;
    },
  },
  watch: {
    isChecked1(newValue) {
      if (newValue) {
        // 체크박스가 체크되면 10초마다 search 메서드를 실행
        this.startInterval();
      } else {
        // 체크박스가 해제되면 반복 중단
        this.stopInterval();
      }
    },
    selectedTab() {
      if (this.selectedTab !== "tab2") {
        this.stopInterval();
      }
    },
    getLoginUserInfo: {
      handler(userInfo) {
        this.loginUserInfo = userInfo
        this.authId = this.loginUserInfo.authId
        this.usrId = this.loginUserInfo.usrId
        this.cpnId = this.loginUserInfo.cpnId
        this.searchpartner(0)
      }
    },
    //소켓시세
    changeOvcData(newPrice) {
      if (this.symbol && newPrice.symbol == this.symbol.name) {
        this.nowPrice = newPrice;

        //일반주문 가격 시세 첫번째 값으로 저장
        if (this.ordStdPrc == 0) {
          this.ordStdPrc = Number(newPrice.curPr).toFixed(this.tikDotSz);
        }
      }
    },
    //폴링시세
    changeOvcDataList(newPrice) {
      if (newPrice != null && newPrice.length > 0) {
        newPrice.forEach(data => {
          if (this.symbol && data.symbol == this.symbol.name) {
            // this.curPr = data.curPr;
            // this.setValProLoss(this.grid.rowData[0]); //평가손익 계산
            // this.gridApi.refreshCells();
          }
        })
      }
    },
    changeFvSymbol(val) {
      this.updateSubscribeOnPrice()
    },
    resultOms(res) {
      if (res.result == "S") {
        // this.completeOrder(res);
      } else {
        this.procFailMessage(res);
        //실패시 리스트목록 주문 목록 재조회
        if (res.body.code != "OMSFL007" && res.body.code != "OMSFL011" && res.body.code != "OMSFL002" && res.body.code != "OMSFL012"
          && res.body.code != "OMSFL014" && res.body.code != "OMSFL016" && res.body.code != "OMSFL017") {
          this.selectOrder(this.symbol.name);
        }
      }
    },
    resultMsg: {
      handler(res) {
        if (res.apiCode == Const.MSG_API_TYPE.BANK) {
          this.reloadPanelComp = this.reloadPanelComp + 1;

          //}else if (returnData.header.apiCode == 'MSG0001'){
          //   this.reloadNotice = this.reloadNotice + 1
        } else if (res.apiCode == Const.MSG_API_TYPE.LOGOUT) {

          window.$store.dispatch('Login/logout');

          this.disconnect();

          this.$alert.alert('다른 브라우저에서 로그인하여 <span style="color:red;">로그아웃</span> 되었습니다.<br/><br/>로그인을 다시 시도하시거나 사용을 종료 해 주십시오.', '안내')
            .then(() => {

              this.$router.push('/login');
            });

          return;
        }

        // 알림 재조회
        //EventBus.$emit('Alarm:event:refresh', {});

        //
        if (res.body.msg != undefined) {
          this.$notify({
            group: 'notification',
            text: '<span style="color: var(--main-red)">알림</span> ' + res.body.msg
          });
        }
      },
      deep: true
    },
  },
  data() {
    return {
      intervalId: null,     // setInterval ID 저장
      isChecked1: false,
      loginUserInfo: { usrId: null, planNm: null, authNm: null },
      selectedOption: "1",
      selectedTab: 'tab1',
      usrTypeList: [{ cd: "0", name: '아이디' }, { cd: "1", name: '성명' }],
      inoutTypeList: [{ cd: "0", name: '전체' }, { cd: "1", name: '입금' }, { cd: "2", name: '출금' }],
      searchFormInfo: { ordType: '0', inoutType: '0', usrType: '0', usrRol: '0', type: '1', partnerType: '', tp1: '0', tp2: '0', tp3: '0', tp2Flag: false, tp3Flag: false },
      searchFormLive: { ordType: '0', inoutType: '0', usrType: '0', usrRol: '0', type: '1', partnerType: '', tp1: '0', tp2: '0', tp3: '0', tp4: '1', tp2Flag: false, tp3Flag: false },
      searchFormLive1: { ordType: '0', inoutType: '0', usrType: '0', usrRol: '0', type: '1', partnerType: '', tp1: '0', tp2: '0', tp3: '0', tp4: '1', tp2Flag: false, tp3Flag: false },
      searchFormInout: { ordType: '0', inoutType: '0', usrType: '0', usrRol: '0', type: '1', partnerType: '', tp1: '0', tp2: '0', tp3: '0', tp2Flag: false, tp3Flag: false },
      searchFormOrder: { ordType: '0', inoutType: '0', usrType: '0', usrRol: '0', type: '1', partnerType: '', tp1: '0', tp2: '0', tp3: '0', tp2Flag: false, tp3Flag: false },
      searchFormLog: { ordType: '0', inoutType: '0', usrType: '0', usrRol: '0', type: '1', partnerType: '', tp1: '0', tp2: '0', tp3: '0', tp2Flag: false, tp3Flag: false },
      searchFormProfit: { ordType: '0', inoutType: '0', usrType: '0', usrRol: '0', type: '1', partnerType: '', tp1: '0', tp2: '0', tp3: '0', tp2Flag: false, tp3Flag: false },
      searchFormAllProfit: { ordType: '0', inoutType: '0', usrType: '0', usrRol: '0', type: '1', partnerType: '', tp1: '0', tp2: '0', tp3: '0', tp2Flag: false, tp3Flag: false },
      searchFormAllProfit1: { ordType: '0', inoutType: '0', usrType: '0', usrRol: '0', type: '1', partnerType: '', tp1: '0', tp2: '0', tp3: '0', tp2Flag: false, tp3Flag: false },
      setSearchFrom: null,
      setSearchUser: null,
      setSearchUserInfo: null,
      setSearchWithdraw: null,
      setSearchOrder: null,
      setSearchLog: null,
      setSearchProfit: null,
      setSearchAllProfit: null,
      priceReqType: "SCKTP02", //시세 받는방식 socket(SCKTP01), polling(SCKTP02/SCKTP03)
      symbolList: [],
      symbolCdList: [],
      rtPosSymbolList: [],
      symbol: null,
      authId: null,
      usrId: null,
      cpnId: null,
      inoutUsrId: null,
      inoutUsrSeq: '',
      isChecked: false,
      partnerList2: [],
      partnerList3: [],
      partnerList4: [],
      userInfoList: [],
      userInoutList: [],
      userInoutDataList: [],
      userOrderDataList: [],
      userLogDataList: [],
      userProfitDataList: [],
      userAllProfitDataList: [],
      gridSearch: {
        fromDate: Utils.getToday(),
        toDate: Utils.getToday(),
        trdDt: Utils.getToday(),
      },
      part1Id: null,
      part2Id: null,
      part3Id: null,
      dailyPlMn: { totPlMn: 0, totCms: 0, totalValPl: 0, totBalMn: 0, futQty: 0, fxQty: 0 },
      memberValPlList: { list: [] },
      futUseYn: null,
      coinUseYn: null,
    };
  },
  created() {
    //소켓 접속 체크
    if (window.$store.sckTpCd != undefined) {
      if (window.$store.sckTpCd == "SCKTP01") { //소켓

      } else if (window.$store.sckTpCd == "SCKTP02" || window.$store.sckTpCd == "SCKTP03") { //폴링
        this.priceReqType = window.$store.sckTpCd;
        //폴링시간 설정
        window.$store.dispatch('SocketPrice/setPollingDelayTime', { time: window.$store.sckTpDsc });
      }
    }

    this.domainCheck();

    this.loginChk();

    this.selectSymbolList()

    //소켓연결시
    if (this.priceReqType == "SCKTP01") {
      this.connectPrice();
    }

    //주문 소켓 서버 연결
    this.connectOms();

    //message 소켓 서버 연결
    this.connectMsg();
  },
  mounted() {
    this.loginUserInfo = window.$store.getters['Login/getLoginUserInfo'];
    this.authId = this.loginUserInfo.authId
    this.usrId = this.loginUserInfo.usrId
    this.cpnId = this.loginUserInfo.cpnId
    this.searchpartner(0)

  },
  methods: {
    async domainCheck() {
      const host = window.location.host;
      const formData = { hostUrl: host };

      await this.$lhttp.post('/auth/domainChk', formData).then((response) => {
        //console.log("response... ", response);
        if (response.code == 200) {
          if (response.data != undefined && response.data.cpnId > 0) {
            //선물, 코인 사용여부
            if (response.data.futUseYn != undefined) {
              this.futUseYn = response.data.futUseYn;
            }
            if (response.data.coinUseYn != undefined) {
              this.coinUseYn = response.data.coinUseYn;
            }
            if (this.futUseYn == "N" && this.coinUseYn == "Y") {
              this.$router.push('/coinpartner');
            }
          } else {
            this.$alert.alert('접속하신 도메인정보의 회사정보가 없습니다.\n관리자에게 문의하세요.', '알림');
            return;
          }
        } else {
          this.$alert.alert('접속하신 도메인정보의 회사정보가 없습니다.\n관리자에게 문의하세요.', '알림');
          return;
        }
      })
    },
    beforeDestroy() {
      // Vue 인스턴스가 파괴될 때 interval을 중단하여 메모리 누수를 방지
      this.stopInterval();
    },
    startInterval() {
      // 10초마다 search 메서드를 실행하고 interval ID를 저장
      this.intervalId = setInterval(() => {
        this.searchAllTab2();
      }, 10000);
    },
    stopInterval() {
      // interval을 중단하고 interval ID를 초기화
      if (this.intervalId) {
        clearInterval(this.intervalId);
        this.intervalId = null;
      }
    },
    SearchInoutData(data, data1) {
      this.inoutUsrId = data
      this.inoutUsrSeq = data1
      if (this.selectedTab == "tab3") {
        this.searchFormInout.setusrId = this.inoutUsrId
        this.searchFormInout.setusrSeq = this.inoutUsrSeq
        this.searchFormInout.cpnId = this.cpnId
        this.searchFormInout.fromDate = this.gridSearch.fromDate
        this.searchFormInout.toDate = this.gridSearch.toDate
        this.setSearchWithdraw = Object.assign({}, this.searchFormInout); //입출금
      } else if (this.selectedTab == "tab4") {
        this.searchFormOrder.setusrId = this.inoutUsrId
        this.searchFormOrder.setusrSeq = this.inoutUsrSeq
        this.searchFormOrder.cpnId = this.cpnId
        this.searchFormOrder.trdDt = this.gridSearch.trdDt
        this.searchFormOrder.tp = this.gridSearch.ordType
        this.setSearchOrder = Object.assign({}, this.searchFormOrder); //거래내역
      } else if (this.selectedTab == "tab5") {
        this.searchFormLog.setusrId = this.inoutUsrId
        this.searchFormLog.setusrSeq = this.inoutUsrSeq
        this.searchFormLog.cpnId = this.cpnId
        this.searchFormLog.cpnId = this.cpnId
        this.searchFormLog.fromDate = this.gridSearch.fromDate
        this.searchFormLog.toDate = this.gridSearch.toDate
        this.setSearchLog = Object.assign({}, this.searchFormLog); //로그내역
      } else if (this.selectedTab == "tab6") {
        this.searchFormProfit.setusrId = this.inoutUsrId
        this.searchFormProfit.setusrSeq = this.inoutUsrSeq
        this.searchFormProfit.cpnId = this.cpnId
        this.searchFormProfit.fromDate = this.gridSearch.fromDate
        this.searchFormProfit.toDate = this.gridSearch.toDate
        this.setSearchProfit = Object.assign({}, this.searchFormProfit); //개별손익
      }
    }
    ,
    resetData() {
      this.part3Id = null;
      this.part2Id = null;
      this.part1Id = null;
      if (this.authId == "PART03") {
        this.partnerList2 = null
      } else if (this.authId == "PART04") {
        this.partnerList2 = null
        this.partnerList3 = null
      }
      if (this.selectedTab == "tab1") {
        this.searchFormInfo.usrId = ""
        this.searchFormInfo.userInfoNm = ""
        this.setSearchUserInfo = Object.assign({}, this.searchFormInfo);
      } else if (this.selectedTab == "tab2") {
        this.searchFormLive.usrId = ""
        this.searchFormLive.userInfoNm = ""
        this.searchFormLive.tp2Flag = false
        this.searchFormLive.tp3Flag = false
        this.setSearchFrom = Object.assign({}, this.searchFormLive);

        //this.setSearchUserInfo = Object.assign({}, this.searchForm);
      } else if (this.selectedTab == "tab3") {
        this.searchFormInout.setusrId = ""
        this.searchFormInout.setusrSeq = ""
        this.searchFormInout.usrId = ""
        this.searchFormInout.userInoutNm = ""
        this.gridSearch.fromDate = Utils.getToday();
        this.gridSearch.toDate = Utils.getToday();
        this.setSearchUser = Object.assign({}, this.searchFormInout);
        this.setSearchWithdraw = Object.assign({}, this.searchFormInout);
      } else if (this.selectedTab == "tab4") {
        this.searchFormOrder.setusrId = ""
        this.searchFormOrder.setusrSeq = ""
        this.searchFormOrder.usrId = ""
        this.searchFormOrder.userInoutNm = ""
        this.searchFormOrder.trdDt = Utils.getToday();
        this.searchFormOrder.tp = "0"
        this.setSearchUser = Object.assign({}, this.searchFormOrder);
        this.setSearchOrder = Object.assign({}, this.searchFormOrder);
      } else if (this.selectedTab == "tab5") {
        this.searchFormLog.setusrId = ""
        this.searchFormLog.setusrSeq = ""
        this.searchFormLog.usrId = ""
        this.searchFormLog.userInoutNm = ""
        this.gridSearch.fromDate = Utils.getToday();
        this.gridSearch.toDate = Utils.getToday();
        this.setSearchUser = Object.assign({}, this.searchFormLog);
        this.setSearchLog = Object.assign({}, this.searchFormLog);
      } else if (this.selectedTab == "tab6") {
        this.searchFormProfit.setusrId = ""
        this.searchFormProfit.setusrSeq = ""
        this.searchFormProfit.usrId = ""
        this.searchFormProfit.userInoutNm = ""
        this.gridSearch.fromDate = Utils.getToday();
        this.gridSearch.toDate = Utils.getToday();
        this.setSearchUser = Object.assign({}, this.searchFormProfit);
        this.setSearchProfit = Object.assign({}, this.searchFormProfit);
      } else if (this.selectedTab == "tab7") {
        this.searchFormAllProfit.usrId = ""
        this.searchFormAllProfit.userProfitNm = ""
        this.gridSearch.fromDate = Utils.getToday();
        this.gridSearch.toDate = Utils.getToday();
        this.setSearchUser = Object.assign({}, this.searchFormAllProfit);
      }
      this.searchpartner(0)
    },
    async searchpartner(no) {
      if (this.usrId == null || this.authId == null) {
        return;
      }
      const response = await this.$http.post('/api/partner/list', { usrId: this.usrId, authId: this.authId, cpnId: this.cpnId });
      if (response && response.data) {
        if (no == 0) {
          if (this.authId == "PART02") {
            this.partnerList2 = response.data;
          } else if (this.authId == "PART03") {
            this.partnerList3 = response.data;
          } else if (this.authId == "PART04") {
            this.partnerList4 = response.data;
          } else if (this.authId == "PART01") {
            this.searchFormInfo.usrId = this.usrId;
            this.searchFormLive.usrId = this.usrId;
            this.searchFormInout.usrId = this.usrId;
            this.searchFormOrder.usrId = this.usrId;
            this.searchFormLog.usrId = this.usrId;
            this.searchFormProfit.usrId = this.usrId;
            this.searchFormAllProfit.usrId = this.usrId;
          }
        }
      }
    },
    checkAuth1(authId) {
      return ["PART04"].includes(authId)
    },
    checkAuth2(authId) {
      return ["PART04", "PART03"].includes(authId)
    },
    checkAuth3(authId) {
      return ["PART04", "PART03", "PART02"].includes(authId)
    },
    searchUserInfo1() {
      if (this.searchFormInfo.usrId == "" || this.searchFormInfo.usrId == null) {
        this.$alert.alert('파트너를 선택하세요.', '알림');
        return;
      }
      if (this.selectedTab == "tab1") {
        this.searchFormInfo.usrNm = this.searchFormInfo.userInfoNm
        this.searchFormInfo.cpnId = this.cpnId
        this.searchFormInfo.authId = this.authId
        //테스트 검색 삭제후 필요한 검색조건 추가
        this.setSearchUserInfo = Object.assign({}, this.searchFormInfo); //컴퍼넌트에서 계속 인식하도록 설정
      }
    },
    async searchAllUserInfo1() {
      const response = await this.$http.post('/api/partner/list', { usrId: this.usrId, authId: this.authId, cpnId: this.cpnId });
      if (response && response.data) {
        //   this.partnerList1 = response.data;
        if (this.selectedTab == "tab1") {
          this.searchFormInfo.usrId = this.usrId
          this.searchFormInfo.authId = this.authId
          this.searchFormInfo.cpnId = this.cpnId
          this.searchFormInfo.usrNm = this.searchFormInfo.userInfoNm
          //테스트 검색 삭제후 필요한 검색조건 추가

          this.setSearchUserInfo = Object.assign({}, this.searchFormInfo); //컴퍼넌트에서 계속 인식하도록 설정
        }
      }
    },
    searchUserInfo2() {
      if (this.searchFormInout.usrId == "" || this.searchFormInout.usrId == null) {
        this.$alert.alert('파트너를 선택하세요.', '알림');
        return;
      }

      if (this.selectedTab == "tab3") {
        this.searchFormInout.usrNm = this.searchFormInout.userInoutNm
        this.searchFormInout.cpnId = this.cpnId
        this.searchFormInout.authId = this.authId
        this.setSearchUser = Object.assign({}, this.searchFormInout); //컴퍼넌트에서 계속 인식하도록 설정 
      }

    },
    async searchAllUserInfo2() {
      const response = await this.$http.post('/api/partner/list', { usrId: this.usrId, authId: this.authId, cpnId: this.cpnId });
      if (response && response.data) {
        //   this.partnerList1 = response.data;
        if (this.selectedTab == "tab3") {
          this.searchFormInout.usrId = this.usrId
          this.searchFormInout.authId = this.authId
          this.searchFormInout.cpnId = this.cpnId
          this.searchFormInout.usrNm = this.searchFormInout.userInoutNm
          this.setSearchUser = Object.assign({}, this.searchFormInout); //컴퍼넌트에서 계속 인식하도록 설정
        }
      }
    },
    searchUserInfo3() {
      if (this.searchFormOrder.usrId == "" || this.searchFormOrder.usrId == null) {
        this.$alert.alert('파트너를 선택하세요.', '알림');
        return;
      }

      if (this.selectedTab == "tab4") {
        this.searchFormOrder.usrNm = this.searchFormOrder.userInoutNm
        this.searchFormOrder.cpnId = this.cpnId
        this.searchFormOrder.authId = this.authId
        this.setSearchUser = Object.assign({}, this.searchFormOrder); //컴퍼넌트에서 계속 인식하도록 설정 
      }

    },
    async searchAllUserInfo3() {
      const response = await this.$http.post('/api/partner/list', { usrId: this.usrId, authId: this.authId, cpnId: this.cpnId });
      if (response && response.data) {
        //   this.partnerList1 = response.data;
        if (this.selectedTab == "tab4") {
          this.searchFormOrder.usrId = this.usrId
          this.searchFormOrder.authId = this.authId
          this.searchFormOrder.cpnId = this.cpnId
          this.searchFormOrder.usrNm = this.searchFormOrder.userInoutNm
          this.setSearchUser = Object.assign({}, this.searchFormOrder); //컴퍼넌트에서 계속 인식하도록 설정
        }
      }
    },
    searchUserInfo4() {
      if (this.searchFormLog.usrId == "" || this.searchFormLog.usrId == null) {
        this.$alert.alert('파트너를 선택하세요.', '알림');
        return;
      }
      if (this.selectedTab == "tab5") {
        this.searchFormLog.usrNm = this.searchFormLog.userInoutNm
        this.searchFormLog.cpnId = this.cpnId
        this.searchFormLog.authId = this.authId
        this.setSearchUser = Object.assign({}, this.searchFormLog); //컴퍼넌트에서 계속 인식하도록 설정 
      }

    },
    async searchAllUserInfo4() {
      const response = await this.$http.post('/api/partner/list', { usrId: this.usrId, authId: this.authId, cpnId: this.cpnId });
      if (response && response.data) {
        //   this.partnerList1 = response.data;
        if (this.selectedTab == "tab5") {
          this.searchFormLog.usrId = this.usrId
          this.searchFormLog.authId = this.authId
          this.searchFormLog.cpnId = this.cpnId
          this.searchFormLog.usrNm = this.searchFormLog.userInoutNm
          this.setSearchUser = Object.assign({}, this.searchFormLog); //컴퍼넌트에서 계속 인식하도록 설정
        }
      }
    },
    searchUserInfo5() {
      if (this.searchFormProfit.usrId == "" || this.searchFormProfit.usrId == null) {
        this.$alert.alert('파트너를 선택하세요.', '알림');
        return;
      }
      if (this.selectedTab == "tab6") {
        this.searchFormProfit.usrNm = this.searchFormProfit.userInoutNm
        this.searchFormProfit.cpnId = this.cpnId
        this.searchFormProfit.authId = this.authId
        this.setSearchUser = Object.assign({}, this.searchFormProfit); //컴퍼넌트에서 계속 인식하도록 설정 
      }

    },
    async searchAllUserInfo5() {
      const response = await this.$http.post('/api/partner/list', { usrId: this.usrId, authId: this.authId, cpnId: this.cpnId });
      if (response && response.data) {
        //   this.partnerList1 = response.data;
        if (this.selectedTab == "tab6") {
          this.searchFormProfit.usrId = this.usrId
          this.searchFormProfit.authId = this.authId
          this.searchFormProfit.cpnId = this.cpnId
          this.searchFormProfit.usrNm = this.searchFormProfit.userInoutNm
          this.setSearchUser = Object.assign({}, this.searchFormProfit); //컴퍼넌트에서 계속 인식하도록 설정
        }
      }
    },
    searchUserInout() {
      if (this.searchFormInout.setusrId == "" || this.searchFormInout.setusrId == null) {
        this.$alert.alert('사용자를 선택하세요.', '알림');
        return;
      }
      if (this.selectedTab == "tab3") {
        this.searchFormInout.fromDate = this.gridSearch.fromDate
        this.searchFormInout.toDate = this.gridSearch.toDate
        this.searchFormInout.cpnId = this.cpnId
        this.setSearchWithdraw = Object.assign({}, this.searchFormInout); //컴퍼넌트에서 계속 인식하도록 설정
      }
    },
    searchUserOrder() {
      if (this.searchFormOrder.setusrId == "" || this.searchFormOrder.setusrId == null) {
        this.$alert.alert('사용자를 선택하세요.', '알림');
        return;
      }
      if (this.selectedTab == "tab4") {
        this.searchFormOrder.tp = this.gridSearch.ordType
        this.searchFormOrder.trdDt = this.gridSearch.trdDt
        this.searchFormOrder.cpnId = this.cpnId
        this.setSearchOrder = Object.assign({}, this.searchFormOrder); //컴퍼넌트에서 계속 인식하도록 설정
      }
    },
    searchUserLog() {
      if (this.searchFormLog.setusrId == "" || this.searchFormLog.setusrId == null) {
        this.$alert.alert('사용자를 선택하세요.', '알림');
        return;
      }
      if (this.selectedTab == "tab5") {
        this.searchFormLog.fromDate = this.gridSearch.fromDate
        this.searchFormLog.toDate = this.gridSearch.toDate
        this.searchFormLog.cpnId = this.cpnId
        this.setSearchLog = Object.assign({}, this.searchFormLog); //컴퍼넌트에서 계속 인식하도록 설정
      }
    },
    searchUserProfit() {
      if (this.searchFormProfit.setusrId == "" || this.searchFormProfit.setusrId == null) {
        this.$alert.alert('사용자를 선택하세요.', '알림');
        return;
      }
      if (this.selectedTab == "tab6") {
        this.searchFormProfit.fromDate = this.gridSearch.fromDate
        this.searchFormProfit.toDate = this.gridSearch.toDate
        this.searchFormProfit.cpnId = this.cpnId
        this.setSearchProfit = Object.assign({}, this.searchFormProfit); //컴퍼넌트에서 계속 인식하도록 설정
      }
    },
    searchUserAllProfit() {
      if (this.authId == "PART01") {
        this.searchFormAllProfit.setusrId = this.usrId
        this.searchFormAllProfit.usrId = this.usrId
        this.searchFormAllProfit.authId = this.authId
        this.searchFormAllProfit.cpnId = this.cpnId
      }
      if (this.searchFormAllProfit.setusrId == "" || this.searchFormAllProfit.setusrId == null) {
        this.$alert.alert('파트너를 선택하세요.', '알림');
        return;
      }
      if (this.selectedTab == "tab7") {
        this.searchFormAllProfit.fromDate = this.gridSearch.fromDate
        this.searchFormAllProfit.toDate = this.gridSearch.toDate
        this.searchFormAllProfit.cpnId = this.cpnId
        this.setSearchAllProfit = Object.assign({}, this.searchFormAllProfit); //컴퍼넌트에서 계속 인식하도록 설정
      }
    },
    searchAllUserAllProfit() {
      this.searchFormAllProfit1.usrId = window.$store.getters['Login/getLoginUserInfo'].usrId
      this.searchFormAllProfit1.authId = window.$store.getters['Login/getLoginUserInfo'].authId

      if (this.selectedTab == "tab7") {
        this.searchFormAllProfit1.fromDate = this.gridSearch.fromDate
        this.searchFormAllProfit1.toDate = this.gridSearch.toDate
        this.searchFormAllProfit1.cpnId = this.cpnId
        this.setSearchAllProfit = Object.assign({}, this.searchFormAllProfit1); //컴퍼넌트에서 계속 인식하도록 설정
      }
    },
    async selectPart1(part) {
      this.part1Id = part.usrId;
      const response = await this.$http.post('/api/partner/list', { usrId: part.usrId, authId: part.authId, cpnId: this.cpnId });
      if (response && response.data) {
        //   this.partnerList1 = response.data;
        if (this.selectedTab == "tab1") {
          this.searchFormInfo.usrId = part.usrId
          this.searchFormInfo.authId = part.authId
          this.searchFormInfo.cpnId = this.cpnId
          this.searchFormInfo.usrNm = this.searchFormInfo.userInfoNm
          //테스트 검색 삭제후 필요한 검색조건 추가

          this.setSearchUserInfo = Object.assign({}, this.searchFormInfo); //컴퍼넌트에서 계속 인식하도록 설정
        } else if (this.selectedTab == "tab2") {
          this.searchFormLive.usrId = part.usrId
          this.searchFormLive.authId = part.authId
          this.searchFormLive.cpnId = this.cpnId
          this.searchFormLive.usrNm = this.searchFormLive.userInfoNm

        } else if (this.selectedTab == "tab3") {
          this.searchFormInout.usrId = part.usrId
          this.searchFormInout.authId = part.authId
          this.searchFormInout.cpnId = this.cpnId
          this.searchFormInout.usrNm = this.searchFormInout.userInoutNm
          this.setSearchUser = Object.assign({}, this.searchFormInout); //컴퍼넌트에서 계속 인식하도록 설정
        } else if (this.selectedTab == "tab4") {
          this.searchFormOrder.usrId = part.usrId
          this.searchFormOrder.authId = part.authId
          this.searchFormOrder.cpnId = this.cpnId
          this.searchFormOrder.usrNm = this.searchFormOrder.userInoutNm
          this.setSearchUser = Object.assign({}, this.searchFormOrder); //컴퍼넌트에서 계속 인식하도록 설정
        } else if (this.selectedTab == "tab5") {
          this.searchFormLog.usrId = part.usrId
          this.searchFormLog.authId = part.authId
          this.searchFormLog.cpnId = this.cpnId
          this.searchFormLog.usrNm = this.searchFormLog.userInoutNm
          this.setSearchUser = Object.assign({}, this.searchFormLog); //컴퍼넌트에서 계속 인식하도록 설정
        } else if (this.selectedTab == "tab6") {
          this.searchFormProfit.usrId = part.usrId
          this.searchFormProfit.authId = part.authId
          this.searchFormProfit.cpnId = this.cpnId
          this.searchFormProfit.usrNm = this.searchFormProfit.userInoutNm
          this.setSearchUser = Object.assign({}, this.searchFormProfit); //컴퍼넌트에서 계속 인식하도록 설정
        } else if (this.selectedTab == "tab7") {
          this.searchFormAllProfit.usrId = part.usrId
          this.searchFormAllProfit.setusrId = part.usrId
          this.searchFormAllProfit.authId = part.authId
          this.searchFormAllProfit.cpnId = this.cpnId
          this.searchFormAllProfit.usrNm = this.searchFormAllProfit.userProfitNm
          this.searchFormAllProfit.fromDate = this.gridSearch.fromDate
          this.searchFormAllProfit.toDate = this.gridSearch.toDate
          //테스트 검색 삭제후 필요한 검색조건 추가

          //    this.setSearchAllProfit = Object.assign({}, this.searchFormAllProfit); //컴퍼넌트에서 계속 인식하도록 설정
        }
      }
    },
    async selectPart2(part) {
      this.partnerList2 = null
      this.part1Id = null;
      this.part2Id = part.usrId;
      const response = await this.$http.post('/api/partner/list', { usrId: part.usrId, authId: part.authId, cpnId: this.cpnId });
      if (response && response.data) {
        this.partnerList2 = response.data
        if (this.selectedTab == "tab1") {
          this.searchFormInfo.usrId = part.usrId
          this.searchFormInfo.authId = part.authId
          this.searchFormInfo.cpnId = this.cpnId
          this.searchFormInfo.usrNm = this.searchFormInfo.userInfoNm
          //테스트 검색 삭제후 필요한 검색조건 추가

          this.setSearchUserInfo = Object.assign({}, this.searchFormInfo); //컴퍼넌트에서 계속 인식하도록 설정
        } else if (this.selectedTab == "tab2") {
          this.searchFormLive.usrId = part.usrId
          this.searchFormLive.authId = part.authId
          this.searchFormLive.cpnId = this.cpnId
          this.searchFormLive.usrNm = this.searchFormLive.userInfoNm
        } else if (this.selectedTab == "tab3") {
          this.searchFormInout.usrId = part.usrId
          this.searchFormInout.authId = part.authId
          this.searchFormInout.cpnId = this.cpnId
          this.searchFormInout.usrNm = this.searchFormInout.userInoutNm
          this.setSearchUser = Object.assign({}, this.searchFormInout); //컴퍼넌트에서 계속 인식하도록 설정
        } else if (this.selectedTab == "tab4") {
          this.searchFormOrder.usrId = part.usrId
          this.searchFormOrder.authId = part.authId
          this.searchFormOrder.cpnId = this.cpnId
          this.searchFormOrder.usrNm = this.searchFormOrder.userInoutNm
          this.setSearchUser = Object.assign({}, this.searchFormOrder); //컴퍼넌트에서 계속 인식하도록 설정
        } else if (this.selectedTab == "tab5") {
          this.searchFormLog.usrId = part.usrId
          this.searchFormLog.authId = part.authId
          this.searchFormLog.cpnId = this.cpnId
          this.searchFormLog.usrNm = this.searchFormLog.userInoutNm
          this.setSearchUser = Object.assign({}, this.searchFormLog); //컴퍼넌트에서 계속 인식하도록 설정
        } else if (this.selectedTab == "tab6") {
          this.searchFormProfit.usrId = part.usrId
          this.searchFormProfit.authId = part.authId
          this.searchFormProfit.cpnId = this.cpnId
          this.searchFormProfit.usrNm = this.searchFormProfit.userInoutNm
          this.setSearchUser = Object.assign({}, this.searchFormProfit); //컴퍼넌트에서 계속 인식하도록 설정
        } else if (this.selectedTab == "tab7") {
          this.searchFormAllProfit.usrId = part.usrId
          this.searchFormAllProfit.setusrId = part.usrId
          this.searchFormAllProfit.authId = part.authId
          this.searchFormAllProfit.cpnId = this.cpnId
          this.searchFormAllProfit.usrNm = this.searchFormAllProfit.userProfitNm
          this.searchFormAllProfit.fromDate = this.gridSearch.fromDate
          this.searchFormAllProfit.toDate = this.gridSearch.toDate
          //테스트 검색 삭제후 필요한 검색조건 추가

          //   this.setSearchAllProfit = Object.assign({}, this.searchFormAllProfit); //컴퍼넌트에서 계속 인식하도록 설정
        }
      }
    },
    async selectPart3(part) {
      this.partnerList2 = null
      this.partnerList3 = null
      this.part1Id = null;
      this.part2Id = null;
      this.part3Id = part.usrId;
      const response = await this.$http.post('/api/partner/list', { usrId: part.usrId, authId: part.authId, cpnId: this.cpnId });
      if (response && response.data) {
        this.partnerList3 = response.data
        if (this.selectedTab == "tab1") {
          this.searchFormInfo.usrId = part.usrId
          this.searchFormInfo.authId = part.authId
          this.searchFormInfo.cpnId = this.cpnId
          this.searchFormInfo.usrNm = this.searchFormInfo.userInfoNm
          //테스트 검색 삭제후 필요한 검색조건 추가

          this.setSearchUserInfo = Object.assign({}, this.searchFormInfo); //컴퍼넌트에서 계속 인식하도록 설정
        } else if (this.selectedTab == "tab2") {
          this.searchFormLive.usrId = part.usrId
          this.searchFormLive.authId = part.authId
          this.searchFormLive.cpnId = this.cpnId
          this.searchFormLive.usrNm = this.searchFormLive.userInfoNm
        } else if (this.selectedTab == "tab3") {
          this.searchFormInout.usrId = part.usrId
          this.searchFormInout.authId = part.authId
          this.searchFormInout.cpnId = this.cpnId
          this.searchFormInout.usrNm = this.searchFormInout.userInoutNm
          this.setSearchUser = Object.assign({}, this.searchFormInout); //컴퍼넌트에서 계속 인식하도록 설정
        } else if (this.selectedTab == "tab4") {
          this.searchFormOrder.usrId = part.usrId
          this.searchFormOrder.authId = part.authId
          this.searchFormOrder.cpnId = this.cpnId
          this.searchFormOrder.usrNm = this.searchFormOrder.userInoutNm
          this.setSearchUser = Object.assign({}, this.searchFormOrder); //컴퍼넌트에서 계속 인식하도록 설정
        } else if (this.selectedTab == "tab5") {
          this.searchFormLog.usrId = part.usrId
          this.searchFormLog.authId = part.authId
          this.searchFormLog.cpnId = this.cpnId
          this.searchFormLog.usrNm = this.searchFormLog.userInoutNm
          this.setSearchUser = Object.assign({}, this.searchFormLog); //컴퍼넌트에서 계속 인식하도록 설정
        } else if (this.selectedTab == "tab6") {
          this.searchFormProfit.usrId = part.usrId
          this.searchFormProfit.authId = part.authId
          this.searchFormProfit.cpnId = this.cpnId
          this.searchFormProfit.usrNm = this.searchFormProfit.userInoutNm
          this.setSearchUser = Object.assign({}, this.searchFormProfit); //컴퍼넌트에서 계속 인식하도록 설정
        } else if (this.selectedTab == "tab7") {
          this.searchFormAllProfit.usrId = part.usrId
          this.searchFormAllProfit.setusrId = part.usrId
          this.searchFormAllProfit.authId = part.authId
          this.searchFormAllProfit.cpnId = this.cpnId
          this.searchFormAllProfit.usrNm = this.searchFormAllProfit.userProfitNm
          this.searchFormAllProfit.fromDate = this.gridSearch.fromDate
          this.searchFormAllProfit.toDate = this.gridSearch.toDate
          //테스트 검색 삭제후 필요한 검색조건 추가

          //  this.setSearchAllProfit = Object.assign({}, this.searchFormAllProfit); //컴퍼넌트에서 계속 인식하도록 설정
        }
      }
    },
    async loginChk() {
      await window.$http.post('/auth/login/refresh', { params: {} });
    },
    //종목 조회
    async selectSymbolList() {
      await window.$store.dispatch('Symbol/load')
      this.symbolList = window.$store.getters['Symbol/getSymbolList'];

      //소켓 시세
      if (this.priceReqType == "SCKTP01") {
        let symCdList = [];
        this.symbolList.forEach(item => {
          symCdList.push(item.name);
        })
        if (!window.$store.getters['SocketPrice/getIsConnectPrice']) {
          window.$store.watch(() => window.$store.getters['SocketPrice/getIsConnectPrice'], socketPrice => {
            window.$store.dispatch('SocketPrice/setSubscribeOnPriceList', [])
            this.symbolDropdown(this.symbolList[0]);
          })
        } else {
          window.$store.dispatch('SocketPrice/setSubscribeOnPriceList', symCdList)
          this.symbolDropdown(this.symbolList[0]);
        }
        //폴링 시세
      } else if (this.priceReqType == "SCKTP02" || this.priceReqType == "SCKTP03") {
        let symbolList = [];
        this.symbolList.forEach(item => {
          symbolList.push(item.name);
        })

        this.connectPrice(symbolList);
      }

      //this.setStockInfo(this.symbolList, null);
    },
    // 시세 socket 연결
    connectPrice(symbolList) {
      if (this.priceReqType == "SCKTP01") {
        window.$store.dispatch('SocketPrice/setConectPrice', { sessionStorage: this.$sessionStorage.get('xDkToken') });
      } else if (this.priceReqType == "SCKTP02" || this.priceReqType == "SCKTP03") {
        window.$store.dispatch('SocketPrice/setPollingPrice', { symbolList: symbolList });
      }
    },
    updateRtPosSymbol(dataList) {
      this.rtPosSymbolList = [];
      dataList.forEach(data => {
        if (data.ordType == "POS") {
          this.rtPosSymbolList.push(data);
        }
      })

      this.updateSubscribeOnPrice();
    },
    updateSubscribeOnPrice() {
      //소켓 구독 종목 업데이트
      this.symbolCdList = [];

      //관심종목이 없고 잔고 목록만 있을경우
      this.rtPosSymbolList.forEach(data => {
        this.symbolCdList.push(data.symCd);
      });

      //선택 종목
      const selSymbol = window.$store.getters['SocketPrice/getSelectSymbol'];

      if (selSymbol != undefined && selSymbol.name) {
        let chkYn = "N"
        this.symbolCdList.forEach(item => {
          if (selSymbol.name == item) {
            chkYn = "Y";
            return;
          }
        })
        if (chkYn == "N") {
          this.symbolCdList.push(selSymbol.name);
        }
      }


      //console.log("소켓 구독 수정", this.symbolCdList)

      this.subscribeOnPriceModify(this.symbolCdList);
    },
    subscribeOnPriceModify(symbolList) {
      if (this.priceReqType == "SCKTP01") {
        window.$store.dispatch('SocketPrice/setUpdateSubscribeOnPriceList', symbolList);
      } else if (this.priceReqType == "SCKTP02" || this.priceReqType == "SCKTP03") {
        window.$store.dispatch('SocketPrice/setPollingPrice', { symbolList: symbolList });
      }
    },
    async setStockInfo(newSymbol, oldSymbol) {

      const rStock = await window.$http.get('chart/datafeed/stockdata', { params: {} })
      if (rStock != null) {
        // let ovc = this.ovcData.ovcData;

        // //시세 db값으로 초기 설정
        // rStock.data.forEach(item => {
        //   ovc.set(item.symbol, item);
        // });

        // let cnt = this.ovcData.cnt; //map으로 전달하면 PanelComp2 watch에서 감지를 못하여 cnt값을 계속 증가시켜 감지
        // const param = {cnt:cnt+1, ovcData:ovc};         
        // this.ovcData = param;
      }
    },
    // oms socket 연결
    async connectOms() {
      await window.$store.dispatch('SocketOms/setConectOms', { sessionStorage: this.$sessionStorage.get('xDkToken'), subscriptionUID: sessionStorage.getItem('subscriptionUID') })
    },
    async connectMsg() {
      await window.$store.dispatch('SocketMsg/setConectMsg', { sessionStorage: this.$sessionStorage.get('xDkToken'), subscriptionUID: sessionStorage.getItem('subscriptionUID') })
    },
    //종목셀렉트 선택
    symbolDropdown(value) {
      let oldValue = Object.assign({}, this.symbol);
      this.symbol = value;
      //const oldSymbol = value;

      window.$store.dispatch('SocketPrice/setSelectSymbol', value)

      this.setStockInfo(value, oldValue);

      //시세 전체로 받을경우 활성화
      let allList = [];
      this.symbolList.forEach(symData => {
        allList.push(symData.name);
      })

      this.subscribeOnPriceModify(allList);
    },
    selectAccountList() {
      //console.log("accountList", this.accountList)
      this.$http
        .get('/api/account/list')
        .then((result) => {
          if (result && result.data !== null) {

            this.accountList = result.data;

            if (result.data.length > 0) {
              this.account = result.data[0];
            }


            //console.log("acccount >>> ", this.account)

            //console.log("accountList", this.accountList)

            // if ( this.$sessionStorage.get('acc_pass_store') ) {
            //   const accPassObj = JSON.parse(this.$sessionStorage.get('acc_pass_store'))

            //   this.accountList.forEach(item => {
            //     item.accPass = accPassObj[item.accNo]
            //   })           
            // }
          }
        })
    },
    setTp1(val) {
      this.searchFormLive.tp1 = val.cd;
      console.log("setTp1 >>> ", val)
    },
    setUsrType1(val) {
      this.searchFormInfo.usrType = val.cd;
      console.log("setUsrType >>> ", val)
    },
    setUsrType2(val) {
      this.searchFormInout.usrType = val.cd;
      console.log("setUsrType >>> ", val)
    },
    setUsrType3(val) {
      this.searchFormOrder.usrType = val.cd;
      console.log("setUsrType >>> ", val)
    },
    setUsrType4(val) {
      this.searchFormLog.usrType = val.cd;
      console.log("setUsrType >>> ", val)
    },
    setUsrType5(val) {
      this.searchFormProfit.usrType = val.cd;
      console.log("setUsrType >>> ", val)
    },
    setUsrType6(val) {
      this.searchFormAllProfit.usrType = val.cd;
      console.log("setUsrType >>> ", val)
    },
    setInoutType(val) {
      this.searchFormInout.inoutType = val.cd;
      console.log("setInoutType >>> ", val)
    },
    setOrdType() {
      if (this.isChecked == true) {
        this.searchFormOrder.ordType = "1"
      } else {
        this.searchFormOrder.ordType = "0"
      }
      //this.searchForm.ordType = this.isChecked;
      console.log("setOrdType >>> ", this.searchFormOrder.ordType)
    },
    selPartner(val) {
      console.log("selPartner >>> ", val)
    },
    searchTab2() {
      if (this.searchFormLive.usrId == "" || this.searchFormLive.usrId == null) {
        this.$alert.alert('파트너를 선택하세요.', '알림');
        return;
      }
      if (this.searchFormLive.tp2Flag) {
        this.searchFormLive.tp2 = '1';
      } else {
        this.searchFormLive.tp2 = '0';
      }

      if (this.searchFormLive.tp3Flag) {
        this.searchFormLive.tp3 = '1';
      } else {
        this.searchFormLive.tp3 = '0';
      }
      this.searchFormLive.tp4 = this.selectedOption
      if (this.authId == "PART01") {
        this.searchFormLive.authId = this.authId;
        this.searchFormLive.cpnId = this.cpnId;
      }
      this.setSearchFrom = Object.assign({}, this.searchFormLive); //컴퍼넌트에서 계속 인식하도록 설정
    },

    searchAllTab2() {
      this.searchFormLive1.usrId = this.usrId
      this.searchFormLive1.authId = this.authId
      this.searchFormLive1.cpnId = this.cpnId
      this.searchFormLive1.usrNm = this.searchFormLive.userInfoNm
      if (this.searchFormLive.tp2Flag) {
        this.searchFormLive1.tp2 = '1';
      } else {
        this.searchFormLive1.tp2 = '0';
      }

      if (this.searchFormLive.tp3Flag) {
        this.searchFormLive1.tp3 = '1';
      } else {
        this.searchFormLive1.tp3 = '0';
      }
      this.searchFormLive1.tp4 = this.selectedOption
      if (this.authId == "PART01") {
        this.searchFormLive1.authId = this.authId;
        this.searchFormLive1.cpnId = this.cpnId;
      }
      this.setSearchFrom = Object.assign({}, this.searchFormLive1); //컴퍼넌트에서 계속 인식하도록 설정
    },
    //실시간손익 등 정보 업데이트
    updateTotalValPl(newVal) {
      //console.log("memberValPlList ", newVal)
      this.dailyPlMn.totalValPl = newVal.totalValPl;
      this.memberValPlList = Object.assign({}, { list: newVal.memberValPlList })

    },
    updateLivePosition(newVal) {
      //console.log("memberValPlList ", newVal)
      this.dailyPlMn.fxQty = newVal.fxS + '/' + newVal.fxB;
      this.dailyPlMn.futQty = newVal.futS + '/' + newVal.futB;
    },
    updateBalAmtCms(newVal) {
      this.dailyPlMn.totBalMn = newVal.totBalMn;
      this.dailyPlMn.totCms = newVal.totCms == 0 ? 0 : Number(newVal.totCms) * -1;
      this.dailyPlMn.totPlMn = newVal.totPlMn;
    },
    thousand(value, digits) {
      return value.toLocaleString('en-US', { minimumFractionDigits: digits });
    },
    disconnect() {
      console.log("partner disconnect....");
      //oms
      if (window.$store.getters['SocketOms/getIsConnectOms']) {
        //
        window.$store.dispatch('SocketOms/setDisconnect');
      }

      //소켓 시세
      if (window.$store.getters['SocketPrice/getIsConnectPrice']) {
        //setDisconnect
        window.$store.dispatch('SocketPrice/setDisconnect');
      }

      //폴링 시세
      if (window.$store.getters['SocketPrice/getIsPolingPrice']) {
        //clear polling
        window.$store.dispatch('SocketPrice/setClearPolling');
      }

      //메시지
      if (window.$store.getters['SocketMsg/getIsConnectMsg']) {
        //clear polling
        window.$store.dispatch('SocketMsg/setDisconnect');
      }
    },
    logout() {
      this.$alert.confirm("로그아웃 하시겠습니까?")
        .then((response) => {
          if (!response) {
            return;
          } else {
            window.$store.dispatch('Login/logout');

            this.disconnect();

            this.$router.push('/login');
          }
        })
    },
    goToFut() {
      if (this.$router.app._route.fullPath !== '/partner') {
        this.$router.push('/partner');
      }
    },
    goToCoin() {
      if (this.$router.app._route.fullPath !== '/coinpartner') {
        this.$router.push('/coinpartner');
      }
    },
  },
  destroyed() {
    this.disconnect();
  },
};
</script>

<style scoped>
.logout {
  display: flex;
  align-items: center;
  gap: 0 5px;
  padding: 0 10px 0 10px;
  cursor: pointer;
}
</style>