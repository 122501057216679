<template>
  <div class="base-dropdown no-border" :id="id" ref="dropdown">
    <div class="dropdown" @click="handleDropdown()" :style="{height:cdHeight + 'px'}">
      <div class="selected">{{ selectedName }}</div>
      <img src="@/assets/icons/dropdown-triangle.svg" alt="" class="dropdown-triangle" />
    </div>
    <!-- <ul class="dropdown-list" v-if="isDropdownVisible">
      <li
        v-for="(option, index) in options"
        :key="index"
        @click="
          selectedOption = option.name;
          isDropdownVisible = false;
          $emit(id, option);
        "
      >
      {{ option.name }}
      </li>
    </ul> -->
    <transition name="fade">
      <DropDownList v-if="isDropdownVisible">
        <div
          class="dropdown-list-item"
          v-for="code in options"
          :key="code.cd"
          @click="onSelect(code)">
          <div class="col">
            <h3>{{ code.name }}</h3>
          </div>
        </div>
      </DropDownList>
    </transition>
  </div>
</template>

<script>
import DropDownList from '@/components/DropDownList.vue'

export default {
  name: 'CoinDropDown',
  components: {
    DropDownList,
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    id: String,
    default: String,
    width: [String, Number],
    height: [String, Number],
    full: Boolean,
    popup: Boolean,
    cdHeight: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    }
  },
  computed: {
    // selectedName() {
    //   let rtn = '';
    //   console.log("selectedName 1111", this.value, this.options)
    //   if ( this.value != undefined && this.value) {
    //     for (let i in this.options) {
    //       console.log("selectedName ", this.value, this.options[i].cd)
    //       if ( this.value == this.options[i].cd || this.value == this.options[i].name ) {
    //         rtn = this.options[i].name
    //         break
    //       }  
    //     }
    //     return rtn
    //   }else{
    //     console.log("this.selectedOption ", this.selectedOption)
    //     return this.selectedOption;
    //   }
    // }
    selectedName() {
      let rtn = '';
      console.log("thisvalue? ", this.value)
      if (this.value === '') {
        if (this.options.length > 0 ) {
          rtn = this.options[0].name;
        }
      } else {
        console.log("else ", this.value)
        for (let i in this.options) {
          if ( this.value == this.options[i].cd ) {
            rtn = this.options[i].name;
            break;
          }  
        }
      }
      console.log("rtn >>> ", rtn)

      return rtn;
    },
  },
  watch: {
    default: {
      handler(newOption) {
        console.log("디폴트 콤보...", newOption)
          this.selectedOption = newOption;
      },
      deep:true
    },
    options: {
      handler(newOptions){
        console.log("options...", newOptions)
      },
      deep: true,
    },

  },
  data() {
    return {
      selectedOption: '',
      isDropdownVisible: false,
    };
  },


  mounted() {
    this.selectedOption = this.default ? this.default : this.options[0];
    window.addEventListener('click', this.closeDropdown);
  },
  beforeDestroy() {
    window.removeEventListener('click', this.closeDropdown);
  },

  methods: {
    handleDropdown() {
      this.isDropdownVisible = !this.isDropdownVisible;
    },
    closeDropdown(element) {
      if (!this.$refs.dropdown.contains(element.target)) {
        this.isDropdownVisible = false;
      }
    },
    onSelect(code) {
      // @click="
      // selectedOption = option.name;
      // isDropdownVisible = false;
      // $emit(id, option);
      //this.selectedOption()
      if (this.value != code.cd){
        this.selectedOption = code.name;
        this.$emit(this.id, code);
      }
      this.isDropdownVisible = false;
    },
  },
};
</script>
  