<template>
  <div>
    <modal name="modalEditOrder" classes="main-modal" :adaptive="true" :width="width" :height="height"
    @before-open="beforeOpen"
    @opened="opened">
      <div class="modal-header">
        <h2 class="modal-header-title">주문정정</h2>
        <button @click="closeModify()"><img src="@/assets/icons/icon-close-modal.svg" alt="" /></button>
      </div>

      <div class="modal-body">
        <div class="modal-edit-order">
          <div class="order-input-group">
            <div class="order-input-container">
              <label for="">가격</label>
              <input type="text" :value="stdOrdPrc" placeholder="가격" @input="checkEditOrdPrc($event)" />
              <div class="unit">USDT</div>
            </div>

            <div class="order-input-container">
              <label for="">수량</label>
              <input type="text" :value="stdOrdQty" placeholder="수량" @input="checkEditOrdQty($event)" />
              <div class="unit">{{ stdOrdType }}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <!-- <button class="btn-primary darkgray" @click="closeModal()">취소</button> -->
        <button class="btn-primary blue" @click="modifyOrder()">확인</button>
      </div>
    </modal>
  </div>
</template>

<script>
import Utils from '@/modules/utils';

export default {
  name: 'CoinModalEditOrder',

  data() {
    return {
      width: '',
      height: '',
      modifyData: null,
      stdOrdPrc: '',
      stdOrdQty: '',
      stdOrdType: 'BTC',
      coinSymbolInfo: null,
    };
  },

  created() {
    if (window.innerWidth > 1200) {
      this.width = 500;
      this.height = 240;
    } else {
      this.width = '100%';
      this.height = '100%';
    }
  },

  mounted() {},

  methods: {
    opened(){
      
    },
    beforeOpen(e){
      console.log("beforeOpen e.prams", e.params)

      this.modifyData = e.params;
      // 초기화
      this.stdOrdPrc = 0;
      this.stdOrdQty = 0;

      //이전 가격 수량 설정
      this.stdOrdPrc = e.params.execPrc;
      this.stdOrdQty = e.params.balQty;

      const coinSymbolInfoList = window.$store.getters['Symbol/getCoinSymbolInfoList']

      for (let item of coinSymbolInfoList) {
        if (item.symNo == this.modifyData.symNo) {
          this.coinSymbolInfo = item;
          this.stdOrdType = item.artcCd;
          break;
        }
      }

      let message = "코인 체결리스트 미체결 정정 클릭 "+ e.params.symCd;
      this.sendCoinOmsLogMessage(message);

      
    },
    closeModal() {
      this.$modal.hide('modalEditOrder');
    },
    closeModify(){
      let message = "코인 체결리스트 미체결 정정 닫기 클릭 "+ this.modifyData.symCd;
      this.sendCoinOmsLogMessage(message);
      this.closeModal();
    },
    modifyOrder(){
      if (this.stdOrdTpCd == "1" && (!this.stdOrdPrc || this.stdOrdPrc <= 0)){
        window.$alert.alert('가격을 입력 해주세요.', '알림');
        return;
      }
      
      if (!this.stdOrdQty || this.stdOrdQty <= 0){
        window.$alert.alert('수량을 입력 해주세요.', '알림');
        return;
      }

      let ordQty = 0;
      let minOrdQty = this.coinSymbolInfo.minTrdAmt; //db에서 읽어와야함

      //수량에 USDT로 입력할 경우 BTC 수량으로 계산
      if (this.stdOrdType == "USDT"){
        ordQty = Utils.coinUsdtToBtc(this.stdOrdQty, this.coinNowPrice.curPr, minOrdQty);
      }else{
        ordQty = this.stdOrdQty;
      }

      //BTC 최소 수량 가능한지 체크
      if (ordQty < minOrdQty){
        console.log("ordQty, minOrdQty ", ordQty, minOrdQty)
        window.$alert.alert('주문가능 최소 수량을 확인 해주세요.', '알림');
        return;
      }

      //포지션 지정가 청산일경우 체크
      if (this.modifyData.posSeq != undefined && this.modifyData.posSeq > 0){
        let posOrdSlList = window.$store.getters['SocketCoinOms/getCoinPosOrdStList'];
        console.log("posOrdSlList ", posOrdSlList)
        let modOrdQty = Number(ordQty);

        //포지션 주문 수량 구하기
        let balQty = 0;
        for (let item of posOrdSlList){
          if (item.ordType == "POS" && item.posSeq == this.modifyData.posSeq){
            balQty = item.balQty;
          }
        }

        //미체결 수량 포지션 최대 수량 체크
        for (let item of posOrdSlList){
          if (item.ordType == "ORD" && item.posSeq != undefined && item.ordQty != undefined && item.ordId > 0 && this.modifyData.ordId != item.ordId && this.modifyData.posSeq == item.posSeq){
            //modOrdQty += modOrdQty + Number(item.ordQty);
            modOrdQty = Utils.decimalAdd(modOrdQty, item.ordQty);
          }
        }

        console.log("modOrdQty ", Number(modOrdQty), Number(balQty))
        if (Number(modOrdQty) > Number(balQty)){
          window.$alert.alert('포지션 수량을 초과 할 수 없습니다.<br/>(미체결 청산 수량 포함)', '알림');
          return;
        }
      }


      //가격 수량이 같으면 업데이트 안함
      if (this.stdOrdPrc != this.modifyData.execPrc || this.stdOrdQty != this.modifyData.balQty){
        this.modifyData.ordPrc = this.stdOrdPrc;
        this.modifyData.ordQty = this.stdOrdQty;
        this.$emit("modifyOrd", this.modifyData);
      }

      let message = "코인 체결리스트 미체결 정정 확인 클릭 "+ this.modifyData.symCd;
      this.sendCoinOmsLogMessage(message);

      this.closeModal();
    },
    sendCoinOmsLogMessage(message){ //코인 사용자 로그 저장
      window.$store.dispatch('SocketCoinOms/setLogMsg', {message:message});
    },
    //가격 입력시 체크
    checkEditOrdPrc(event){
      this.stdOrdPrc = Utils.inputPointCoinPrc(event, "USDT", this.modifyData.execPrc, this.coinSymbolInfo.costDotCnt);
    },
    //수량 입렵시 체크
    checkEditOrdQty(event){
      this.stdOrdQty = Utils.inputPointCoinQty(event, this.stdOrdType, this.coinSymbolInfo.costDotCnt, null, null, null);
    },
  },
};
</script>
<style lang="scss" scoped></style>
