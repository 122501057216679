<template>
  <div class="signup1" :style="{'background-image': 'url(' + imgLogSrc + ')'}">
    <div class="signup-form">
      <div class="signup-form-header">
        <!-- <img class="company-logo" src="@/assets/images/toss-logo.png" alt="" /> -->
        <h1 class="signup-title">회원가입</h1>
        <!-- <button class="close-btn"><img src="@/assets/icons/close-black.svg" alt="" /></button> -->
      </div>
      <div class="signup-form-body">
        <validation-observer ref="validator">
        <section>
          <h2 class="section-title">기본정보</h2>
          <div class="section-form">
            <div class="input-row">
              <label for="" class="input-label">사용구분</label>
              <BaseDropdown id="usrRol" :options="usrRolList" :default="usrRolList[0].name" @usrRol="setUsrRol" />
            </div>
            <validation-provider :rules="{ required:true, limit: 10, }" name="이름" v-slot="{errors}">
            <div class="input-row">
              <label for="usrNm" class="input-label">이름</label>
              <input type="text" id="usrNm" class="input" :class="[errors[0] ? 'error': '']" v-model="form.usrNm" />
            </div>
            <div class="input-row-error">
              <label></label>
              <div class="error" :class="[errors[0] ? 'errPadding': '']">{{errors[0]}}</div>
            </div>
            </validation-provider>
            <validation-provider :rules="{ usrId:true, required:true, 'limit-range': [4,16] }" name="아이디" v-slot="{errors}">
            <div class="input-row">
              <label for="usrId" class="input-label">아이디</label>
              <div class="input-form">
                <input type="text" id="usrId" class="input" :class="[errors[0] ? 'error': '']" v-model="form.usrId" @input="form.checkDupId = false" maxlength="16"/>
                <button class="btn-search" @click="chkId">중복확인</button>
              </div>
              <!-- <input type="text" id="usrId" class="input" :class="[errors[0] ? 'error': '']" v-model="form.usrId" /> -->
            </div>
            <div class="input-row-error">
              <label></label>
              <div class="error" :class="[errors[0] ? 'errPadding': '']">{{errors[0]}}</div>
            </div>
            </validation-provider>
            <validation-provider :rules="{ required:true, password:true, limit: 16, }" name="비밀번호" v-slot="{errors}">
            <div class="input-row">
              <label for="passwd" class="input-label">비밀번호</label>
              <input type="password" id="passwd" class="input" :class="[errors[0] ? 'error': '']" v-model="form.passwd" maxlength="16" />
            </div>
            <div class="input-row-error">
              <label></label>
              <div class="error" :class="[errors[0] ? 'errPadding': '']">{{errors[0]}}</div>
            </div>
            </validation-provider>
            <validation-provider :rules="{ required:true, password:true, confirmed:'비밀번호', limit: 16, }" name="비밀번호확인" v-slot="{errors}">
            <div class="input-row">
              <label for="passwd2" class="input-label">비밀번호확인</label>
              <input type="password" id="passwd2" class="input" :class="[errors[0] ? 'error': '']" v-model="form.passwd2" maxlength="16" />
            </div>
            <div class="input-row-error">
              <label></label>
              <div class="error" :class="[errors[0] ? 'errPadding': '']">{{errors[0]}}</div>
            </div>
            </validation-provider>
            <div class="input-row">
              <label for="" class="input-label">휴대번호</label>
              <div class="mobile-form">
                <BaseDropdown id="moNum1" :options="moNumList" default="010" @moNum1="setMoNum1" />
                  <validation-provider :rules="{ required:true, limit: 4, numeric:true }" name="휴대번호" v-slot="{errors}">
                  <span style="display:none;">{{errMsg1 = errors[0]}}</span>
                  <input type="text" id="moNum2" class="input" :class="[errors[0] ? 'error': '' ]" v-model="form.moNum2" maxlength="4" />                  
                  </validation-provider>
                  <validation-provider :rules="{ required:true, limit: 4, numeric:true}" name="휴대번호"  v-slot="{errors}">
                  <input type="text" id="moNum3" class="input" :class="[errors[0] ? 'error': '']" v-model="form.moNum3" maxlength="4" />
                  <span style="display:none;">{{errMsg2 = errors[0]}}</span>
                  </validation-provider>
              </div>
            </div>            
            <div class="input-row-error">
              <label></label>
              <div class="error" :class="[errMsg1 || errMsg2  ? 'errPadding': '']">
                <span v-if="errMsg1">{{errMsg1}}</span>
                <span v-else-if="errMsg2">{{errMsg2}}</span>
              </div>
            </div>
            <validation-provider :rules="{ required: false, email: false }" name="이메일"  v-slot="{errors}">
            <div class="input-row">
              <label for="mail" class="input-label">이메일</label>
              <input type="text" id="mail" class="input" v-model="form.mail" />
            </div>
            <div class="input-row-error">
              <label></label>
              <div class="error" :class="[errors[0]  ? 'errPadding': '']">{{errors[0] }}</div>
            </div>
            </validation-provider>
            <validation-provider :rules="{ required: false, limit: 16, }" name="추천인"  v-slot="{errors}">
            <div class="input-row">
              <label for="rcmdId" class="input-label">추천인</label>
              <input type="text" id="rcmdId" class="input" v-model="form.rcmdId" />
            </div>
            <div class="input-row-error">
              <label></label>
              <div class="error" :class="[errors[0]  ? 'errPadding': '']">{{errors[0] }}</div>
            </div>
            </validation-provider>
          </div>
        </section>
        <section>
          <h2 class="section-title">계좌정보</h2>
          <div class="section-form">
            <div class="input-row">
              <label for="" class="input-label">은행명</label>
              <!-- <BaseDropdown id="wdBnkNm" :options="bankList" default="카카오뱅크" /> -->
              <CodeDropDown width="82" grpCd="BNK" v-model="form.wdBnkNm" height="200" popup @input="bankSelect" />
            </div>
            <validation-provider :rules="{ required: form.wdBnkNm != 0 ? true : false, limit: 10 }" name="예금주"  v-slot="{errors}">
            <div class="input-row">
              <label for="wdBnkNm" class="input-label">예금주</label>
              <input type="text" id="wdBnkNm" class="input" v-model="form.wdBnkHd" />
            </div>
            <div class="input-row-error">
              <label></label>
              <div class="error" :class="[errors[0]  ? 'errPadding': '']">{{errors[0] }}</div>
            </div>
            </validation-provider>
            <validation-provider :rules="{ required: form.wdBnkNm != 0 ? true : false, limit: 20, account:false }" name="계좌번호"  v-slot="{errors}">
            <div class="input-row">
              <label for="wdBnkAccNo" class="input-label">계좌번호</label>

              <input type="text" id="wdBnkAccNo" class="input" v-model="form.wdBnkAccNo" />
            </div>
            <div class="input-row-error">
              <label></label>
              <div class="error" :class="[errors[0]  ? 'errPadding': '']">{{errors[0] }}</div>
            </div>
            </validation-provider>
          </div>
        </section>
        </validation-observer>
        <div class="siginBottom">
          <button class="btn-primary darkgray"  @click="goHome">취소</button>
          <button class="btn-primary blue"  @click="signup">회원가입</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseDropdown from '@/components/BaseDropdown.vue'
import CodeDropDown from '@/components/CodeDropDown.vue'

export default {
  name: 'WhiteLabelSignup1',
  components: { 
    BaseDropdown,
    CodeDropDown
  },
  data() {
    return {
      imgLogSrc: null,
      //usrRolList: [{cd:"0", name:'테스터'}, {cd:"1", name:'일반'}],
      usrRolList: [{cd:"1", name:'일반'}],
      moNumList: [{cd:"010", name:'010'}],
      bankList: [{cd:"010", name:'010'}],
      form:{
        cpnId:0,
        usrRol:'1',
        usrNm:'',
        usrId:'',
        passwd:'',
        passwd2:'',
        moNum1:'010',
        moNum2:'',
        moNum3:'',
        mail:'',
        rcmdId:'',
        wdBnkHd: '',
        wdBnkNm: '',
        wdBnkAccNo:'',
        checkDupId:false,
      },
      errMsg1:'',
      errMsg2:'',
    }
  },
  created(){

  },
  mounted() {
    this.domainCheck();
  },
  methods: {
    domainCheck(){
      const host = window.location.host;

      const formData = {hostUrl: host};

      this.$lhttp.post('/auth/domainChk', formData).then((response) => {
        if (response.code == 200){
          if (response.data != undefined && response.data.cpnId > 0){
            console.log(this.form.cpnId)
            this.form.cpnId = response.data.cpnId;
          }else{
            this.$alert.alert('접속하신 도메인정보의 회사정보가 없습니다.\n관리자에게 문의하세요.', '알림');
            return;
          }
        }else{
          this.$alert.alert('접속하신 도메인정보의 회사정보가 없습니다.\n관리자에게 문의하세요.', '알림');
          return;
        }
      });

      if (host === 'mng.n-globalinv.com' || host === 'n-globalinv.com' || host === 'www.n-globalinv.com' || host === 'ng.obbtrade.com') {
        this.imgLogSrc = require('@/assets/images/login-bg.png');
      } else if (host === 'beinasset.com' || host === 'www.beinasset.com' || host === 'bein.obbtrade.com') {
        this.imgLogSrc = require('@/assets/images/bein-login-bg.png');
      } else if (host === 'sp-globalinv.com' || host === 'www.sp-globalinv.com' || host === 'sp.obbtrade.com') {
        this.imgLogSrc = require('@/assets/images/sp-login-bg.png');
      }else{
        this.imgLogSrc = require('@/assets/images/login-bg.png');
      }
    },
    chkId(){
      if (this.form.usrId == undefined || this.form.usrId == null || this.form.usrId.trim() == ""){
        this.$alert.alert('아이디를 입력하세요.', '알림');
        return;
      }

      this.$lhttp
      .post('/auth/signup/signIdChk',
        this.form)
      .then((response) => {
        if (response.data.registUser == true){
          this.form.checkDupId = false;
          this.$alert.alert('이미 가입된 아이디입니다.', '알림');
        }else{
          this.form.checkDupId = true;
          this.$alert.alert('사용 가능한 아이디입니다.', '알림');
        }
      });
    },
    async signup () {
      const { validator } = this.$refs;

      await validator.validate().then((result) => {
        if (result) {
          if (this.form.checkDupId == false){
            this.$alert.alert('아이디 중복 확인을 해주세요.', '알림');
            return;
          }
          this.$alert.confirm('입력하신 정보로 회원가입 하시겠습니까?')
            .then((response) => {
              if (response) {
                this.$lhttp
                  .post('/auth/signup/signMember',
                    this.form)
                  .then((response) => {
                    //response.data.registUser 중복여부
                    if (response.data.registUser == true){
                      this.$alert.alert('이미 가입된 아이디입니다.', '알림');
                    }else{
                      this.$alert.alert('회원 가입이 완료 되었습니다.', '알림')
                      .then(()=>{
                        this.goHome();
                      })
                    }

                  })    
              }
            })
        }
      })
    },
    setUsrRol(val){
      this.form.usrRol = val.cd;
    },
    bankSelect(val){
      this.form.wdBnkNm = val;
    },
    setMoNum1(val){
      this.form.moNum1 = val;
    },
    goHome(){
      this.$router.push('/');
    },
  },
};
</script>
<style lang="scss" scoped>
.btn-primary {
  width: 45%;
  height: 40px;
  flex: 1;
  margin-top: 40px;
}
.mobile-form {
  display: grid;
	grid-template-columns: 1fr 1fr 1fr;
  gap: 0 5px;
}
.input-form {
  display: grid;
	grid-template-columns: 2fr 1fr;
  gap: 0 5px;
}
.base-dropdown {
  .dropdown {
    width: 100%;
    flex: 1;
  }
  .dropdown-list {
    width: 100%;
    flex: 1;
  }
}

.input-row-error {
  display: grid;
  grid-template-columns: 80px 200px;
  align-items: center;
  gap: 0 30px;
}

.error {
  color: var(--table-red);
  font-size: 12px;
}

.errPadding{
  padding-bottom:5px;
  width: 124%;
}

.siginBottom{
  display: flex;
  gap: 0 10px;
}
</style>
