<template>
  <header>
    <img :src="imageSrc" alt="" class="logo" />
    <nav v-if="getLoginUserInfo != undefined && getLoginUserInfo.authId">
      <button class="nav-btn" @click="showModal('modalDepositWithdraw')">
        <img src="@/assets/icons/account.svg" alt="" />
        입출금신청
      </button>
      <button class="nav-btn" @click="showModal('modalDepositWithdrawTable')">
        <img src="@/assets/icons/monitor.svg" alt="" />
        입출금내역
      </button>
      <button class="nav-btn" @click="showModal('modalTransactionTable')">
        <img src="@/assets/icons/transaction.svg" alt="" />
        거래내역
      </button>
      <button class="nav-btn" @click="showModal('modalProfitLossTable')">
        <img src="@/assets/icons/profits.svg" alt="" />
        매매손익
      </button>
      <button class="nav-btn" @click="showModal('modalSettings')">
        <img src="@/assets/icons/settings.svg" alt="" />
        환경설정
      </button>
      <!-- <router-link to=/partner>
        <button class="nav-btn">
        <img src="@/assets/icons/partner.svg" alt="" />
        파트너
      </button>
      </router-link> -->
    </nav>
    <div class="profit-bar-container">
      <ul class="profit-bar" v-if="getLoginUserInfo == undefined || getLoginUserInfo.authId == undefined">
        <li>
          <div class="label" style="width:100%">국내/해외 선물 거래를 이용 하실려면 로그인 해주세요.</div>
        </li>
      </ul>
      <ul class="profit-bar" v-if="getLoginUserInfo != undefined && getLoginUserInfo.authId">
        <li v-if="cpnAuth.showBalMn">
          <div class="label">{{ cpnAuth.BalMnNm }}</div>
          <div class="value">{{ thousand(dailyPlMn.balMn) }}</div>
        </li>
        <!-- <li>
          <div class="label">로스컷</div>
          <div class="value">{{thousand(totalLossCut)}}</div>
        </li> -->
        <li v-if="cpnAuth.showTotPlMn">
          <div class="label">{{ cpnAuth.totPlMn }}</div>
          <div class="value">{{ thousand(dailyPlMn.totPlMn) }}</div>
        </li>
        <li v-if="cpnAuth.showTotCms">
          <div class="label">{{ cpnAuth.totCmsNm }}</div>
          <div class="value">{{ thousand(dailyPlMn.totCms) }}</div>
        </li>
        <li v-if="cpnAuth.showTotNPlMn">
          <div class="label">{{ cpnAuth.totNPlMnNm }}</div>
          <div class="value">{{ thousand(dailyPlMn.totNPlMn) }}</div>
        </li>
      </ul>
    </div>
    <div class="time">{{ serverTime.replace(/(\d{2})(\d{2})(\d{2})/, "$1:$2:$3") }}</div>
    <HeaderMenu />
  </header>
</template>

<script>
import HeaderMenu from '@/components/HeaderMenu.vue';
import { required } from 'vee-validate/dist/rules';
import defaultImage1 from '@/assets/images/logo-white1.png'; // import를 사용하여 이미지 가져오기
import defaultImage2 from '@/assets/images/logo-white.png'; // import를 사용하여 이미지 가져오기
import defaultImage3 from '@/assets/images/logo-bein.png'; // import를 사용하여 이미지 가져오기
import defaultImage4 from '@/assets/images/logo-white-sp.png'; // import를 사용하여 이미지 가져오기

export default {
  name: 'WhiteLabelHeader',
  components: {
    HeaderMenu,
  },
  props: {
    account: {
      type: Object
    },
    setTotalValPl: {
      type: Object
    },
    setSymbolPlMn: {
      type: Object
    },
  },
  computed: {
    getDailyPlMn: function () {
      return window.$store.getters['SocketOms/getDailyPlMn'];
    },
    getLoginUserInfo: function () {
      let loginInfo = window.$store.getters['Login/getLoginUserInfo'];
      return loginInfo;
    },
    imageSrc() {
      const domain = window.location.hostname; // 현재 도메인 가져오기
      if (domain === 'mng.n-globalinv.com' || domain === 'n-globalinv.com') {
        return defaultImage1
      } else if (domain === 'beinasset.com') {
        return defaultImage3
      } else if (domain === 'sp-globalinv.com') {
        return defaultImage4
      } else {
        return defaultImage2
      }

    },
    getCpnAuthList: function () {
      return window.$store.getters['Layout/getCpnAuthList'];
    }

  },
  watch: {
    account: {
      handler(newval) {
        //this.searchDailyPlMn();
      },
      deep: true
    },
    setTotalValPl: {
      handler(newVal) {
        this.totalValPl = newVal.totalValPl;
        this.totalLossCut = newVal.totalLossCut;
        //this.totalLossCut = Number(newVal.totalQty) * ;

        this.setTotalVal();
      },
      deep: true
    },
    getDailyPlMn: {
      handler(res) {
        this.dailyPlMn.data = res;
        this.setDailyPlMn(res);
      },
      deep: true
    },
    setSymbolPlMn: {
      handler(newVal) {
        this.symbolPlMn = newVal;
      },
      deep: true
    },
    getLoginUserInfo: {
      handler(userInfo) {
        this.loginUserInfo = userInfo;
      }
    },
    getCpnAuthList: {
      handler(newCpnAuth) {
        if (newCpnAuth != undefined && newCpnAuth.length > 0) {
          newCpnAuth.forEach(item => {
            if (item.authTpCd == "PCTOPCMS") { //수수료 표시
              if (item.chgNmYn == "Y") {
                this.cpnAuth.BalMnNm = item.authTpNm;
              }
              if (item.useYn == "N") {
                this.cpnAuth.showTotCms = false;
              } else {
                this.cpnAuth.showTotCms = true;
              }
            }
          })
          //this.cpnAuth: {BalMnNm:'보유담보금', totPlMn: '총매매손익', totCmsNm:'총수수료', totNPlMnNm:'총손익합', showBalMn:false, showTotPlMn:false, showTotCms:false, showTotNPlMn:false},
        }
      },
      deep: true
    }

  },
  data() {
    return {
      currencyInfo: { currency: "WON", usdExR: 0, hkdExR: 0, usdExHkd: 0 },
      symValPl: 0,
      totalValPl: 0,
      totalLossCut: 0,
      dailyPlMn: { totPlMn: 0, totNPlMn: 0, totCms: 0, balMn: 0, usdExR: 0, totalValPl: 0, lossCut: 0, data: null, showCms: false, },
      cpnAuth: { BalMnNm: '보유담보금', totPlMn: '총매매손익', totCmsNm: '총수수료', totNPlMnNm: '총손익합', showBalMn: true, showTotPlMn: true, showTotCms: true, showTotNPlMn: true },
      serverGmt: "",
      serverTime: "000000",
      symbolPlMn: { valPl: 0 }
    }
  },

  created() {
    //this.getTime();
    setInterval(() => {
      this.getTime();
    }, 1000);
  },
  mounted() {
  },
  methods: {
    showModal(name) {
      let params = { account: this.account, mobileYn: "N" };
      this.$modal.show(name, params);
      let message = "";

      if (name == "modalDepositWithdraw") {
        message = "입출금신청 클릭";
      } else if (name == "modalDepositWithdrawTable") {
        message = "입출금내역 클릭";
      } else if (name == "modalTransactionTable") {
        message = "거래내역 클릭";
      } else if (name == "modalProfitLossTable") {
        message = "매매손익 클릭";
      } else if (name == "modalSettings") {
        message = "환경설정 클릭";
      }

      this.sendOmsLogMessage(message);
    },
    thousand(value) {
      //return value.toLocaleString('en-US', { minimumFractionDigits: 2 });
      if (value == undefined) {
        return 0;
      } else {
        return Intl.NumberFormat('en-US').format(value);
      }
    },
    getTime() {
      const curr = new Date();
      // UTC 시간 계산
      const utc = curr.getTime() + (curr.getTimezoneOffset() * 60 * 1000);

      // UTC to KST (UTC + 9시간)
      const KR_TIME_DIFF = 9 * 60 * 60 * 1000;
      const kr_curr = new Date(utc + (KR_TIME_DIFF));

      const month = String(kr_curr.getMonth() + 1).padStart(2, "0");
      const date = String(kr_curr.getDate()).padStart(2, "0");
      const h = String(kr_curr.getHours()).padStart(2, "0");
      const m = String(kr_curr.getMinutes()).padStart(2, "0");
      const s = String(kr_curr.getSeconds()).padStart(2, "0");
      // const weekday = ["일", "월", "화", "수", "목", "금", "토"];
      // const day = weekday[kr_curr.getDay()];
      var korGmt = `GMT+09:00 ${month}-${date}`

      this.serverGmt = korGmt;
      this.serverTime = `${h}${m}${s}`;

    },
    //일별 청산, 손익, 예수금, 달러 조회
    // async searchDailyPlMn() {
    //   if (!this.account) return;
    //   const response = await this.$http.get('/api/order/daily-pl-mn', { params: {accId: this.account.accId} })
    //   if ( response && response.data ) {
    //       let data = response.data;
    //       this.dailyPlMn.data = data;
    //       this.setDailyPlMn(data);
    //       this.$emit("updateDailyPlMn", data);
    //   }
    // },
    setDailyPlMn(data) {
      if (data == undefined || data.null) {
        return;
      }
      this.currencyInfo.usdExR = data.usdExR;
      this.currencyInfo.hkdExR = data.hkdExR;
      this.currencyInfo.usdExHkd = data.usdExHkd;
      if (this.currencyInfo.currency == "WON") {
        this.dailyPlMn.totPlMn = data.totPlMn; // 총손익금액
        this.dailyPlMn.totNPlMn = data.totNPlMn; // 총순손익
        this.dailyPlMn.totCms = data.totCms > 0 ? data.totCms * -1 : 0; // 총수수료

        this.dailyPlMn.fxPlMn = data.fxPlMn;
        this.dailyPlMn.fxCms = data.fxCms > 0 ? data.fxCms * -1 : 0;
        this.dailyPlMn.niPlMn = data.niPlMn;
        this.dailyPlMn.balMn = data.balMn;
        this.dailyPlMn.usdExR = data.usdExR;
      } else {
        this.dailyPlMn.totPlMn = data.totPlMn; // 총손익금액
        this.dailyPlMn.totNPlmn = data.totNPlmn; // 총순손익
        this.dailyPlMn.totCms = data.totCms > 0 ? data.totCms * -1 : 0; // 총수수료
        //this.dailyPlMn.balMn = data.balMn;
        this.dailyPlMn.usdBalMn = data.usdBalMn;
        this.dailyPlMn.usdExR = data.usdExR;
      }
    },
    setTotalVal() {
      if (this.currencyInfo.currency == "WON") {
        this.dailyPlMn.totalValPl = (this.totalValPl).toString();
      } else {
        if (this.totalValPl == 0) {
          this.dailyPlMn.totalValPl = "0";
        } else {
          this.dailyPlMn.totalValPl = (this.totalValPl).toFixed(2);
        }

      }

    },
    resetDailyPlMn() {
      this.dailyPlMn.totPlMn = (0).toString();
      this.dailyPlMn.totNPlmn = (0).toString();
      this.dailyPlMn.totCms = (0).toString();
      this.dailyPlMn.usdBalMn = (0).toString();
      this.dailyPlMn.usdExR = (0).toString();
      this.dailyPlMn.totalValPl = (0).toString();
    },
    sendOmsLogMessage(message) {
      window.$store.dispatch('SocketOms/setLogMsg', { message: message });
    }
  },
};
</script>
