<template>
  <div style="width: 100%; height: 100%">
    <ag-grid-vue style="width: 100%; height: 100%" class="ag-theme-wts" :columnDefs="grid.columnDefs"
      :rowData="grid.rowData" :headerHeight="rowHeight" :rowHeight="rowHeight" :pagination="true"
      :suppressPaginationPanel="true" :suppressScrollOnNewData="true" :columnHoverHighlight="true"
      @grid-ready="onGridReady" :overlayNoRowsTemplate="grid.overlayNoRowsTemplate" />
    <!--<PaginationComp :paging="grid.paging" @onPage="search" />-->
  </div>
</template>

<script>
// import { ref } from 'vue';
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
// import "ag-grid-community/styles/ag-theme-quartz.css"; // Theme
import { AgGridVue } from "ag-grid-vue"; // Vue Grid Logic
// eslint-disable-next-line no-unused-vars
// import {currencyFormatter, cellClassRulesMinus} from '@/agGridUtils.js';
import PaginationComp from '@/components/agTables/PaginationComp.vue';
import Utils from '@/modules/utils'
import gridUtils from '@/modules/grid-utils';
import { stringCount } from '@/modules/helper';
import { buySellType, cellClassRulesMinus } from '@/modules/grid-utils.js';

export default {
  name: "AgCoinOrderListTable",
  components: {
    AgGridVue,
    PaginationComp,
  },
  props: {
    coinAccount: {
      type: Object
    },
    setGridSearch: {
      type: Object
    }
  },
  computed: {
    getSymbolList: function () {
      let symbolList = window.$store.getters['Symbol/getSymbolList'];
      return symbolList;
    },
    getLoginUserInfo: function () {
      let loginInfo = window.$store.getters['Login/getLoginUserInfo'];
      return loginInfo;
    },
  },
  watch: {
    getSymbolList(symbolList) {
      this.symbolList = symbolList;
    },
    setGridSearch: {
      handler(newVal) {
        this.gridSearch = newVal;
        this.search(1);
      },
      deep: true
    },
  },
  data() {
    return {
      showColumn: false,
      gridSearch: {
        accId: 0,
        accPass: '',
        fromDate: Utils.getToday(),
        toDate: Utils.getToday(),
      },
      grid: {
        columnDefs: [],
        rowData: [],
        autoSizeStrategy: null,
        paginationPageSize: null,
        paging: {
          page: 1,
          size: 20,
          total: 0,
          pageSize: 10
        },
        overlayNoRowsTemplate: '<span style="margin-top: 30px;">조회된 거래내역이 없습니다.</span>',
      },
      gridApi: null,
      currentPage: '',
      totalPages: '',
      rowHeight: '',
      symbolList: [],
    };
  },
  created() {
    this.grid.rowData = [];
    this.grid.paging.page = 1;
    this.isMobile();
  },
  mounted() {
    this.symbolList = window.$store.getters['Symbol/getSymbolList'];

    this.grid.columnDefs = [
      {
        headerName: "주문번호",
        field: "ordId",
        minWidth: 80,
        flex: 1,
        cellStyle: {
          justifyContent: 'center'
        },
        headerClass: "center-align-header",
        suppressMovable: true,
      }, {

        headerName: "시간",
        field: "updTm",
        minWidth: 150,
        flex: 1,
        cellStyle: {
          justifyContent: 'center'
        },
        headerClass: "center-align-header",
        suppressMovable: true,
      },
      {
        headerName: "종목코드",
        field: "symCd",
        minWidth: 80,
        flex: 1,
        cellStyle: {
          justifyContent: 'center'
        },
        headerClass: "center-align-header",
        suppressMovable: true,
      },
      {
        headerName: "유형",
        field: "ordSdCd",
        minWidth: 80,
        flex: 1,
        cellStyle: {
          justifyContent: 'center'
        },
        headerClass: "center-align-header",
        suppressMovable: true,
        cellClassRules: buySellType,
      },
      {
        headerName: "구분",
        field: "ordTpCd",
        minWidth: 80,
        flex: 1,
        cellStyle: {
          justifyContent: 'center'
        },
        headerClass: "center-align-header",
        suppressMovable: true,
      },
      {
        headerName: "가격",
        field: "execPrc",
        minWidth: 80,
        flex: 1,
        cellStyle: {
          justifyContent: 'center'
        },
        headerClass: "center-align-header",
        suppressMovable: true,
      },
      {
        headerName: "수량",
        field: "execQty",
        minWidth: 80,
        flex: 1,
        cellStyle: {
          justifyContent: 'center'
        },
        headerClass: "center-align-header",
        suppressMovable: true,
      },
      {
        headerName: "실현손익",
        field: "ordPlMn",
        minWidth: 80,
        flex: 1,
        cellStyle: {
          justifyContent: 'center'
        },
        headerClass: "center-align-header",
        suppressMovable: true,
      },
      {
        headerName: "수수료",
        field: "cms",
        minWidth: 80,
        flex: 1,
        cellStyle: {
          justifyContent: 'center'
        },
        headerClass: "center-align-header",
        suppressMovable: true,
      },
      {
        headerName: "총손익",
        field: "totPlMn",
        minWidth: 80,
        flex: 1,
        cellStyle: {
          justifyContent: 'center'
        },
        headerClass: "center-align-header",
        suppressMovable: true,
      },
      {
        headerName: "이전보유잔고",
        field: "bfBalMn",
        minWidth: 100,
        flex: 1,
        cellStyle: {
          justifyContent: 'center'
        },
        headerClass: "center-align-header",
        suppressMovable: true,
      },
      {
        headerName: "이후보유잔고",
        field: "afBalMn",
        minWidth: 100,
        flex: 1,
        cellStyle: {
          justifyContent: 'center'
        },
        headerClass: "center-align-header",
        suppressMovable: true,
      }];
  },
  methods: {
    async search(page) {
      if (page) {
        this.grid.paging.page = page
      }

      // 조회 조건
      this.gridSearch.accId = this.coinAccount.accId
      //this.gridSearch.accPass = this.account.accPass
      this.gridSearch.page = this.grid.paging.page
      this.gridSearch.size = this.grid.paging.size

      const response = await this.$lhttp.get('/api/account/coinorder/list/page', { params: this.gridSearch })

      if (response && response.data) {
        this.grid.paging.total = response.data.total
        this.grid.rowData = response.data.content

        if (response.data.content.length <= 0) {
          this.$alert.alert('조회된 거래내역이 없습니다.', '알림')
        }
      }
    },
    onGridReady(params) {
      this.gridApi = params.api;
    },
    isMobile() {
      if (window.innerWidth <= 1200) {
        return this.rowHeight = 40;
      } else {
        return this.rowHeight = 30;
      }
    },
    tikDotSz(symbol) {
      if (symbol == null) {
        return 0;
      }
      return stringCount(symbol.pricescale);
    },
  },
};

</script>

<style lang="scss" scoped>
@import '@/assets/scss/agGridStyles.scss';
</style>