import Vue from 'vue'
import VueRouter from 'vue-router'
import ComponentsPage from "@/views/ComponentsPage.vue";
import TestPage from '@/views/TestPage.vue';

import Init from '@/views/Init.vue';

import Login1Page from '@/views/Login1Page.vue';
import Signup1Page from '@/views/Signup1Page.vue';

import Login2Page from '@/views/Login2Page.vue';
import Signup2Page from '@/views/Signup2Page.vue';

import DesktopPage from '@/views/DesktopPage.vue';
import MobilePage from '@/views/MobilePage.vue';

import PartnerPage from '@/views/PartnerPage.vue';
import CoinPartnerPage from '@/views/CoinPartnerPage.vue';

import PopDeskTopPage from '@/views/PopDeskTopPage.vue';

import CoinPage from "@/views/CoinPage.vue";
import CoinMobilePage from "@/views/CoinMobilePage.vue";

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  
  routes: [
		{
			path: "/",
			name: "Login",
			component: Login1Page,
			meta: { authRequired: false }
		},
		{
			path: "/components",
			name: "Component Page",
			component: ComponentsPage,
			meta: { authRequired: false }
		},
		{
			path: "/test",
			name: "Test Page",
			component: TestPage,
			meta: { authRequired: false }
		},
		{
			path: "/login",
			name: "Login1 Page",
			component: Login1Page,
			meta: { authRequired: false }
		},
		{
			path: "/signup",
			name: "Signup1 Page",
			component: Signup1Page,
			meta: { authRequired: false }
		},
		{
			path: "/login2",
			name: "Login2 Page",
			component: Login2Page,
			meta: { authRequired: false }
		},
		{
			path: "/signup2",
			name: "Signup2 Page",
			component: Signup2Page,
			meta: { authRequired: false }
		},
		{
			path: "/desktop",
			name: "Desktop Page",
			component: DesktopPage,
			meta: { authRequired: true }
		},
		{
			path: "/mobile",
			name: "Mobile Page",
			component: MobilePage,
			meta: { authRequired: true }
		},
		{
			path: "/partner",
			name: "Partner Page",
			component: PartnerPage,
			meta: { authRequired: true }
			
        },
		{
			path: "/coinpartner",
			name: "CoinPartner Page",
			component: CoinPartnerPage,
			meta: { authRequired: true }
			
		},
		{
			path: "/orderPop",
			name: "order popup",
			component: PopDeskTopPage,
			meta: { authRequired: true }
			
		},
		{
			path: "/coinDesktop",
			name: "Coin Page",
			component: CoinPage
		},
		{
			path: "/coinMobile",
			name: "Coin Mobile Page",
			component: CoinMobilePage
		},
	]
});

export default router