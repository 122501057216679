<template>
    <div style="width: 100%; height: 100%">
      <ag-grid-vue style="width: 100%; height: 100%" class="ag-theme-wts" :columnDefs="grid.columnDefs"
        :rowData="grid.rowData" :headerHeight="rowHeight" :rowHeight="rowHeight" :paginationPageSize="paginationPageSize"
        :pagination="true" :suppressPaginationPanel="true" :suppressScrollOnNewData="true"
        @pagination-changed="onPaginationChanged" @grid-ready="onGridReady"
        :overlayNoRowsTemplate="grid.overlayNoRowsTemplate" />
      <!-- <PaginationComp :currentPage="currentPage" :totalPages="totalPages" @toFirstPage="toFirstPage" @toPrevPage="toPrevPage" @toNextPage="toNextPage" @toLastPage="toLastPage" /> -->
      <!--<PaginationComp :paging="grid.paging" @onPage="search" />-->
    </div>
  </template>
  
  <script>
  // import { ref } from 'vue';
  import "ag-grid-community/styles/ag-grid.css"; // Core CSS
  // import "ag-grid-community/styles/ag-theme-quartz.css"; // Theme
  import { AgGridVue } from "ag-grid-vue"; // Vue Grid Logic
  // eslint-disable-next-line no-unused-vars
  import PaginationComp from '@/components/agTables/PaginationComp.vue';
  import Utils from '@/modules/utils';
  import gridUtils from '@/modules/grid-utils';
  
  export default {
    name: "agProfitLossTable",
    components: {
      AgGridVue,
      PaginationComp,
    },
    props: {
      coinAccount: {
        type: Object
      },
      setGridSearch: {
        type: Object
      }
    },
    watch: {
      setGridSearch: {
        handler(newVal) {
          this.gridSearch = newVal;
          this.search(1);
        },
        deep: true
      }
    },
    data() {
      return {
        gridSearch: {
          accId: 0,
          accPass: '',
          fromDate: Utils.getDatePrevMonth(1),
          toDate: Utils.getToday(),
        },
        grid: {
          columnDefs: [
            {
              headerName: "거래일자",
              field: "trdDt",
              minWidth: 80,
              flex: 1,
              cellStyle: {
                justifyContent: 'center'
              },
              headerClass: "center-align-header",
              suppressMovable: true,
            },
            {
              headerName: "신청시간",
              field: "dpsWdReqTm",
              minWidth: 80,
              flex: 1,
              cellStyle: {
                justifyContent: 'center'
              },
              headerClass: "center-align-header",
              suppressMovable: true,
            },
            {
              headerName: "구분",
              field: "dpsWdTpNm",
              minWidth: 80,
              flex: 1,
              cellStyle: {
                justifyContent: 'center'
              },
              headerClass: "center-align-header",
              suppressMovable: true,
            },
            {
              headerName: "금액",
              field: "amount",
              minWidth: 80,
              flex: 1,
              cellStyle: {
                justifyContent: 'center'
              },
              headerClass: "center-align-header",
              suppressMovable: true,
              valueFormatter: gridUtils.numberComma
            },
            {
              headerName: "처리시간",
              field: "dpsWdProcTm",
              minWidth: 80,
              flex: 1,
              cellStyle: {
                justifyContent: 'center'
              },
              headerClass: "center-align-header",
              suppressMovable: true,
            },
            {
              headerName: "상태",
              field: "dpsWdProcNm",
              minWidth: 80,
              flex: 1,
              cellStyle: {
                justifyContent: 'center'
              },
              headerClass: "center-align-header",
              suppressMovable: true,
            },
            {
              headerName: "비고",
              field: "cmt",
              minWidth: 200,
              flex: 1,
              cellStyle: {
                justifyContent: 'center'
              },
              headerClass: "center-align-header",
              suppressMovable: true,
            },
          ],
          rowData: [],
          gridApi: null,
          autoSizeStrategy: null,
          paginationPageSize: null,
          overlayNoRowsTemplate: '<span style="margin-top: 30px;">조회된 입출금내역이 없습니다.</span>',
          paging: {
            page: 1,
            size: 20,
            total: 0,
            pageSize: 10
          }
        },
        currentPage: '',
        totalPages: '',
        rowHeight: '',
  
      };
    },
    created() {
      this.paginationPageSize = 12;
      this.isMobile();
    },
    mounted() {
      this.grid.rowData = [];
      this.grid.paging.page = 1;
      // console.log("거래내역");
    },
    methods: {
      onPaginationChanged() {
        console.log('onPaginationPageLoaded');
        if (this.gridApi) {
          this.currentPage = this.gridApi.paginationGetCurrentPage() + 1;
          this.totalPages = this.gridApi.paginationGetTotalPages();
        }
      },
      toFirstPage() {
        console.log('current page: ' + this.currentPage)
        this.gridApi.paginationGoToFirstPage();
      },
      toPrevPage() {
        this.gridApi.paginationGoToPreviousPage();
      },
      toNextPage() {
        this.gridApi.paginationGoToNextPage();
      },
      toLastPage() {
        this.gridApi.paginationGoToLastPage();
      },
      onGridReady(params) {
        this.gridApi = params.api;
      },
      isMobile() {
        if (window.innerWidth <= 1200) {
          return this.rowHeight = 40;
        } else {
          return this.rowHeight = 30;
        }
      },
      async search(page) {
        if (page) {
          this.grid.paging.page = page
        }
  
        // 조회 조건
        this.gridSearch.accId = this.coinAccount.accId
        //this.gridSearch.accPass = this.account.accPass
        this.gridSearch.page = this.grid.paging.page
        this.gridSearch.size = this.grid.paging.size
  
        const response = await this.$lhttp.get('/api/account/dpswd/list/page', { params: this.gridSearch })
  
        if (response && response.data) {
          this.grid.paging.total = response.data.total
          this.grid.rowData = response.data.content
  
          if (response.data.content.length <= 0) {
            this.$alert.alert('조회된 입출금내역이 없습니다.', '안내')
          }
        }
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  @import '@/assets/scss/agGridStyles.scss';
  </style>