<template>
  <div style="width: 100%; height: 100%">
    <ag-grid-vue style="width: 100%; height: 100%" class="ag-theme-wts" :columnDefs="grid.columnDefs"
      :rowData="grid.rowData" :headerHeight="rowHeight" :rowHeight="rowHeight" :suppressScrollOnNewData="true"
      :columnHoverHighlight="true" @grid-ready="onGridReady" />
  </div>
</template>

<script>
// import { ref } from 'vue';
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
// import "ag-grid-community/styles/ag-theme-quartz.css"; // Theme
import { AgGridVue } from "ag-grid-vue"; // Vue Grid Logic
// eslint-disable-next-line no-unused-vars
import Utils from '@/modules/utils'
import gridUtils from '@/modules/grid-utils';
import { buySellType, cellClassRulesMinus } from '@/modules/grid-utils.js';

export default {
  name: "AgCoinProfitLossTablePartner",
  components: {
    AgGridVue,
  },
  props: {
    account: {
      type: Object
    },
    setGridSearch: {
      type: Object
    }
  },
  watch: {
    setGridSearch: {
      handler(newVal) {
        this.gridSearch = newVal;
        this.search();
      },
      deep: true
    }
  },
  data() {
    return {
      gridSearch: {
        accId: 0,
        //accPass: '',
        symCd: '',
        fromDate: Utils.getToday(),
        toDate: Utils.getToday(),
        //crc: 'USD',
      },
      grid: {
        columnDefs: [
          {
            headerName: "거래일자",
            field: "trdDt",
            minWidth: 110,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
            sortable: true,
            valueFormatter: (d) => {
              if (d.value) {
                return d.value.replace(/(\d{4})(\d{2})(\d{2})/, "$1-$2-$3");
              } else {
                return '';
              }
            },
          },
          {
            headerName: "총손익",
            field: "totPlMn",
            minWidth: 110,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
            sortable: true,
            cellClassRules: cellClassRulesMinus,
            valueFormatter: gridUtils.numberComma,
          },
          {
            headerName: "매매손익합",
            field: "plMn",
            minWidth: 110,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
            sortable: true,
            cellClassRules: cellClassRulesMinus,
            valueFormatter: gridUtils.numberComma,
          },
          {
            headerName: "수수료합",
            field: "totCms",
            minWidth: 110,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
            sortable: true,
            cellClassRules: cellClassRulesMinus,
            valueFormatter: gridUtils.numberComma,
          },
        ],
        rowData: [],
        bottomRowData: [],
        gridApi: null,
        autoSizeStrategy: null,
      },
      rowHeight: '',
      mobileMinWidth: ''
    };
  },
  created() {
    this.grid.rowData = [];
    this.isMobile();
  },
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
    },
    isMobile() {
      if (window.innerWidth <= 1200) {
        return this.rowHeight = 40;
      } else {
        return this.rowHeight = 30;
      }
    },
    async search() {
      this.grid.rowData = [];
      this.grid.bottomRowData = [];
      if (this.gridSearch.usrId == "") { return }
      const response = await this.$http.post('/api/coin/partner/usrprofitlist', { usrId: this.gridSearch.setusrId, usrSeq: this.gridSearch.setusrSeq, stdt: this.gridSearch.fromDate, eddt: this.gridSearch.toDate, tp: '0', cpnId: this.gridSearch.cpnId })
      let totPlMn = 0;
      let plMn = 0;
      let totCms = 0;
      if (response && response.data) {
        this.grid.rowData = response.data.content
        let sum = [{
          trdDt: '합계',
          totPlMn: totPlMn, //총손익
          plMn: plMn, //매매손익합
          totCms: totCms, //totCms
        }];
        if (response.data.sum != undefined && response.data.sum.length > 0) {
          sum = response.data.sum;
        }

        this.gridApi.setPinnedBottomRowData(sum);
      }
    }
  },
};



</script>

<style lang="scss" scoped>
@import '@/assets/scss/agGridStyles.scss';
</style>