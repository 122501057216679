<template>
  <div style="width: 100%; height: 100%">
    <ag-grid-vue style="width: 100%; height: 100%" class="ag-theme-wts" :columnDefs="grid.columnDefs"
      :rowData="grid.rowData" :headerHeight="rowHeight" :rowHeight="rowHeight" :columnHoverHighlight="true"
      @grid-ready="onGridReady" />
  </div>
</template>

<script>
// import { ref } from 'vue';
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
// import "ag-grid-community/styles/ag-theme-quartz.css"; // Theme
import { AgGridVue } from "ag-grid-vue"; // Vue Grid Logic
// eslint-disable-next-line no-unused-vars
// import {currencyFormatter, cellClassRulesMinus} from '@/agGridUtils.js';
import Utils from '@/modules/utils'
import { buySellType, cellClassRulesMinus } from '@/modules/grid-utils.js';

export default {
  name: "AgCoinTransactionTablePartner",
  components: {
    AgGridVue,
  },
  props: {
    account: {
      type: Object
    },
    setGridSearch: {
      type: Object
    }
  },
  computed: {
    getLoginUserInfo: function () {
      let loginInfo = window.$store.getters['Login/getLoginUserInfo'];
      return loginInfo;
    },
  },
  watch: {
    setGridSearch: {
      handler(newVal) {
        this.gridSearch = newVal;
        this.search();
      },
      deep: true
    }
  },
  data() {
    return {
      showColumn: false,
      gridSearch: {
        accId: 0,
        accPass: '',
        fromDate: Utils.getToday(),
        toDate: Utils.getToday(),
        trdDt: Utils.getToday(),
      },
      grid: {
        columnDefs: [],
        rowData: [],
        autoSizeStrategy: null,

      },
      gridApi: null,
      rowHeight: ''
    };
  },
  created() {
    this.grid.rowData = [];
    this.isMobile();
  },
  mounted() {
    this.showColumn = this.getLoginUserInfo.lvTpCd == "Y" ? true : false;
    this.grid.columnDefs = [
      {
        headerName: "주문번호",
        field: "ordId",
        minWidth: 80,
        flex: 1,
        cellStyle: {
          justifyContent: 'center'
        },
        headerClass: "center-align-header",
        suppressMovable: true,
      }, {

        headerName: "시간",
        field: "updTm",
        minWidth: 150,
        flex: 1,
        cellStyle: {
          justifyContent: 'center'
        },
        headerClass: "center-align-header",
        suppressMovable: true,
      },
      {
        headerName: "종목코드",
        field: "symCd",
        minWidth: 80,
        flex: 1,
        cellStyle: {
          justifyContent: 'center'
        },
        headerClass: "center-align-header",
        suppressMovable: true,
      },
      {
        headerName: "유형",
        field: "ordSdCd",
        minWidth: 80,
        flex: 1,
        cellStyle: {
          justifyContent: 'center'
        },
        headerClass: "center-align-header",
        suppressMovable: true,
        cellClassRules: buySellType,
      },
      {
        headerName: "구분",
        field: "ordTpCd",
        minWidth: 80,
        flex: 1,
        cellStyle: {
          justifyContent: 'center'
        },
        headerClass: "center-align-header",
        suppressMovable: true,
      },
      {
        headerName: "가격",
        field: "execPrc",
        minWidth: 80,
        flex: 1,
        cellStyle: {
          justifyContent: 'center'
        },
        headerClass: "center-align-header",
        suppressMovable: true,
      },
      {
        headerName: "수량",
        field: "execQty",
        minWidth: 80,
        flex: 1,
        cellStyle: {
          justifyContent: 'center'
        },
        headerClass: "center-align-header",
        suppressMovable: true,
      },
      {
        headerName: "실현손익",
        field: "ordPlMn",
        minWidth: 80,
        flex: 1,
        cellStyle: {
          justifyContent: 'center'
        },
        headerClass: "center-align-header",
        suppressMovable: true,
      },
      {
        headerName: "수수료",
        field: "cms",
        minWidth: 80,
        flex: 1,
        cellStyle: {
          justifyContent: 'center'
        },
        headerClass: "center-align-header",
        suppressMovable: true,
      },
      {
        headerName: "총손익",
        field: "totPlMn",
        minWidth: 80,
        flex: 1,
        cellStyle: {
          justifyContent: 'center'
        },
        headerClass: "center-align-header",
        suppressMovable: true,
      }]
  },
  methods: {
    async search() {
      this.grid.rowData = [];
      //url 변경필요
      if (this.gridSearch.usrId == "") { return }
      const response = await this.$http.post('/api/coin/partner/usrorderlist', { usrId: this.gridSearch.setusrId, usrSeq: this.gridSearch.setusrSeq, trdDt: this.gridSearch.trdDt, tp: this.gridSearch.ordType, cpnId: this.gridSearch.cpnId })

      if (response && response.data) {
        this.grid.rowData = response.data
      }
    },
    onGridReady(params) {
      this.gridApi = params.api;
    },
    isMobile() {
      if (window.innerWidth <= 1200) {
        return this.rowHeight = 40;
      } else {
        return this.rowHeight = 30;
      }
    },
  },
};

</script>

<style lang="scss" scoped>
@import '@/assets/scss/agGridStyles.scss';
</style>