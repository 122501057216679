<template>
  <div>
    <modal name="modalTPSlAll" classes="main-modal" :adaptive="true" :width="width" :height="height"
    @before-open="beforeOpen"
    @opened="opened">
      <div class="modal-header">
        <h2 class="modal-header-title">전체 포지션 익절(TP)/손절(SL)</h2>
        <button @click="closeModal()"><img src="@/assets/icons/icon-close-modal.svg" alt="" /></button>
      </div>

      <div class="modal-body">
        <div class="modal-tpsl-all">
          <table class="modal-tpsl-all-table">
            <tbody>
              <tr>
                <td>
                  <div class="label">심볼</div>
                  <div class="value"><span class="orange">{{ ordInfo.symCd}} {{ ordInfo.lv }}x</span></div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="label">진입가격</div>
                  <div class="value">{{ toFixNum(ordInfo.symCd, ordInfo.execPrc) }} USDT</div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="label">종가</div>
                  <div class="value">{{ toFixNum(ordInfo.symCd, coinNowPrice.curPr) }} USDT</div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="label">시장평균가</div>
                  <div class="value">{{ toFixNum(ordInfo.symCd, com.markPr) }} USDT</div>
                </td>
              </tr>
            </tbody>
          </table>

          <div class="order-input-group">
            <div class="order-input-container">
              <label for="">익절(TP)</label>
              <input type="number" placeholder="" v-model="tpOrdPrc" @input="setTpOrdPrc" />
              <div class="unit">
                <CoinCodeDropDown grpCd="PRCTP" v-model="ordTpType" width="64" height="72" popup />
              </div>
            </div>
            <p v-if="tpPnlFlg == 'N'"><span class="red">발동 가격은 {{ ordTpType == "1" ? "종가":"시장평균가" }} 가격보다 {{ ordInfo.ordSdCd == "1" ? '낮아야':'높아야'}} 합니다.</span></p>
            <p><span class="bold">{{ ordTpType == "1" ? "종가":"시장평균가" }}</span>가 <span class="bold">{{ tpOrdPrc == '' ? (0).toFixed(coinSymbolInfo.dotCnt) : Number(tpOrdPrc).toFixed(coinSymbolInfo.dotCnt) }}</span>에 도달하면 익절 시장가 주문을 발동하여 포지션을 종료합니다. 예상 PNL은 다음과 같습니다. <span :class="{ 'color-blue': tpPnlPrc != '' && Number(tpPnlPrc) < 0, 'color-red': tpPnlPrc != '' && Number(tpPnlPrc) > 0, '': tpPnlPrc == '' }">{{ tpPnlPrc == null ? '-' : Number(tpPnlPrc).toFixed(2) }} USDT</span></p>
          </div>

          <div class="order-input-group">
            <div class="order-input-container">
              <label for="">손절(SL)</label>
              <input type="number" placeholder="" v-model="slOrdPrc" @input="setSlOrdPrc" />
              <div class="unit">
                <CoinCodeDropDown grpCd="PRCTP" v-model="ordSlType" width="64" height="72" popup />
              </div>
            </div>
            <p v-if="slPnlFlg == 'N'"><span class="red">발동 가격은 {{ ordTpType == "1" ? "종가":"시장평균가" }} 가격보다 {{ ordInfo.ordSdCd == "1" ? '높아야':'낮아야'}} 합니다.</span></p>
            <p><span class="bold">{{ ordSlType == "1" ? "종가":"시장평균가" }}</span>가 <span class="bold">{{ slOrdPrc == '' ? (0).toFixed(coinSymbolInfo.dotCnt) : Number(slOrdPrc).toFixed(coinSymbolInfo.dotCnt) }}</span>에 도달하면 손절 시장가 주문을 발동하여 포지션을 종료합니다. 예상 PNL은 다음과 같습니다. <span :class="{ 'color-blue': slPnlPrc != '' && Number(slPnlPrc) < 0, 'color-red': slPnlPrc != '' && Number(slPnlPrc) > 0, '': slPnlPrc == '' }">{{ slPnlPrc == null ? '-' : Number(slPnlPrc).toFixed(2) }} USDT</span></p>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <button class="btn-primary blue" @click="cancelTpSl('1')" v-if="tpId > 0">TP 취소</button>
        <button class="btn-primary blue" @click="cancelTpSl('2')" v-if="slId > 0">SL 취소</button>
        <button class="btn-primary blue" :class="{'btn-disabled':tpPnlFlg == 'N' || slPnlFlg == 'N' }" :disabled="(tpPnlFlg == 'N' || slPnlFlg == 'N') ? true : false" @click="saveTpSl">확인</button>
      </div>
    </modal>
  </div>
</template>

<script>
import Utils from '@/modules/utils';
import CoinCodeDropDown from '@/components/coin/CoinCodeDropDown.vue';
export default {
  name: 'CoinModalTPSLAll',
  components: {
    CoinCodeDropDown,
  },

  computed: {
    //소켓 코인 시세
    changeCocData: function () {
      let cocData = window.$store.getters['SocketCoinPrice/getCocData'];
      return cocData;
    },
    //소켓 코인 청산가
    changeComData: function(){
      let comData = window.$store.getters['SocketCoinPrice/getComData']
      return comData
    },
    //폴링시세
    changeCocDataList: function () {
      let cocData = window.$store.getters['SocketCoinPrice/getCocDataList'];
      return cocData;
    },
    tikDotSz() {
      return Utils.tikDotSz(this.coinSymbol);
    },

  },
  watch: {
    //소켓시세
    changeCocData(newPrice) {
      //console.log("changeOvcData >>>> ", newPrice)
      if (newPrice.symbol == this.coinSymbol.name) {
        this.coinNowPrice.curPr = newPrice.curPr;
        this.coinNowPrice.buyerMaker = newPrice.buyerMaker;

        this.setTpOrdPrc();
        this.setSlOrdPrc();
      }
    },
    //폴링시세
    changeCocDataList(newPrice) {
      //
    },
    //소켓 코인 청산가
    changeComData(newPrice){
      if (newPrice != null && this.ordInfo != null && newPrice.symbol == this.ordInfo.symCd){
        this.com.markPr = (Math.floor(newPrice.markPr * 100) / 100).toFixed(this.tikDotSz);

        this.setTpOrdPrc();
        this.setSlOrdPrc();
      }
    },
  },
  data() {
    return {
      width: '',
      height: '',
      ordTpType:'1',
      ordSlType:'1',
      ordInfo:{},
      coinSymbol: {},
      com: {markPr:0},
      coinSymbolInfoList:null,
      ordInfoList: [],
      orgOrdInfoList: [],
      tpOrdPrc: '',
      slOrdPrc: '',
      tpPnlPrc: '',
      slPnlPrc: '',
      tpPnlFlg: "Y",
      slPnlFlg: "Y",
      slId: 0,
      tpId: 0,
      coinNowPrice: { curPr: 0, ydiffPr: 0, open: 0, chGrate: 0, high: 0, low: 0, ydiffSign: 0 },
      coinSymbolInfo: { symCd: 'BTCUSDT', symNo: 101, tikSz: 0.1, artcCd: 'BTC', dotCnt: 1, korNm: '비트코인', lmtOrdMaxQty: 0, lmtOrdPrcCfRat: 0, maxOpnOrdCnt: 0, minOrdAmt: 0, minTrdAmt: 0, mktOrdMaxQty: 0 },
    };
  },

  created() {
    if (window.innerWidth > 1200) {
      this.width = 500;
      this.height = 472;
    } else {
      this.width = '100%';
      this.height = '100%';
    }
  },

  mounted() {},

  methods: {
    opened(){

    },
    beforeOpen(e){
      console.log("beforeOpen e.prams", e.params);

      //초기화
      this.ordInfo = {};
      this.orgOrdInfoList = [];
      this.ordTpType = '1';
      this.ordSlType = '1';
      this.tpOrdPrc = '';
      this.slOrdPrc = '';
      this.tpPnlPrc = '';
      this.slPnlPrc = '';
      this.tpPnlFlg = "Y";
      this.slPnlFlg = "Y";
      this.tpId = 0;
      this.slId = 0;
      this.ordInfo = e.params;

      this.coinSymbolList = window.$store.getters['Symbol/getCoinSymbolList'];

      this.coinSymbolList.forEach(item => {
        if (item.name == this.ordInfo.symCd){
          this.coinSymbol = item;
        }
      });

      let coinSymbolInfoList = window.$store.getters['Symbol/getCoinSymbolInfoList'];
      if (coinSymbolInfoList != undefined && coinSymbolInfoList.length > 0) {
        for (let item of coinSymbolInfoList){
          //
          if (item.symNo == this.ordInfo.symbolno) {
            this.coinSymbolInfo = item;
            break;
          }
        }
      }

      this.com.markPr = (Math.floor(this.ordInfo.markPr * 100) / 100).toFixed(this.coinSymbolInfo.dotCnt);

      this.searchOrdInfo();
    },
    toFixNum(symCd, execPrc) {
      let rtnValue = '';
      const value = execPrc;

      //data.markPr = this.comMap.get(data.symCd).markPr.toFixed(Utils.tikDotSz(symbolData));

      if (value != undefined && value != null) {
        rtnValue = Utils.toFixNum(value, Utils.tikDotSz(this.coinSymbol)).toFixed(Utils.tikDotSz(this.coinSymbol));
      }
      return rtnValue;
    },
    //tpsl 목록 조회
    async searchOrdInfo(){
      // 
      // ordInfoList
      const data = {posSeq:this.ordInfo.posSeq};
      await this.$lhttp.get('/api/order/getCoinOrdSlList', { params: data }).then((response) => { 
        if (response.code == 200){
          console.log("response", response);
          //this.ordInfoList = Object.assign([], response.data);
          //원본 저장
          for (let item of response.data){
            this.orgOrdInfoList.push({slId:item.slId, slOrdPrc:item.slOrdPrc, slOrdQty:item.slOrdQty, slOrdSdCd:item.slOrdSdCd, slOrdStatCd:item.slOrdStatCd, slTpCd:item.slTpCd, prcTpCd:item.prcTpCd});
            if (item.slTpCd == "1"){
              this.tpId = item.slId;
              this.tpOrdPrc = item.slOrdPrc;
              this.ordTpType = item.prcTpCd;
            }else if (item.slTpCd == "2"){
              this.slId = item.slId;
              this.slOrdPrc = item.slOrdPrc;
              this.ordSlType = item.prcTpCd;
            }
          }
        }
      });
    },
    closeModal() {
      this.$modal.hide('modalTPSlAll');
    },
    //취소
    cancelTpSl(type){
      let saveItem =null;
      for (let item of this.orgOrdInfoList){
        if (type == item.slTpCd){
          saveItem = item;
          break;
        }
      }

      if (saveItem == null){
        window.$alert.alert('TP/SL 정보가 없습니다.')
        return;
      }

      let saveInfo = {ordInfo: this.ordInfo, tpSlData:saveItem};
      this.$emit("event:cancelTpSlPos", saveInfo);
      this.closeModal();
    },
    saveTpSl(){
      let saveInfo = {ordInfo: this.ordInfo, saveList:[]};
      let saveYn = "N";
      let slOrdSdCd = "0";
      if (this.ordInfo.ordSdCd == "1"){ //매도
        slOrdSdCd = "2"; //매수
      }else if (this.ordInfo.ordSdCd == "2"){ //매수
        slOrdSdCd = "1"; //매도
      }

      //익절/손절 현재가, 반대 가격 체크
      //지정가 호가 공백 체크
      // if (slOrdSdCd == "1"){ //매도
      //   //현재가 매도1호가 사이 주문 불가
      //   if (this.stdOrdPrc > this.coinNowPrice.curPr && this.stdOrdPrc < this.coinCohPrice.bids[0][0]){
      //     window.$alert.alert('매도 호가 공백에는 지정가를 주문할 수 없습니다.', '알림');
      //     return;
      //   }
      // }else if (slOrdSdCd == "2"){ //매수
      //   //현재가 매수1호가 사이 주문 불가
      //   if (this.stdOrdPrc < this.coinNowPrice.curPr && this.stdOrdPrc > this.coinCohPrice.asks[0][0]){
      //     window.$alert.alert('매수 호가 공백에는 지정가를 주문할 수 없습니다.', '알림');
      //     return;
      //   }
      // }
      

      //수정이면
      if (this.orgOrdInfoList.length > 0){
        let slYn = "N";
        let tpYn = "N";
        for (let item of this.orgOrdInfoList){
          if (item.slTpCd == "1"){ //익절
            if (Number(item.slOrdPrc) != Number(this.tpOrdPrc)){
              item.slOrdPrc = this.tpOrdPrc;
              saveYn = "Y";
            }
            if (item.prcTpCd != this.ordTpType){
              item.prcTpCd = this.ordTpType;
              saveYn = "Y";
            }
            slYn = "Y";
          }else if (item.slTpCd == "2"){ //손절
            if (Number(item.slOrdPrc) != Number(this.slOrdPrc)){
              item.slOrdPrc = this.slOrdPrc;
              saveYn = "Y";
            }
            if (item.prcTpCd != this.ordSlType){
              item.prcTpCd = this.ordSlType;
              saveYn = "Y";
            }
            tpYn = "Y";
          }
          if (saveYn == "Y"){
            item.orgSlOrdPrc = item.slOrdPrc; //삭제할 가격
            saveInfo.saveList.push(item);
          }
        }

        //TP/SL 중 1개만 저장되어있으면 나머지 신규로 생성
        if (slYn == "N" && this.tpOrdPrc != null && Number(this.tpOrdPrc) > 0){ // 익절
          let item = {slId:0, posSeq:this.ordInfo.posSeq, slOrdPrc:this.tpOrdPrc, slOrdQty:0, slTpCd:"1", prcTpCd:this.ordTpType, slOrdSdCd:slOrdSdCd};
          saveInfo.saveList.push(item);
          saveYn = "Y";
        }else if (tpYn == "N" && this.slOrdPrc != null && Number(this.slOrdPrc) > 0){ // 손절
          let item = {slId:0, posSeq:this.ordInfo.posSeq, slOrdPrc:this.slOrdPrc, slOrdQty:0, slTpCd:"2", prcTpCd:this.ordSlType, slOrdSdCd:slOrdSdCd};
          saveInfo.saveList.push(item);
          saveYn = "Y";
        }
      }else { //신규저장
        if (this.tpOrdPrc != null && Number(this.tpOrdPrc) > 0){ //익절
          let item = {slId:0, posSeq:this.ordInfo.posSeq, slOrdPrc:this.tpOrdPrc, slOrdQty:0, slTpCd:"1", prcTpCd:this.ordTpType, slOrdSdCd:slOrdSdCd};
          saveInfo.saveList.push(item);
          saveYn = "Y";
        }
        if (this.slOrdPrc != null && Number(this.slOrdPrc) > 0){ //손절
          let item = {slId:0, posSeq:this.ordInfo.posSeq, slOrdPrc:this.slOrdPrc, slOrdQty:0, slTpCd:"2", prcTpCd:this.ordSlType, slOrdSdCd:slOrdSdCd};
          saveInfo.saveList.push(item);
          saveYn = "Y";
        }
      }

      console.log("saveInfo List?? ", saveInfo)

      //저장
      if (saveYn == "Y"){
        this.$emit("event:saveTpSl", saveInfo);
        this.closeModal();
      }else{
        this.closeModal();
      }
    },
    //익절(TP) PNL 계산
    setTpOrdPrc(){
      //금액이 없으면
      if (this.tpOrdPrc == ''){
        this.tpPnlPrc = '';
        this.tpPnlFlg = "Y";
      }else if (!isNaN(this.tpOrdPrc)){
        this.tpPnlPrc = Utils.getTpSlCalPrc(this.ordInfo, Number(this.tpOrdPrc), this.coinSymbolInfo.dotCnt, this.ordInfo.lv);

        //pnl이 마이너스면 저장 불가 설정
        // if (Number(this.tpPnlPrc) < 0){
        //   this.tpPnlFlg = "N";
        // }else {
          //종가
          if (this.ordTpType == "1"){
            //매도
            if (this.ordInfo.ordSdCd == "1" && Number(this.tpOrdPrc) > Number(this.coinNowPrice.curPr)){
              this.tpPnlFlg = "N";
            //매수
            }else if (this.ordInfo.ordSdCd == "2" && Number(this.tpOrdPrc) < Number(this.coinNowPrice.curPr)){
              this.tpPnlFlg = "N";
            }else{
              this.tpPnlFlg = "Y";
            }
          //시장평균가
          }else if (this.ordTpType == "2"){
            //매도
            if (this.ordInfo.ordSdCd == "1" && Number(this.tpOrdPrc) > Number(this.com.markPr)){
              this.tpPnlFlg = "N";
            //매수
            }else if (this.ordInfo.ordSdCd == "2" && Number(this.tpOrdPrc) < Number(this.com.markPr)){
              this.tpPnlFlg = "N";
            }else{
              this.tpPnlFlg = "Y";
            }
          }
          
        }
      // }else{
      //   this.tpPnlFlg = "N";
      // }
    },
    //손절(SL) PNL 계산
    setSlOrdPrc(){
      //금액이 없으면
      if (this.slOrdPrc == ''){
        this.slPnlPrc = '';
        this.slPnlFlg = "Y";
      }else if (!isNaN(this.slOrdPrc)){
        this.slPnlPrc = Utils.getTpSlCalPrc(this.ordInfo, Number(this.slOrdPrc), this.coinSymbolInfo.dotCnt, this.ordInfo.lv);

        //pnl이 플러스면 저장 불가 설정
        // if (Number(this.slPnlPrc) > 0){
        //   this.slPnlFlg = "N";
        // }else {
          //종가
          if (this.ordSlType == "1"){
            //매도
            if (this.ordInfo.ordSdCd == "1" && Number(this.slOrdPrc) < Number(this.coinNowPrice.curPr)){
              this.slPnlFlg = "N";
            //매수
            //84940.12  < 84673
            }else if (this.ordInfo.ordSdCd == "2" && Number(this.slOrdPrc) > Number(this.coinNowPrice.curPr)){
              this.slPnlFlg = "N";
            }else{
              this.slPnlFlg = "Y";
            }
          //시장평균가
          }else if (this.ordSlType == "2"){
            //매도
            if (this.ordInfo.ordSdCd == "1" && Number(this.slOrdPrc) < Number(this.com.markPr)){
              this.slPnlFlg = "N";
            //매수
            }else if (this.ordInfo.ordSdCd == "2" && Number(this.slOrdPrc) > Number(this.com.markPr)){
              this.slPnlFlg = "N";
            }else{
              this.slPnlFlg = "Y";
            }
          }
        }
      // }else{
      //   this.slPnlFlg = "N";
      // }
    },
  },
};
</script>
<style lang="scss" scoped></style>
