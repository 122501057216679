<template>
  <div class="control-panel-container">
    <div class="margin-row">
      <button class="btn-line" @click="chageMarginMode">{{ marginMode == "1" ? "교차" : "격리" }}</button>
      <button class="btn-line" @click="changeLeverage">{{ coinLeverage }}x</button>
      <button class="btn-line" @click="changePositionMode">{{ cpos == "1" ? "단방향" : "헤지" }}</button>
    </div>

    <!-- 진입청산 -->
    <div class="entry-close" v-show="cpos == '2'">
      <div class="entry" :class="{ active: openCloseMode == '1' }" @click="changeOpenClose('1')">진입</div>
      <div class="close" :class="{ active: openCloseMode == '2' }" @click="changeOpenClose('2')">청산</div>
    </div>

    <div class="order-type-tab-container">
      <button class="order-type-tab" :class="{ active: stdOrdTpCd == '1' }" @click="changeOrdTpCd('1')">지정가</button>
      <button class="order-type-tab" :class="{ active: stdOrdTpCd == '2' }" @click="changeOrdTpCd('2')">시장가</button>
    </div>

    <div class="balance-row">
      <div class="simple-label">
        Avbl
        <b>{{ avbl }} USDT</b>
      </div>
      <button class="icon" @click="resetUsdt">
        금액초기화
        <img src="@/assets/icons/refresh.svg" alt="" />
      </button>
    </div>

    <div class="order-input-group">
      <div class="order-input-container" v-show="stdOrdTpCd == '1'">
        <label for="">가격</label>
        <input type="number" placeholder="가격" id="stdOrdPrc" :value="stdOrdPrc" @input="checkStdOrdPrc($event)" />
        <div class="unit">USDT</div>
      </div>
      <div class="order-input-container">
        <label for="">수량</label>
        <input type="text" placeholder="수량" :value="stdOrdQty" @input="checkStdOrdQty($event)" @focus="onFocus" />
        <CoinDropDown id="coinOrdType" :options="coinOrdTypeList" v-model="selOrdType"
          @coinOrdType="changeStdOrdType" />
      </div>
    </div>

    <Slider v-model="sliderValue" @update="onSliderUpdate" />

    <div class="sl-tp-container">
      <div class="checkbox-default">
        <input type="checkbox" id="takeProfit" name="stopProfit" v-model="showTpSlFlag" @input="setTpSlFlag" />
        <label for="takeProfit">익절/손절</label>
      </div>
      <div class="order-input-container" v-show="showTpSlFlag">
        <label for="">익절</label>
        <CoinCodeDropDown grpCd="PRCTP" v-model="stdOrdTpType" width="64" height="72" popup @input="changeTpType" />
        <input type="number" placeholder="가격" v-model="stdOrdTpPrc" />
      </div>
      <div class="order-input-container" v-show="showTpSlFlag">
        <label for="">손절</label>
        <CoinCodeDropDown grpCd="PRCTP" v-model="stdOrdSlType" width="64" height="72" popup @input="changeSlType" />
        <input type="number" placeholder="가격" v-model="stdOrdSlPrc" />
      </div>
    </div>

    <div class="buysell-btn-container">
      <div class="coin-btn-container">
        <button class="order-btn buy" @click="stdCoinSellBuy('buy')">매수/Long</button>
        <div class="simple-label" v-if="openCloseMode == '1'">
          Cost:
          <b>{{ toFixNum(buyCostUsdt, 2) }} USDT</b>
        </div>
        <div class="simple-label" v-if="openCloseMode == '2'">
          Buy:
          <b>{{ stdOrdType == 'USDT' ? toFixNum(buyCostClose, 2) : buyCostClose }} {{ stdOrdType }}</b>
        </div>
        <div class="simple-label">
          Max:
          <b>{{ stdOrdType == 'USDT' ? toFixNum(coinBuyQty, 2) : coinBuyQty }} {{ stdOrdType }}</b>
        </div>
      </div>
      <div class="coin-btn-container">
        <button class="order-btn sell" @click="stdCoinSellBuy('sell')">매도/Short</button>
        <div class="simple-label" v-if="openCloseMode == '1'">
          Cost:
          <b>{{ toFixNum(sellCostUsdt, 2) }} USDT</b>
        </div>
        <div class="simple-label" v-if="openCloseMode == '2'">
          Sell:
          <b>{{ stdOrdType == 'USDT' ? toFixNum(sellCostClose, 2) : sellCostClose }} {{ stdOrdType }}</b>
        </div>
        <div class="simple-label">
          Max:
          <b>{{ stdOrdType == 'USDT' ? toFixNum(coinSellQty, 2) : coinSellQty }} {{ stdOrdType }}</b>
        </div>
      </div>
    </div>
    <div class="info-panel">
      <div class="panel-header">
        <div class="header-title">예탁금</div>
        <button class="icon" @click="coinTrasnfer">
          자산이동
          <img src="@/assets/icons/transfer.svg" alt="" />
        </button>
      </div>
      <table class="info-list-table">
        <tbody>
          <tr>
            <th>보유USDT</th>
            <td>{{ coinDailyPlMn.usdt == undefined ? 0 : NumberComma(toFixNum(coinDailyPlMn.usdt, 4)) }}<br />
              (약 {{ coinDailyPlMn.usdtKrw }} 원)
            </td>
          </tr>
          <tr>
            <th>사용중USDT</th>
            <td>{{ coinDailyPlMn.orgUseUsdt == undefined ? 0 : NumberComma(toFixNum(coinDailyPlMn.orgUseUsdt, 4)) }}<br/>
              (약 {{ coinDailyPlMn.orgUseUsdtKrw}} 원)
            </td>
          </tr>
          <!-- <tr>
            <th>사용중USDT</th>
            <td>{{ coinDailyPlMn.useUsdt == undefined ? 0 : NumberComma(toFixNum(coinDailyPlMn.useUsdt, 4)) }}<br/>
              (약 {{ coinDailyPlMn.useUsdtKrw}} 원)
            </td>
          </tr> -->
          <!-- <tr>
            <th>동결자산</th>
            <td>0</td>
          </tr> -->
        </tbody>
      </table>
    </div>
    <!-- <div class="info-panel">
      <div class="panel-header">
        <div class="header-title">계약정보</div>
      </div>
      <table class="info-list-table">
        <tbody>
          <tr>
            <th>만료일자</th>
            <td>0</td>
          </tr>
          <tr>
            <th>자수가격</th>
            <td>0</td>
          </tr>
          <tr>
            <th>표시가격</th>
            <td>0</td>
          </tr>
          <tr>
            <th>24H 판매량</th>
            <td>0</td>
          </tr>
          <tr>
            <th>24H 거래량</th>
            <td>0</td>
          </tr>
          <tr>
            <th>계약가액</th>
            <td>0</td>
          </tr>
        </tbody>
      </table> -->
      <!-- <div class="panel-header">
        <div class="header-title">마진비율</div>
      </div>
      <table class="info-list-table">
        <tbody>
          <tr>
            <th>마진잔액</th>
            <td>{{ coinDailyPlMn.orgAbleUsdt == undefined ? 0 : NumberComma(toFixNum(coinDailyPlMn.orgAbleUsdt, 4)) }}</td>
          </tr>
          <tr>
            <th>유지보수마진</th>
            <td>0</td>
          </tr>
        </tbody>
      </table> -->
      
      <!-- <div class="panel-header">
        <div class="header-title">레버리지별 주문가능금액
        </div>
      </div>
      <table class="info-list-table">
        <thead>
          <tr>
            <th v-for="(header, index) in headers" :key="'header-' + index" style="text-align: center;">{{ header }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, rowIndex) in tableData" :key="'row-' + rowIndex">
            <td style="text-align: center;">{{ row.tier }}</td>
            <td style="text-align: center;">{{ row.maxLv }}</td>
            <td style="text-align: center;">{{ NumberComma(row.maxOrdAmt) }}</td>
          </tr>
        </tbody>
      </table>
    </div> -->



    <div class="info-panel">
      <div class="panel-header">
        <div class="header-title">레버리지별 주문가능금액</div>
      </div>
      <div class="table-outer-container">
        <table class="info-list-table leverage">
          <thead>
            <tr>
              <th v-for="(header, index) in headers" :key="'header-' + index">{{ header }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, rowIndex) in tableData" :key="'row-' + rowIndex">
              <td>{{ row.tier }}</td>
              <td>{{ row.maxLv }}</td>
              <td>{{ NumberComma(row.maxOrdAmt) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

  </div>
</template>

<script>
import Utils from '@/modules/utils';
import Slider from '@/components/coin/Slider.vue';
import CoinDropDown from '@/components/coin/CoinDropDown.vue';
import CoinCodeDropDown from '@/components/coin/CoinCodeDropDown.vue';
import EventBus from '@/modules/event-bus';


export default {
  name: 'conrolPanel',
  components: {
    Slider,
    CoinDropDown,
    CoinCodeDropDown,
  },
  props: {
    account: {
      type: Object
    },
    coinAccount: {
      type: Object
    },
    coinOrdStdPrc: {
      type: Number
    },
    coinTotalValPl: {
      type: Object
    }
  },
  computed: {
    //소켓 코인 시세
    changeCocData: function () {
      let cocData = window.$store.getters['SocketCoinPrice/getCocData'];
      return cocData;
    },
    //소켓 코인 호가
    changeCohData: function () {
      let cohData = window.$store.getters['SocketCoinPrice/getCohData']
      return cohData;
    },
    //폴링시세
    changeCocDataList: function () {
      let cocData = window.$store.getters['SocketCoinPrice/getCocDataList'];
      return cocData;
    },
    getSelectCoinSymbol: function () {
      return window.$store.getters['SocketCoinPrice/getSelectCoinSymbol'];
    },
    tikDotSz() {
      return Utils.tikDotSz(this.coinSymbol);
    },
    resultCoinOms: function () {
      return window.$store.getters['SocketCoinOms/getCompleteOrder'];
    },
    getCoinDailyPlMn: function () {
      return window.$store.getters['SocketCoinOms/getCoinDailyPlMn'];
    },
    getUsrPos: function () {
      return window.$store.getters['SocketCoinOms/getUsrPos'];
    },
    getCoinSymbolInfoList: function () {
      return window.$store.getters['Symbol/getCoinSymbolInfoList'];
    }
  },
  watch: {
    //소켓시세
    changeCocData(newPrice) {
      //console.log("changeOvcData >>>> ", newPrice)
      if (newPrice.symbol == this.coinSymbol.name) {
        this.coinNowPrice.curPr = newPrice.curPr;
        this.coinNowPrice.buyerMaker = newPrice.buyerMaker;
      }
      //
    },
    //소켓호가
    changeCohData(newPrice) {
      // apiType
      // asks
      // bids
      // hotTime
      // symbol
      if (newPrice.symbol == this.coinSymbol.name) {
        this.coinCohPrice = newPrice;
        this.setPriceCost();
        this.setCostUsdt();
      }
    },
    //폴링시세
    changeCocDataList(newPrice) {
      //
    },
    getUsrPos(usrPos) {
      this.marginMode = usrPos.marginMode;
      this.coinLeverage = usrPos.leverage;
      this.coinTier = usrPos.tierList;
      this.cpos = usrPos.cpos;
      this.buffer = usrPos.buffer;

      console.log("buffer >>> ", this.buffer);
      this.setAvbl();
      this.setLeverageMargin();
    },
    getSelectCoinSymbol(newVal) {
      if (newVal == undefined) return;

      this.coinSymbol = newVal;
      this.coinNowPrice = { symCd: newVal.name, symNo: newVal.symbolno, chGrate: 0, chPrRate: 0, curPr: 0, markPr: 0, indexPr: 0, funding: 0, CountDown: 0, highPr: 0, lowPr: 0, btcVol: 0, usdtVol: 0, usdtOpenInterest: 0, buyerMaker: false };

      this.stdOrdPrc = ''; //주문 가격
      this.stdOrdQty = ''; //주문 수량
      this.stdOrdSlPrc = '';
      this.stdOrdTpPrc = '';
      this.onSliderUpdate(0);
      this.stdOrdQty = '';
      this.openCloseMode = "1";

      for (let item of this.coinSymbolInfoList) {
        if (item.symNo == this.coinSymbol.symbolno) {
          this.coinSymbolInfo = item;
          //코인 이름 변경
          this.coinOrdTypeList[1].name = item.artcCd;

          if (this.stdOrdType != "USDT") {
            // 수량 리스트 변경
            this.stdOrdType = item.artcCd;
            this.setPriceCost();
          }
          break;
        }
      }


      this.setCostUsdt();

    },
    resultCoinOms(res) {
      if (res.result == "S") {
        this.resetUsdt();
      }
    },
    getCoinDailyPlMn: {
      handler(res) {
        this.coinDailyPlMn = res;

        //원화로 계산
        if (this.coinDailyPlMn != undefined) {
          //usdt
          if (this.coinDailyPlMn.usdt != null && this.coinDailyPlMn.usdt > 0 && this.coinDailyPlMn.usdExR != null && this.coinDailyPlMn.usdExR > 0) {
            let usdtkrw = Utils.decimalMultiply(this.coinDailyPlMn.usdt, this.coinDailyPlMn.usdExR);
            usdtkrw = this.NumberComma(Number(usdtkrw).toFixed(0));
            this.coinDailyPlMn.usdtKrw = usdtkrw
          } else {
            this.coinDailyPlMn.usdtKrw = 0;
          }

          //사용중usdt
          if (this.coinDailyPlMn.orgUseUsdt != null && this.coinDailyPlMn.orgUseUsdt > 0 && this.coinDailyPlMn.usdExR != null && this.coinDailyPlMn.usdExR > 0){
            let orgUseUsdt = Utils.decimalMultiply(this.coinDailyPlMn.orgUseUsdt, this.coinDailyPlMn.usdExR);
            orgUseUsdt = this.NumberComma(Number(orgUseUsdt).toFixed(0));
            this.coinDailyPlMn.orgUseUsdtKrw = orgUseUsdt;
          }else{
            this.coinDailyPlMn.orgUseUsdtKrw = 0;
          }

          //사용가능usdt
          if (this.coinDailyPlMn.orgAbleUsdt != null && this.coinDailyPlMn.orgAbleUsdt > 0 && this.coinDailyPlMn.usdExR != null && this.coinDailyPlMn.usdExR > 0){
            let orgAbleUsdt = Utils.decimalMultiply(this.coinDailyPlMn.orgAbleUsdt, this.coinDailyPlMn.usdExR);
            orgAbleUsdt = this.NumberComma(Number(orgAbleUsdt).toFixed(0));
            this.coinDailyPlMn.orgAbleUsdtKrw = orgAbleUsdt;
          }else{
            this.coinDailyPlMn.orgAbleUsdtKrw = 0;
          }
        }
        //usdtKrw:0, useUsdtKrw: 0

        this.setAvbl();

        //자리수 소수점 2자리까지 자름
        // if (this.coinDailyPlMn != undefined) {
        //   if (this.coinDailyPlMn.usdt != undefined) {
        //     this.coinDailyPlMn.usdt = Utils.toFixNum(this.coinDailyPlMn.usdt, 4).toFixed(4);
        //   }

        //   if (this.coinDailyPlMn.useUsdt != undefined) {
        //     this.coinDailyPlMn.orgUseUsdt = Utils.toFixNum(res.useUsdt, 4).toFixed(4);
        //     this.coinDailyPlMn.useUsdt = Utils.toFixNum(this.coinDailyPlMn.useUsdt, 4).toFixed(4);
        //   }
        // }

      },
      deep: true
    },
    coinOrdStdPrc: {
      handler(newVal) {
        //지정가
        if (this.stdOrdTpCd == "1") {
          this.stdOrdPrc = newVal;
          this.setPriceCost();
          this.setCostUsdt();
        }

      },
      deep: true
    },
    getCoinSymbolInfoList(newList) {
      this.coinSymbolInfoList = newList;
      if (newList != undefined && newList.length > 0) {
        this.coinSymbolInfo = this.coinSymbolInfoList[0];

        // this.coinBuyUsdt = 0.00;
        // this.coinSellUsdt = 0.00;
        // this.coinBuyQty = Utils.toFixInit(0, this.coinSymbolInfo.costDotCnt);
        // this.coinSellQty = Utils.toFixInit(0, this.coinSymbolInfo.costDotCnt);
        this.setPriceCost();
      }
    },
    coinTotalValPl: {
      handler(newVal) {
        //console.log("control coinTotalValPl ", newVal);
        //crossTotalValPl
        //isolratedTotalValPl

        //pnl 0보다 크면 사용가능자산은 초기값으로
        // if (newVal > 0){
        //   this.coinDailyPlMn.useUsdt = this.coinDailyPlMn.orgUseUsdt;
        //   this.coinDailyPlMn.ableUsdt = this.coinDailyPlMn.orgAbleUsdt;
        // }else{
        //   this.coinDailyPlMn.useUsdt = Utils.decimalAdd(this.coinDailyPlMn.useUsdt, newVal);
        //   this.coinDailyPlMn.ableUsdt = Utils.decimalAdd(this.coinDailyPlMn.ableUsdt, newVal);
        // }

      },
      deep: true,
    }
  },
  data() {
    return {
      marginMode: "1",
      coinLeverage: 1,
      cpos: "1",
      openCloseMode: "1",
      buffer: 1,
      stdOrdTpCd: '1',
      stdOrdPrc: '',
      stdOrdQty: '',
      stdOrdType: 'USDT',
      coinOrdTypeList: [{ cd: "1", name: 'USDT' }, { cd: "2", name: 'BTC' }],
      selOrdType: '1',
      stdOrdTypeFlag: false,
      showTpSlFlag: false,
      showTpFlag: false,
      showSlFlag: false,
      stdOrdTpType: '1',
      stdOrdSlType: '1',
      stdOrdTpPrc: '',
      stdOrdSlPrc: '',
      btnStdCoinFlag: true,
      coinNowPrice: { curPr: 0, ydiffPr: 0, open: 0, chGrate: 0, high: 0, low: 0, ydiffSign: 0 },
      coinSymbol: { name: null, base_name: null, symbolno: 0 },
      coinSymbolInfoList: [],
      coinTier: [],
      coinSymbolInfo: { symCd: 'BTCUSDT', symNo: 101, tikSz: 0.1, artcCd: 'BTC', dotCnt: 1, korNm: '비트코인', lmtOrdMaxQty: 0, lmtOrdPrcCfRat: 0, maxOpnOrdCnt: 0, minOrdAmt: 0, minTrdAmt: 0, mktOrdMaxQty: 0 },
      coinDailyPlMn: { fxPlMn: "0", fxCms: "0", nFxPlMn: "0", futPlMn: "0", futCms: "0", nFutPlMn: "0", totPlMn: "0", totNPlMn: "0", totCms: "0", niPlMn: "0", balMn: "0", hkdExR: "0", usdExR: "0", usdExHkd: "0", totalValPl: "0", totalLossCut: "0", data: null, usdt: 0, useUsdt: 0, orgUseUsdt:0, ableUsdt: 0, orgAbleUsdt: 0, usdtKrw:0, useUsdtKrw: 0, orgAbleUsdtKrw: 0, orgUseUsdtKrw:0},
      coinBuyQty: 0.00,
      coinSellQty: 0.00,
      coinBuyCostPrc: 0.00,
      coinSellCostPrc: 0.00,
      coinCohPrice: {},
      avbl: 0.00,
      buyCostUsdt: 0.00,
      sellCostUsdt: 0.00,
      sliderValue: 0,
      sellCostClose: 0.00,
      buyCostClose: 0.00,
      headers: ["티어", "레버리지", "최대금액(USDT)"], // 고정된 3개의 헤더
      tableData: [], // 행을 관리하는 배열
    }
  },
  created() {
    EventBus.$on('Layout:event:loadLayout', (layout) => {
      this.loadCustom()
    })
  },
  mounted() {

  },
  methods: {
    loadCustom() {
      this.orderSetting = window.$store.getters['Layout/getOrderSetting'];
    },
    toFixNum(value, fixNum) {
      let rtnValue = 0;
      if (value != undefined && !isNaN(value)) {
        rtnValue = Utils.toFixNum(value, fixNum).toFixed(fixNum);
      }

      return rtnValue;
    },
    onSliderUpdate(value) {
      this.sliderValue = Number(value);
      this.stdOrdQty = `${value}%`;
    },
    onFocus() {
      this.onSliderUpdate(0);
      this.stdOrdQty = '';
    },
    NumberComma(value) {
      if (value == undefined) {
        return 0;
      } else {
        return Utils.numberComma(Number(value).toFixed(4));
      }
    },
    //마진모드 변경
    chageMarginMode() {
      if (this.coinAccount == undefined || this.coinAccount.accId == undefined || this.coinAccount.accId == 0) {
        window.$alert.alert('코인 계좌가 없습니다.<br/> 관리자에게 문의 하세요.', '알림')
        return;
      }
      const params = { marginMode: this.marginMode, coinAccount: this.coinAccount, coinSymbol: this.coinSymbol };
      this.showModal('modalMarginSelect', params)
    },
    //레버리지 변경
    changeLeverage() {
      if (this.coinAccount == undefined || this.coinAccount.accId == undefined || this.coinAccount.accId == 0) {
        window.$alert.alert('코인 계좌가 없습니다.<br/> 관리자에게 문의 하세요.', '알림')
        return;
      }
      const params = { coinLeverage: this.coinLeverage, coinAccount: this.coinAccount, coinSymbol: this.coinSymbol, tierList: this.coinTier, marginMode: this.marginMode };
      this.showModal('modalLeverageSelect', params)
    },
    //포지션모드 변경
    changePositionMode() {
      if (this.coinAccount == undefined || this.coinAccount.accId == undefined || this.coinAccount.accId == 0) {
        window.$alert.alert('코인 계좌가 없습니다.<br/> 관리자에게 문의 하세요.', '알림')
        return;
      }
      const params = { cpos: this.cpos, coinAccount: this.coinAccount, coinSymbol: this.coinSymbol };
      this.showModal('modalTradeMode', params)
    },
    //햇지 진입/청산 (oepn close) 설정
    changeOpenClose(type) {
      this.openCloseMode = type;

      let message = "코인선물 주문창";
      if (type == "1") {
        message += " 진입 클릭";
      } else {
        message += " 청산 클릭";
      }
      this.sendCoinOmsLogMessage(message);
    },
    //지정가, 시장가 설정
    changeOrdTpCd(ordTpCd) {
      this.stdOrdTpCd = ordTpCd;
      this.setPriceCost();
      this.setCostUsdt();

      let message = "코인선물 주문창";
      if (ordTpCd == "1") {
        message += " 지정가 클릭";
      } else {
        message += " 시장가 클릭";
      }
      this.sendCoinOmsLogMessage(message);
    },
    //금액/수량 초기화
    resetUsdt() {
      this.stdOrdPrc = '';
      this.stdOrdQty = '';
      this.stdOrdTpPrc = '';
      this.stdOrdSlPrc = '';
      this.onSliderUpdate(0);
      this.stdOrdQty = '';
    },
    //가격/수량 변경
    changeStdOrdType(chgVal) {
      //console.log("chgVal? ", chgVal, this.selOrdType)

      this.selOrdType = chgVal.cd;
      this.stdOrdPrc = '';
      this.stdOrdType = chgVal.name;
      this.onSliderUpdate(0);
      this.stdOrdQty = '';
    },
    //익절 콤보 설정
    changeTpType(type) {
      this.stdOrdTpType = type;
    },
    //손절 콤보 설정
    changeSlType(type) {
      this.stdOrdSlType = type;
    },
    //익절/손절 체크
    setTpSlFlag(e) {
      let message = "코인선물 주문창 익절/손절";
      if (e.target.checked) {
        message += " 체크 클릭";
      } else {
        message += " 체크 해제 클릭";
      }
      this.sendCoinOmsLogMessage(message);
    },
    stdCoinSellBuy(type) {
      if (this.coinAccount == undefined || this.coinAccount.accId == undefined || this.coinAccount.accId == 0) {
        window.$alert.alert('코인 계좌가 없습니다.<br/> 관리자에게 문의 하세요.', '알림')
        return;
      }
      if (!this.stdOrdTpCd) {
        window.$alert.alert('지정가/시장가를 선택하세요.', '알림');
        return;
      } else if (this.stdOrdTpCd == "1" && (!this.stdOrdPrc || this.stdOrdPrc <= 0)) {
        window.$alert.alert('가격을 입력 해주세요.', '알림');
        return;
      }

      if (!this.stdOrdQty || this.stdOrdQty <= 0) {
        window.$alert.alert('수량을 입력 해주세요.', '알림');
        return;
      }

      // if ((type == "buy" && this.stdOrdQty == 0) || (type == "sell" && this.stdOrdQty == 0)){
      //   window.$alert.alert('주문가능 수량이 부족합니다.', '알림');
      //   return;
      // }

      //주문내역이 있으면 리턴 결과 오기전 재주문 불가
      if (!this.btnStdCoinFlag) {
        return;
      }

      //지정가 호가 공백 체크
      if (this.stdOrdTpCd == "1") {
        if (type == "sell") {
          //현재가 매도1호가 사이 주문 불가
          if (this.stdOrdPrc > this.coinNowPrice.curPr && this.stdOrdPrc < this.coinCohPrice.bids[0][0]) {
            window.$alert.alert('매도 호가 공백에는 지정가를 주문할 수 없습니다.', '알림');
            return;
          }
        } else if (type == "buy") {
          //현재가 매수1호가 사이 주문 불가
          if (this.stdOrdPrc < this.coinNowPrice.curPr && this.stdOrdPrc > this.coinCohPrice.asks[0][0]) {
            window.$alert.alert('매수 호가 공백에는 지정가를 주문할 수 없습니다.', '알림');
            return;
          }
        }
      }

      let ordSdCd = "0";
      let message = "코인선물 주문창 주문";
      if (type == "sell") {
        ordSdCd = "1";
        message += " 매도";
      } else if (type == "buy") {
        ordSdCd = "2";
        message += " 매수";
      }

      message += " " + this.coinSymbol.name;

      let ordTpCd = this.stdOrdTpCd;
      let apiCode = "";
      let stTpCd = "2";
      let ordPrc = 0;
      let ordQty = 0;
      let opnCls = "1"; //진입 청산 구분
      let minOrdQty = this.coinSymbolInfo.minTrdAmt; //db에서 읽어와야함

      //console.log("minOrdQty >>>> ", minOrdQty)

      if (this.openCloseMode != null && this.cpos == "2") { //헤지모드만 사용
        console.log("this.openCloseMode >>>> ", this.openCloseMode)
        opnCls = this.openCloseMode;
      }

      //수량에 USDT로 입력할 경우 BTC 수량으로 계산
      let stdOrdQty = this.stdOrdQty;

      let stdOrdPrc = this.stdOrdPrc;
      let sellPrc = this.stdOrdPrc;
      let buyPrc = this.stdOrdPrc;

      let cms = this.coinSymbolInfo.lmtCmsR;

      if (this.stdOrdTpCd == "2") { //시장가
        stdOrdPrc = this.coinNowPrice.curPr;
        sellPrc = this.coinNowPrice.curPr;
        buyPrc = this.coinNowPrice.curPr;
        cms = this.coinSymbolInfo.mktCmsR;
      } else {
        //시세보다 지정가가 작으면 매도가격을 매수1호가로 지정
        if (this.coinNowPrice.curPr > sellPrc) {
          sellPrc = this.coinCohPrice.bids[0][0];
        }

        //시세보다 지정가가 크면 매수가격을 매도1호가로 지정
        if (this.coinNowPrice.curPr < buyPrc) {
          buyPrc = this.coinCohPrice.asks[0][0];
        }
      }

      let maxSellQty = 0;
      let maxBuyQty = 0;

      //최대 수량
      if (opnCls == "1") { //진입일경우 
        if (this.stdOrdType == "USDT") {
          maxSellQty = Utils.getCoinOrdUsdt(this.coinDailyPlMn, this.coinLeverage, sellPrc, cms, this.coinSymbolInfo.costDotCnt, this.coinTier, this.buffer, "sell", this.cpos);
          maxBuyQty = Utils.getCoinOrdUsdt(this.coinDailyPlMn, this.coinLeverage, buyPrc, cms, this.coinSymbolInfo.costDotCnt, this.coinTier, this.buffer, "buy", this.cpos);
        } else {
          maxSellQty = Utils.getCoinOrdQty(this.coinDailyPlMn, this.coinLeverage, sellPrc, cms, this.coinSymbolInfo.costDotCnt, this.coinTier, this.buffer, "sell", this.cpos);
          maxBuyQty = Utils.getCoinOrdQty(this.coinDailyPlMn, this.coinLeverage, buyPrc, cms, this.coinSymbolInfo.costDotCnt, this.coinTier, this.buffer, "buy", this.cpos);
        }
      } else if (opnCls == "2") { //청산일경우
        let coinPosOrdSlList = window.$store.getters['SocketCoinOms/getCoinPosOrdStList'];
        if (coinPosOrdSlList == undefined || coinPosOrdSlList.length == 0) {
          maxSellQty = 0;
          maxBuyQty = 0;
        } else {
          for (let item of coinPosOrdSlList) {
            if (item.symCd == this.coinSymbol.name && item.ordType == "POS") { //포지션
              if (item.ordSdCd == "1") { //매도일경우
                maxBuyQty = item.balQty;
              } else if (item.ordSdCd == "2") {
                maxSellQty = item.balQty;
              }
            } else if (item.symCd == this.coinSymbol.name && item.ordType == "ORD") { // 미체결
              if (item.ordSdCd == "1") { //매도일경우
                maxBuyQty = maxBuyQty - item.balQty;
              } else if (item.ordSdCd == "2") {
                maxSellQty = maxSellQty - item.balQty;
              }
            }
          }
        }
      }

      if (this.stdOrdQty.indexOf("%") > -1) {
        if (this.stdOrdType == "USDT") {
          if (type == "sell") {
            stdOrdPrc = sellPrc;
            stdOrdQty = Utils.coinUsdtPercent(maxSellQty, this.stdOrdQty, this.stdOrdType);
          } else if (type == "buy") {
            stdOrdPrc = buyPrc;
            stdOrdQty = Utils.coinUsdtPercent(maxBuyQty, this.stdOrdQty, this.stdOrdType);
          }
        } else { //코인          
          if (type == "sell") {
            stdOrdPrc = sellPrc;
            stdOrdQty = Utils.coinUsdtPercent(maxSellQty, this.stdOrdQty, this.stdOrdType);
          } else if (type == "buy") {
            stdOrdPrc = buyPrc;
            stdOrdQty = Utils.coinUsdtPercent(maxBuyQty, this.stdOrdQty, this.stdOrdType);
          }
        }
      }

      if (this.stdOrdType == "USDT") {
        if (opnCls == "1") {
          ordQty = Utils.coinUsdtToBtc(stdOrdQty, stdOrdPrc, cms, this.coinSymbolInfo.costDotCnt);
        } else if (opnCls == "2") {
          ordQty = stdOrdQty;
        }
      } else {
        ordQty = stdOrdQty;
      }


      //가능금액 체크
      if (opnCls == "1") {
        if (this.stdOrdType == "USDT") {
          if ((type == "sell" && Number(maxSellQty) < Number(this.sellCostUsdt))
            || (type == "buy" && Number(maxBuyQty) < Number(this.buyCostUsdt))
          ) {
            console.log("maxSellQty sellCostUsdt maxBuyQty buyCostUsdt ", maxSellQty, this.sellCostUsdt, maxBuyQty, this.buyCostUsdt);
            window.$alert.alert('최대 주문 금액을 초과했습니다.', '알림');
            return;
          }
        } else {
          if ((type == "sell" && maxSellQty < ordQty)
            || (type == "buy" && maxBuyQty < ordQty)
          ) {
            console.log("stdOrdTpCd maxSellQty maxBuyQty ordQty ", this.stdOrdTpCd, maxSellQty, maxBuyQty, ordQty);
            window.$alert.alert('최대 주문 금액을 초과했습니다.', '알림');
            return;
          }
        }
      }

      //BTC 최소 수량 가능한지 체크
      if (ordQty < minOrdQty) {
        console.log("ordQty, minOrdQty ", stdOrdQty, minOrdQty)
        window.$alert.alert('주문가능 최소 수량을 확인 해주세요.', '알림');
        return;
      }

      if (this.stdOrdTpCd == "2") { //시장가
        apiCode = "COMS0001";
        message += "/시장가";
      } else if (this.stdOrdTpCd == "1") { //지정가
        apiCode = "COMS0001";
        ordPrc = this.stdOrdPrc;
        message += "/지정가";
      } else if (this.stdOrdTpCd == "3") { //MIT, 코인 stop은 아직 없음
        apiCode = "COMS0004";
        ordPrc = this.stdOrdPrc;
        message += "/MIT";

        //stTpCd : 스탑유형코드 // '1' 위방향 '2' 아래방향 (주문가격이 현재가보다 클경우 1 아닌경우 2)
        if (Number(this.coinNowPrice.curPr) == Number(this.stdOrdPrc)) {
          this.$notify({ group: 'notification', clean: true })

          this.$notify({
            group: 'notification',
            title: 'FAIL',
            text: '[주문거부] 현재가에 MIT주문이 불가능합니다.'
          });
          return;
        } else if (this.coinNowPrice.curPr < Number(this.stdOrdPrc)) {
          stTpCd = "1";
        }
      }

      message += "/수량(" + this.stdOrdType + ") ";
      message += this.stdOrdQty + "/가격 " + ordPrc;

      this.btnStdCoinFlag = false;

      let slTpCd1 = "";
      let slTpCd2 = "";
      let stdOrdTpType = "";
      let stdOrdSlType = "";
      if (this.showTpSlFlag) { //익절/손절 체크시
        //익절 가격이 있으면
        if (this.stdOrdTpPrc != undefined && this.stdOrdTpPrc != null && this.stdOrdTpPrc != "") {
          slTpCd1 = "1";
          // if (this.stdOrdTpType == "Last") {
          //   stdOrdTpType = "1";
          // } else if (this.stdOrdTpType == "Mark") {
          //   stdOrdTpType = "2";
          // }
          stdOrdTpType = this.stdOrdTpType;
        }
        //손절 가격이 있으면
        if (this.stdOrdTpPrc != undefined && this.stdOrdTpPrc != null && this.stdOrdTpPrc != "") {
          slTpCd2 = "2";
          // if (this.stdOrdSlType == "Last") {
          //   stdOrdSlType = "1";
          // } else if (this.stdOrdSlType == "Mark") {
          //   stdOrdSlType = "2";
          // }
          stdOrdSlType = this.stdOrdSlType;
        }

      }

      const order = {
        usrKey: Utils.getUuidv4(),
        accId: this.coinAccount.accId,
        symCd: this.coinSymbol.name,
        symNo: this.coinSymbol.symbolno,
        trdDt: '',
        ordId: 0,
        orgOrdNo: 0,
        ordSdCd: ordSdCd,
        ordTpCd: ordTpCd,
        ordPrc: ordPrc,
        ordQty: ordQty,
        repReqQty: 0,
        cxlReqQty: 0,
        stTpCd: stTpCd,
        //accPass : this.getUsrAccPass,
        lv: this.coinLeverage,
        opnCls: opnCls,
        slTpCd1: slTpCd1, //익절 여부
        prcTpCd1: stdOrdTpType, //1.Last, 2.Mark
        slPrc1: this.stdOrdTpPrc, // 가격
        slTpCd2: slTpCd2, //손절 여부
        prcTpCd2: stdOrdSlType, //1.Last, 2.Mark
        slPrc2: this.stdOrdSlPrc, // 가격
      }

      console.log("sellbuy >>>> ", ordPrc, ordQty, order)

      this.sendCoinOmsLogMessage(message);

      if (this.orderSetting != undefined && this.orderSetting.ordMessageAlert) {
        const orderData = { apiCode: apiCode, order: order, symbol: this.coinSymbol, qty: ordQty, price: Number(ordPrc), message: message };
        this.$modal.show("modalConfirmOrder", orderData);
      } else {
        this.sendCoinOmsMessage(apiCode, order);
      }

      //0.3초후 버튼 누를 수 있게 설정
      if (!this.btnStdCoinFlag) {
        setTimeout(() => {
          this.btnStdCoinFlag = true;
        }, 300);
      }
    },
    sendCoinOmsLogMessage(message) { //코인 사용자 로그 저장
      window.$store.dispatch('SocketCoinOms/setLogMsg', { message: message });
    },
    sendCoinOmsMessage(apiCode, body) { // 코인 주문 서버 전송
      if (!window.$store.getters['SocketCoinOms/getIsConnectCoinOms']) {
        window.$alert.alert('주문서버 연결이 불안정합니다.\n새로고침 후 다시 시도해주세요.');
        return;
      }

      //비밀번호 추가
      //body.accPass = this.getUsrAccPass;

      window.$store.dispatch('SocketCoinOms/sendCoinOmsMessage', { apiCode: apiCode, body: body });
    },
    //자산이동
    coinTrasnfer() {
      const params = { account: this.account, coinAccount: this.coinAccount, mobileYn: "N", selectedTab: "exchg" };
      this.showModal('modalDepositWithdraw', params);
    },
    //가격 입력시 체크
    checkStdOrdPrc(event){
      this.stdOrdPrc = Utils.inputPointCoinPrc(event, "USDT", this.coinNowPrice.curPr, this.coinSymbolInfo.costDotCnt);
      this.setStdOrdPrc();
    },
    //수량 입력시 체크
    checkStdOrdQty(event){
      this.stdOrdQty = Utils.inputPointCoinQty(event, this.stdOrdType, this.coinSymbolInfo.costDotCnt, this.coinDailyPlMn, this.coinLeverage, this.buffer);
      this.setCostUsdt();
    },
    // 가격 cost/max 계산
    setStdOrdPrc() {
      this.setPriceCost();
      this.setCostUsdt();
    },
    // 가격 수량 Cost 계산
    setPriceCost() {
      if (this.openCloseMode == "1") { //진입
        if (this.stdOrdTpCd == "1") { //지정가
          if (this.stdOrdPrc != null && this.stdOrdPrc != '') {
            let sellPrc = this.stdOrdPrc;
            let buyPrc = this.stdOrdPrc;

            //시세보다 지정가가 작으면 매도가격을 매수1호가로 지정
            if (this.coinNowPrice.curPr > sellPrc) {
              sellPrc = this.coinCohPrice.bids[0][0];
            }

            //시세보다 지정가가 크면 매수가격을 매도1호가로 지정
            if (this.coinNowPrice.curPr < buyPrc) {
              buyPrc = this.coinCohPrice.asks[0][0];
            }

            if (this.stdOrdType == "USDT") { //usdt
              this.coinBuyQty = Utils.getCoinOrdUsdt(this.coinDailyPlMn, this.coinLeverage, buyPrc, this.coinSymbolInfo.lmtCmsR, this.coinSymbolInfo.costDotCnt, this.coinTier, this.buffer, "sell", this.cpos);
              this.coinSellQty = Utils.getCoinOrdUsdt(this.coinDailyPlMn, this.coinLeverage, sellPrc, this.coinSymbolInfo.lmtCmsR, this.coinSymbolInfo.costDotCnt, this.coinTier, this.buffer, "buy", this.cpos);
            } else { //코인
              this.coinBuyQty = Utils.getCoinOrdQty(this.coinDailyPlMn, this.coinLeverage, buyPrc, this.coinSymbolInfo.lmtCmsR, this.coinSymbolInfo.costDotCnt, this.coinTier, this.buffer, "sell", this.cpos);
              this.coinSellQty = Utils.getCoinOrdQty(this.coinDailyPlMn, this.coinLeverage, sellPrc, this.coinSymbolInfo.lmtCmsR, this.coinSymbolInfo.costDotCnt, this.coinTier, this.buffer, "buy", this.cpos);
            }
          } else {
            this.coinBuyUsdt = 0.00;
            this.coinSellUsdt = 0.00;
            if (this.stdOrdType == "USDT") { //usdt
              this.coinBuyQty = Utils.toFixInit(0, 2);
              this.coinSellQty = Utils.toFixInit(0, 2);
            } else { //코인
              this.coinBuyQty = Utils.toFixInit(0, this.coinSymbolInfo.costDotCnt);
              this.coinSellQty = Utils.toFixInit(0, this.coinSymbolInfo.costDotCnt);
            }
          }
        } else { //시장가
          if (this.coinCohPrice != undefined) {
            let sellPrc = 0;
            let buyPrc = 0;
            if (this.coinCohPrice.bids != undefined) {
              sellPrc = this.coinCohPrice.bids[0][0]; //매도가격을 매수1호가로 지정
            }
            if (this.coinCohPrice.asks != undefined) {
              buyPrc = this.coinCohPrice.asks[0][0]; //매수가격을 매도1호가로 지정
            }

            if (this.stdOrdType == "USDT") { //usdt
              this.coinBuyQty = Utils.getCoinOrdUsdt(this.coinDailyPlMn, this.coinLeverage, buyPrc, this.coinSymbolInfo.mktCmsR, this.coinSymbolInfo.costDotCnt, this.coinTier, this.buffer, "buy", this.cpos);
              this.coinSellQty = Utils.getCoinOrdUsdt(this.coinDailyPlMn, this.coinLeverage, sellPrc, this.coinSymbolInfo.mktCmsR, this.coinSymbolInfo.costDotCnt, this.coinTier, this.buffer, "sell", this.cpos);
            } else { //코인
              this.coinBuyQty = Utils.getCoinOrdQty(this.coinDailyPlMn, this.coinLeverage, buyPrc, this.coinSymbolInfo.mktCmsR, this.coinSymbolInfo.costDotCnt, this.coinTier, this.buffer, "buy", this.cpos);
              this.coinSellQty = Utils.getCoinOrdQty(this.coinDailyPlMn, this.coinLeverage, sellPrc, this.coinSymbolInfo.mktCmsR, this.coinSymbolInfo.costDotCnt, this.coinTier, this.buffer, "sell", this.cpos);
            }
          } else {
            this.coinBuyUsdt = 0.00;
            this.coinSellUsdt = 0.00;
            if (this.stdOrdType == "USDT") { //usdt
              this.coinBuyQty = Utils.toFixInit(0, 2);
              this.coinSellQty = Utils.toFixInit(0, 2);
            } else { //코인
              this.coinBuyQty = Utils.toFixInit(0, this.coinSymbolInfo.costDotCnt);
              this.coinSellQty = Utils.toFixInit(0, this.coinSymbolInfo.costDotCnt);
            }
          }
        }
      } else if (this.openCloseMode == "2") { //청산
        let maxSellQty = 0;
        let maxBuyQty = 0;
        let coinPosOrdSlList = window.$store.getters['SocketCoinOms/getCoinPosOrdStList'];
        if (coinPosOrdSlList == undefined || coinPosOrdSlList.length == 0) {
          maxSellQty = 0;
          maxBuyQty = 0;
        } else {
          for (let item of coinPosOrdSlList) {
            if (item.symCd == this.coinSymbol.name && item.ordType == "POS") { //포지션
              if (item.ordSdCd == "1") { //매도일경우
                maxBuyQty = item.balQty;
              } else if (item.ordSdCd == "2") {
                maxSellQty = item.balQty;
              }
            } else if (item.symCd == this.coinSymbol.name && item.ordType == "ORD") { // 미체결
              if (item.ordSdCd == "1" && item.opnCls != "2") { //매도일경우
                maxBuyQty = maxBuyQty - item.balQty;
              } else if (item.ordSdCd == "2" && item.opnCls != "2") {
                maxSellQty = maxSellQty - item.balQty;
              }
            }
          }
        }

        if (maxBuyQty < 0) {
          maxBuyQty = 0;
        }

        if (maxSellQty < 0) {
          maxSellQty = 0;
        }

        if (this.stdOrdType == "USDT") { //usdt
          if (this.stdOrdTpCd == "1") { //지정가
            if (this.stdOrdPrc != null && this.stdOrdPrc != '') {
              let sellPrc = this.stdOrdPrc;
              let buyPrc = this.stdOrdPrc;

              //시세보다 지정가가 작으면 매도가격을 매수1호가로 지정
              if (this.coinNowPrice.curPr > sellPrc) {
                sellPrc = this.coinCohPrice.bids[0][0];
              }

              //시세보다 지정가가 크면 매수가격을 매도1호가로 지정
              if (this.coinNowPrice.curPr < buyPrc) {
                buyPrc = this.coinCohPrice.asks[0][0];
              }

              maxBuyQty = Utils.decimalMultiply(maxBuyQty, buyPrc);
              maxSellQty = Utils.decimalMultiply(maxSellQty, sellPrc);

            } else {
              maxBuyQty = 0.00;
              maxSellQty = 0.00;
            }
          } else { //시장가
            if (this.coinCohPrice != undefined) {
              let sellPrc = 0;
              let buyPrc = 0;
              if (this.coinCohPrice.bids != undefined) {
                sellPrc = this.coinCohPrice.bids[0][0]; //매도가격을 매수1호가로 지정
              }
              if (this.coinCohPrice.asks != undefined) {
                buyPrc = this.coinCohPrice.asks[0][0]; //매수가격을 매도1호가로 지정
              }



              maxBuyQty = Utils.decimalMultiply(maxBuyQty, buyPrc);
              maxSellQty = Utils.decimalMultiply(maxSellQty, sellPrc);

            } else {
              maxBuyQty = 0.00;
              maxSellQty = 0.00;
            }
          }

          this.coinBuyQty = maxBuyQty;
          this.coinSellQty = maxSellQty;
        } else { //코인
          //console.log("maxBuyQty ", maxBuyQty , this.coinSymbolInfo.costDotCnt)
          this.coinBuyQty = this.toFixNum(maxBuyQty, this.coinSymbolInfo.costDotCnt);
          this.coinSellQty = this.toFixNum(maxSellQty, this.coinSymbolInfo.costDotCnt);
        }
      }
    },
    //매도/매수 cost 구하기
    setCostUsdt() {
      if (this.openCloseMode == "1") { //진입
        let sellCostUsdt = 0.00;
        let buyCostUsdt = 0.00;
        //수량에 USDT로 입력할 경우 BTC 수량으로 계산
        const minOrdQty = this.coinSymbolInfo.minTrdAmt;

        let sellStdQty = this.stdOrdQty;
        let buyStdQty = this.stdOrdQty;

        if (this.stdOrdTpCd == "1") { //지정가
          if (this.stdOrdPrc != undefined && this.stdOrdPrc != null && this.stdOrdPrc != "" && this.stdOrdQty != undefined && this.stdOrdQty != null && this.stdOrdQty != "") {
            let sellPrc = this.stdOrdPrc;
            let buyPrc = this.stdOrdPrc;

            //시세보다 지정가가 작으면 매도가격을 매수1호가로 지정
            if (this.coinNowPrice.curPr > sellPrc) {
              sellPrc = this.coinCohPrice.bids[0][0];
            }

            //시세보다 지정가가 크면 매수가격을 매도1호가로 지정
            if (this.coinNowPrice.curPr < buyPrc) {
              buyPrc = this.coinCohPrice.asks[0][0];
            }

            if (this.stdOrdQty.indexOf("%") > -1) {
              if (this.stdOrdType == "USDT") {
                let maxSellQty = Utils.getCoinOrdUsdt(this.coinDailyPlMn, this.coinLeverage, buyPrc, this.coinSymbolInfo.lmtCmsR, this.coinSymbolInfo.costDotCnt, this.coinTier, this.buffer, "sell", this.cpos);
                let maxBuyQty = Utils.getCoinOrdUsdt(this.coinDailyPlMn, this.coinLeverage, sellPrc, this.coinSymbolInfo.lmtCmsR, this.coinSymbolInfo.costDotCnt, this.coinTier, this.buffer, "buy", this.cpos);

                sellStdQty = Utils.coinUsdtPercent(maxSellQty, this.stdOrdQty, this.stdOrdType);
                buyStdQty = Utils.coinUsdtPercent(maxBuyQty, this.stdOrdQty, this.stdOrdType);
              } else { //코인
                let maxSellQty = Utils.getCoinOrdQty(this.coinDailyPlMn, this.coinLeverage, sellPrc, this.coinSymbolInfo.lmtCmsR, this.coinSymbolInfo.costDotCnt, this.coinTier, this.buffer, "sell", this.cpos);
                let maxBuyQty = Utils.getCoinOrdQty(this.coinDailyPlMn, this.coinLeverage, buyPrc, this.coinSymbolInfo.lmtCmsR, this.coinSymbolInfo.costDotCnt, this.coinTier, this.buffer, "buy", this.cpos);

                sellStdQty = Utils.coinUsdtPercent(maxSellQty, this.stdOrdQty, this.stdOrdType);
                buyStdQty = Utils.coinUsdtPercent(maxBuyQty, this.stdOrdQty, this.stdOrdType);
              }
            }

            sellCostUsdt = Utils.coinUsdtCost(sellStdQty, sellPrc, this.stdOrdType, this.coinSymbolInfo.costDotCnt);
            buyCostUsdt = Utils.coinUsdtCost(buyStdQty, buyPrc, this.stdOrdType, this.coinSymbolInfo.costDotCnt);
          }
        } else { //시장가
          if (this.stdOrdQty != undefined && this.stdOrdQty != null && this.stdOrdQty != "") {
            let sellPrc = this.coinCohPrice.bids[0][0]; //매도가격을 매수1호가로 지정
            let buyPrc = this.coinCohPrice.asks[0][0]; //매수가격을 매도1호가로 지정

            if (this.stdOrdQty.indexOf("%") > -1) {
              if (this.stdOrdType == "USDT") {
                let maxSellQty = Utils.getCoinOrdUsdt(this.coinDailyPlMn, this.coinLeverage, buyPrc, this.coinSymbolInfo.mktCmsR, this.coinSymbolInfo.costDotCnt, this.coinTier, this.buffer, "sell", this.cpos);
                let maxBuyQty = Utils.getCoinOrdUsdt(this.coinDailyPlMn, this.coinLeverage, sellPrc, this.coinSymbolInfo.mktCmsR, this.coinSymbolInfo.costDotCnt, this.coinTier, this.buffer, "buy", this.cpos);

                sellStdQty = Utils.coinUsdtPercent(maxSellQty, this.stdOrdQty, this.stdOrdType);
                buyStdQty = Utils.coinUsdtPercent(maxBuyQty, this.stdOrdQty, this.stdOrdType);
              } else { //코인
                let maxSellQty = Utils.getCoinOrdQty(this.coinDailyPlMn, this.coinLeverage, sellPrc, this.coinSymbolInfo.mktCmsR, this.coinSymbolInfo.costDotCnt, this.coinTier, this.buffer, "sell", this.cpos);
                let maxBuyQty = Utils.getCoinOrdQty(this.coinDailyPlMn, this.coinLeverage, buyPrc, this.coinSymbolInfo.mktCmsR, this.coinSymbolInfo.costDotCnt, this.coinTier, this.buffer, "buy", this.cpos);
                sellStdQty = Utils.coinUsdtPercent(maxSellQty, this.stdOrdQty, this.stdOrdType);
                buyStdQty = Utils.coinUsdtPercent(maxBuyQty, this.stdOrdQty, this.stdOrdType);
              }
            }

            sellCostUsdt = Utils.coinUsdtCost(sellStdQty, sellPrc, this.stdOrdType, this.coinSymbolInfo.costDotCnt);
            buyCostUsdt = Utils.coinUsdtCost(buyStdQty, buyPrc, this.stdOrdType, this.coinSymbolInfo.costDotCnt);
          }
        }

        if (buyCostUsdt == 0) {
          buyCostUsdt = this.toFixNum(buyCostUsdt, 2);
        }

        if (sellCostUsdt == 0) {
          sellCostUsdt = this.toFixNum(sellCostUsdt, 2);
        }

        this.buyCostUsdt = buyCostUsdt;
        this.sellCostUsdt = sellCostUsdt;
      } else if (this.openCloseMode == "2") { //청산
        this.buyCostUsdt = 0.00;
        this.sellCostUsdt = 0.00;

        let sellCostUsdt = 0.00;
        let buyCostUsdt = 0.00;

        let sellStdQty = this.stdOrdQty;
        let buyStdQty = this.stdOrdQty;

        let maxSellQty = 0;
        let maxBuyQty = 0;
        let coinPosOrdSlList = window.$store.getters['SocketCoinOms/getCoinPosOrdStList'];
        if (coinPosOrdSlList == undefined || coinPosOrdSlList.length == 0) {
          maxSellQty = 0;
          maxBuyQty = 0;
        } else {
          for (let item of coinPosOrdSlList) {
            if (item.symCd == this.coinSymbol.name && item.ordType == "POS") { //포지션
              if (item.ordSdCd == "1") { //매도일경우
                maxBuyQty = item.balQty;
              } else if (item.ordSdCd == "2") {
                maxSellQty = item.balQty;
              }
            } else if (item.symCd == this.coinSymbol.name && item.ordType == "ORD") { // 미체결
              if (item.ordSdCd == "1") { //매도일경우
                maxBuyQty = maxBuyQty - item.balQty;
              } else if (item.ordSdCd == "2") {
                maxSellQty = maxSellQty - item.balQty;
              }
            }
          }
        }

        if (this.stdOrdTpCd == "1") { //지정가
          if (this.stdOrdPrc != undefined && this.stdOrdPrc != null && this.stdOrdPrc != "" && this.stdOrdQty != undefined && this.stdOrdQty != null && this.stdOrdQty != "") {
            let sellPrc = this.stdOrdPrc;
            let buyPrc = this.stdOrdPrc;

            //시세보다 지정가가 작으면 매도가격을 매수1호가로 지정
            if (this.coinNowPrice.curPr > sellPrc) {
              sellPrc = this.coinCohPrice.bids[0][0];
            }

            //시세보다 지정가가 크면 매수가격을 매도1호가로 지정
            if (this.coinNowPrice.curPr < buyPrc) {
              buyPrc = this.coinCohPrice.asks[0][0];
            }

            if (this.stdOrdQty.indexOf("%") > -1) {
              sellStdQty = Utils.coinUsdtPercent(maxSellQty, this.stdOrdQty, this.stdOrdType);
              buyStdQty = Utils.coinUsdtPercent(maxBuyQty, this.stdOrdQty, this.stdOrdType);

              if (this.stdOrdType == "USDT") {
                sellStdQty = Utils.decimalMultiply(sellStdQty, buyPrc);
                buyStdQty = Utils.decimalMultiply(buyStdQty, sellPrc);
              }
            }

            sellCostUsdt = sellStdQty;
            buyCostUsdt = buyStdQty;
          }
        } else { //시장가
          if (this.stdOrdQty != undefined && this.stdOrdQty != null && this.stdOrdQty != "") {
            let sellPrc = this.coinCohPrice.bids[0][0]; //매도가격을 매수1호가로 지정
            let buyPrc = this.coinCohPrice.asks[0][0]; //매수가격을 매도1호가로 지정

            if (this.stdOrdQty.indexOf("%") > -1) {
              sellStdQty = Utils.coinUsdtPercent(maxSellQty, this.stdOrdQty, this.stdOrdType);
              buyStdQty = Utils.coinUsdtPercent(maxBuyQty, this.stdOrdQty, this.stdOrdType);

              if (this.stdOrdType == "USDT") {
                sellStdQty = Utils.decimalMultiply(sellStdQty, buyPrc);
                buyStdQty = Utils.decimalMultiply(buyStdQty, sellPrc);
              }
            }

            sellCostUsdt = sellStdQty;
            buyCostUsdt = buyStdQty;
          }
        }

        if (this.stdOrdType != "USDT") {
          sellCostUsdt = this.toFixNum(sellCostUsdt, this.coinSymbolInfo.costDotCnt);
          buyCostUsdt = this.toFixNum(buyCostUsdt, this.coinSymbolInfo.costDotCnt);
        }

        this.sellCostClose = sellCostUsdt;
        this.buyCostClose = buyCostUsdt;
      }
    },
    //Avbl 금액 계산
    setAvbl(){
      if (this.coinDailyPlMn.orgAbleUsdt != undefined) {
        // 사용금액이 더 클경우 0
        if (this.coinDailyPlMn.usdt < this.coinDailyPlMn.orgUseUsdt){
          this.avbl = 0;
        } else {
          //배율에 따른 제한 금액
          let tier = null;
          if (this.coinTier != undefined && this.coinTier.length > 0) {
            for (let item of this.coinTier) {
              if (item.maxLv >= this.coinLeverage) {
                tier = item;
              }
            }
          }

          //배율별 금액, 보유자산이 없으면
          if (tier == null || this.coinDailyPlMn == undefined || this.coinDailyPlMn == null) {
            this.avbl = 0;
          }else{
            this.avbl = Utils.getAvbl(this.coinDailyPlMn, this.coinSymbolInfo.costDotCnt, this.coinTier, this.coinLeverage, this.buffer);
          }

        }
      }

      this.avbl = Utils.toFixNum(this.avbl, 2).toFixed(2);
    },
    setLeverageMargin() {
      this.tableData = this.coinTier.map(item => ({
        tier: item.tier,
        maxLv: item.maxLv,
        maxOrdAmt: item.maxOrdAmt
      }));
    },
    showModal(name, params) {
      this.$modal.show(name, params);
      let message = "";
      if (name == "modalDepositWithdraw") {
        message = "자산이동 클릭";
      } else if (name == "modalMarginSelect") {
        message = "마진모드 클릭";
      } else if (name == "modalLeverageSelect") {
        message = "레버리지 클릭";
      } else if (name == "modalTradeMode") {
        message = "포지션모드 클릭";
      }

      this.sendCoinOmsLogMessage(message);
      console.log(name);
    },
  },

}
</script>