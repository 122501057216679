<template>
  <div style="width: 100%; height: 100%">
    <ag-grid-vue style="width: 100%; height: 100%" class="ag-theme-wts" :columnDefs="grid.columnDefs"
      :rowData="grid.rowData" :headerHeight="rowHeight" :rowHeight="rowHeight" @grid-ready="onGridReady" />
  </div>
</template>

<script>
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
import { AgGridVue } from "ag-grid-vue"; // Vue Grid Logic
import PaginationComp from '@/components/agTables/PaginationComp.vue';
import AgBtn from '@/components/agTables/AgBtn.vue';
import gridUtils from '@/modules/grid-utils';
import { stringCount } from '@/modules/helper';
import { buySellType, cellClassRulesMinus } from '@/modules/grid-utils.js';
import Utils from '@/modules/utils';
import bigDecimal from 'js-big-decimal';

export default {
  name: "agCoinMemberPositionTablePartner",
  components: {
    AgGridVue,
    PaginationComp,
    AgBtn,
  },
  props: {
    setGridSearch: {
      type: Object
    },
    symbolList: {
      type: Array
    },
  },
  computed: {
    //소켓시세
    changeCocData: function () {
      let cocData = window.$store.getters['SocketCoinPrice/getCocData']
      return cocData;
    },
    //폴링시세
    changeCocDataList: function () {
      let cocData = window.$store.getters['SocketCoinPrice/getCocDataList']
      return cocData;
    },
  },
  watch: {
    //소켓시세
    changeCocData(newPrice) {
      this.cocMap.set(newPrice.symbol, newPrice);
      this.setGridPrice(); //현재가, 평가손익 새로고침
    },
    //폴링시세
    changeCocDataList(newPrice) {
      if (newPrice != null && newPrice.length > 0) {
        newPrice.forEach(data => {

          this.cocMap.set(data.coinSymbol, data);
          this.setGridPrice(); //현재가, 평가손익 새로고침
        })
      }
    },
    setGridSearch: {
      handler(newVal) {
        this.gridSearch = newVal;
        this.search();
      },
      deep: true
    }
  },
  data() {
    return {
      cocMap: new Map(),
      currencyInfo: { currency: "WON" },
      priceReqType: "SCKTP02",
      grid: {
        columnDefs: [
          {
            headerName: "ID",
            field: "usrId",
            minWidth: 50,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
            sortable: true,
          },
          {
            headerName: "이름",
            field: "usrNm",
            minWidth: 50,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
            sortable: true,
          },

          {
            headerName: "종목",
            field: "symCd",
            minWidth: 50,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
            sortable: true,
          },
          {
            headerName: "구분",
            field: "ordSdNm",
            minWidth: 50,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
            sortable: true,
            cellClassRules: buySellType,
          },
          {
            headerName: "수량",
            field: "balQty",
            minWidth: 50,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
            sortable: true,
            valueFormatter: gridUtils.numberComma
          },
          {
            headerName: "평균단가",
            field: "execPrc",
            minWidth: 50,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
            sortable: true,
            valueFormatter: (d) => {
              let val = d.value;
              if (d.data.symNo == 2) { //항셍
                if (!Number.isInteger(val)) {
                  val = Number(val).toFixed(1);
                }
                return val;
              } else {
                let symbol = null;
                this.symbolList.forEach(item => {
                  if (item.symbolno == d.data.symNo) {
                    symbol = item;
                    return;
                  }
                });
                return val.toFixed(this.tikDotSz(symbol));
              }
            },
          },
          {
            headerName: "현재가",
            field: "nowPrice",
            minWidth: 50,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
            sortable: true,
            valueFormatter: (d) => {
              let val = d.value;
              if (d.data.symNo == 2) { //항셍
                if (!Number.isInteger(val)) {
                  val = Number(val).toFixed(1);
                }
                return val;
              } else {
                let symbol = null;
                this.symbolList.forEach(item => {
                  if (item.symbolno == d.data.symNo) {
                    symbol = item;
                    return;
                  }
                });
                //return val.toFixed(this.tikDotSz(symbol));
                return
              }
            },
          },
          {
            headerName: "평가손익",
            field: "valPl",
            minWidth: 50,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
            sortable: true,
            cellClassRules: cellClassRulesMinus,
            valueFormatter: gridUtils.numberComma,
          },
          /*  { 
              headerName: "관리자취소",
              field: "actionName",
              minWidth: 50,
              flex: 1,
              cellStyle: {
                justifyContent: 'center'
              },
              headerClass: "center-align-header",
              suppressMovable: true,
              cellRenderer: 'AgBtn',
              cellRendererParams: { actionName: '청산' },
            },*/
        ],
        rowData: [],
        autoSizeStrategy: null,
        rowHeight: '',
      },
      gridApi: null,
      gridSearch: null,
      memberValPlList: [],
    }
  },
  created() {
    this.isMobile();
  },
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
    },
    isMobile() {
      if (window.innerWidth <= 1200) {
        return this.rowHeight = 40;
      } else {
        return this.rowHeight = 30;
      }
    },
    async search() {
      this.grid.rowData = [];
      if (this.gridSearch.usrId == "") {
        return;
      }

      const response = await this.$lhttp.post('/api/coin/partner/usliveexeclist', this.gridSearch);

      const cocData = await window.$http.get('apisisecoin/getCocData', { params: {} });
      if (response && response.data) {
        let symlist = [];
        if (response.data.length > 0) {
          this.grid.rowData = [];
          this.memberValPlList = [];
          response.data.forEach(data => {
            //실시간 
            data.valPl = 0; //실시간손익
            data.totValPl = 0; //평가담보금
            data.nowPrice = 0; //현재시세
            data.actionName = '청산';
            for (let coc in cocData.data.cocDataList) {
              if (coc == data.symCd) {
                data.nowPrice = cocData.data.cocDataList[coc].curPr;
              }
              if (this.cocMap.get(coc) == undefined || this.cocMap.get(coc).curPr == undefined) {
                this.cocMap.set(coc, cocData.data.cocDataList[coc]);
              }
            }
            this.grid.rowData.push(data);
            symlist.push(data.symCd)
            let chkYn = true;
            for (let member of this.memberValPlList) {
              if (member.usrId == data.usrId) {
                chkYn = false;
              }
            }

            if (chkYn) {
              const memData = { usrId: data.usrId, valPl: 0 };
              this.memberValPlList.push(memData);
            }
          })
        } else {
          this.grid.rowData = [];
          this.memberValPlList = [];
        }
        this.connectPrice(symlist)
        this.setGridPrice(); //실시간 계산
      }
    },
    setGridPrice() {
      let totData = { totalValPl: 0, memberValPlList: [] };
      if (this.grid.rowData != null && this.grid.rowData.length > 0) {
        let totalValPl = 0;

        for (let memData of this.memberValPlList) {
          memData.valPl = 0;
        }
        //이부분은 계정의 금액이므로 그리드 조회나 초기에 따로 조회필요(환율정보사용)
        this.grid.rowData.forEach(data => {
          if (data.balQty > 0 && this.symbolList.length > 0) {
            const cocData = this.cocMap.get(data.symCd);

            //let res = this.symbolCalPrc(data, this.ovcMap.get(data.symCd), this.symbolList, usdExr, usdExHkd, hkdExR, data.lvTpCd, this.currencyInfo.currency);
            // 계산 공통사용변경
            // data - 그리드 1개 row data
            // ovcMap
            // symbolList
            // usdExr 환율
            // usdExHkd 홍콩달러환율
            // hkdExR 홍콩환율
            // lvTpCd 레버리지여부
            // currency 원/달러 구분 WON
            // return {data, }
            data.trdUnt = data.tikVal;
            let res = Utils.symbolCalPrc(data, cocData, this.symbolList, data.usd, data.usdHkd, data.hkd, data.lvTpCd, this.currencyInfo.currency);
            //data = res.data; //데이터가 갱신 안되면 주석해제
            totalValPl += Number(res.valProLoss);

            for (let memData of this.memberValPlList) {
              if (data.usrId == memData.usrId) {
                memData.valPl += Number(data.valPl);
              }
            }

            let symbolData = null;
            let pricescale = 1;
            this.symbolList.forEach(item => {
              if (item.name == data.symCd) {
                symbolData = item;
                if (item.pricescale > 0) {
                  pricescale = item.pricescale;
                }
                return;
              }
            });

            if (cocData != undefined) {
              //현재가
              data.nowPrice = Number(cocData.curPr).toFixed(this.tikDotSz(symbolData));
            }

            //평가손익 계산
            let val1 = 0;
            let val2 = 0;
            let valProLoss = 0;
            if (cocData.curPr == 0 || data.ordSdCd == 0) {
              valProLoss = 0;
            } else {
              if (data.ordSdCd == "1") { //매도

                val1 = bigDecimal.multiply(data.execPrc, data.balQty);
                val2 = bigDecimal.multiply(cocData.curPr, data.balQty);
                //val1 = Math.trunc((data.execPrc.toFixed(this.tikDotSz(symbolData)) * pricescale) * data.balQty);
                //val2 = Math.trunc((cocData.curPr * pricescale) * data.balQty);
              } else if (data.ordSdCd == "2") { //매수
                val1 = bigDecimal.multiply(cocData.curPr, data.balQty);
                val2 = bigDecimal.multiply(data.execPrc, data.balQty);
                // val1 = Math.trunc((cocData.curPr * pricescale) * data.balQty);
                // val2 = Math.trunc(((data.execPrc.toFixed(this.tikDotSz(symbolData)) * pricescale) * data.balQty));
              }

              //     //소수점 계산에서 오차가 발생 ex> 0.0001 + 0.0009 = 0.000999 같은경우
              //     //1. execPrc의 평균가를 toFixed로 종목별 자릿수 만큼 반올림
              //     //2. pricescale 곱하여 소수점없이 계산
              //     //3. val1,val2 계산된값에 trunc을 하여 소수점을 버림
              //     //4. 최종 가격에서 pricescale로 나눠서 정상 가격을 표시

              //     //달러로 계산
              let pnl = 0;
              pnl = bigDecimal.subtract(val1, val2);
              pnl = bigDecimal.multiply(pnl, '100');
              pnl = Math.floor(pnl);
              pnl = bigDecimal.divide(pnl, '100');

              if (isNaN(pnl)) pnl = 0;

              totalValPl += Number(pnl);
              data.valPl = pnl;
            }

            for (let memData of this.memberValPlList) {
              if (data.usrId == memData.usrId) {
                memData.valPl += Number(data.valPl);
              }
            }

            //   return;
            // }
          }
        });
        this.gridApi.refreshCells(); //셀 새로고침
        totData.totalValPl = totalValPl.toFixed(2); //실시간 평가금액
        totData.memberValPlList = this.memberValPlList; //실시간 평가금액

        //필요한정보 추가 후 상위페이지로 전달
      }

      this.$emit("updateTotalValPl", totData);
    },
    connectPrice(symbolList) {
      if (this.priceReqType == "SCKTP01") {
        window.$store.dispatch('SocketCoinPrice/setConectCoinPrice', { sessionStorage: this.$sessionStorage.get('xDkToken') });
      } else if (this.priceReqType == "SCKTP02" || this.priceReqType == "SCKTP03") {
        window.$store.dispatch('SocketCoinPrice/setPollingCoinPrice', { symbolList: symbolList });
      }
    },
    tikVal(symbol) {
      if (symbol == null) {
        return 0;
      }
      return (symbol.minmov / symbol.pricescale);
    },
    tikDotSz(symbol) {
      if (symbol == null) {
        return 0;
      }
      return stringCount(symbol.pricescale);
    },
  },
};

</script>

<style lang="scss" scoped>
@import '@/assets/scss/agGridStyles.scss';
</style>
