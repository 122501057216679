<template>
  <div style="width: 100%; height: 100%">
    <ag-grid-vue style="width: 100%; height: 100%" class="ag-theme-wts" :columnDefs="grid.columnDefs"
      :rowData="grid.rowData" :headerHeight="rowHeight" :rowHeight="rowHeight" />
  </div>
</template>

<script>
// import { ref } from 'vue';
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
// import "ag-grid-community/styles/ag-theme-quartz.css"; // Theme
import { AgGridVue } from "ag-grid-vue"; // Vue Grid Logic
// eslint-disable-next-line no-unused-vars
export default {
  name: "agCoinLogTablePartner",
  components: {
    AgGridVue,
  },
  props: {
    account: {
      type: Object
    },
    setGridSearch: {
      type: Object
    }
  },
  watch: {
    setGridSearch: {
      handler(newVal) {
        this.gridSearch = newVal;
        this.search();
      },
      deep: true
    }
  },
  data() {
    return {
      grid: {
        columnDefs: [
          {
            headerName: "일시",
            field: "msgTm",
            minWidth: 200,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
            sortable: true,
          },
          {
            headerName: "종목코드",
            field: "symCd",
            minWidth: 80,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
            sortable: true,
          },
          {
            headerName: "내용",
            field: "msg",
            minWidth: 250,
            flex: 5,
            cellStyle: {
              // justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
            sortable: true,
          },
          // { 
          // 	headerName: "비고",
          // 	field: "비고",
          // 	minWidth: 80,
          // 	flex: 1,
          // 	cellStyle: {
          // 		justifyContent: 'center'
          // 	},
          // 	headerClass: "center-align-header",
          // 	suppressMovable: true,
          // },
        ],
        rowData: [],
        autoSizeStrategy: null,
      },
      rowHeight: '',
      gridApi: null,
    };
  },
  created() {
    this.grid.rowData = [];
    this.isMobile();
  },
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
    },
    isMobile() {
      if (window.innerWidth <= 1200) {
        return this.rowHeight = 40;
      } else {
        return this.rowHeight = 30;
      }
    },
    async search() {
      this.grid.rowData = [];
      //url 변경필요
      if (this.gridSearch.usrId == "") { return }
      const response = await this.$http.post('/api/coin/partner/usrloglist', { usrId: this.gridSearch.setusrId, usrSeq: this.gridSearch.setusrSeq, stdt: this.gridSearch.fromDate, eddt: this.gridSearch.toDate, tp: "0", cpnId: this.gridSearch.cpnId })

      if (response && response.data) {
        this.grid.rowData = response.data
      }
    },
  },
};


</script>

<style lang="scss" scoped>
@import '@/assets/scss/agGridStyles.scss';
</style>