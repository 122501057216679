<template>
  <div class="temp">
    <div class="desktop-main" :class="selectedTheme">
      <Header
       :account="account"
       :setTotalValPl="totalValPl"
       :setSymbolPlMn="symbolPlMn"
       @updateDailyPlMn="updateDailyPlMn"
      />
      <main>
        <div class="left" v-if="getLoginUserInfo != undefined && getLoginUserInfo.authId != undefined">
          <button class="panel-btn" @click="setShowPanel(true)" v-show="!showPanel">
            축소
            <img src="@/assets/icons/chevron-left.svg" alt="" />
          </button>
          <div class="upper">
            <div class="instrument-row">
              <button v-if="futUseYn == 'Y'" class="btn-line" value="korea" :class="{ active: showInstruments === 'korea' }" @click="checkInstruments">국내선물</button>
              <button v-if="futUseYn == 'Y'" class="btn-line" value="global" :class="{ active: showInstruments === 'global' }" @click="checkInstruments">해외선물</button>
              <button v-if="coinUseYn == 'Y'" class="btn-line" value="coin" :class="{ active: showInstruments === 'coin' }" @click="checkInstruments">코인선물</button>
              <input v-if="showInstruments === 'korea' || showInstruments === 'global'" type="text" v-model="symbol.name" class="symbol" readonly />
              <input v-if="showInstruments === 'korea' || showInstruments === 'global'" type="text" :value="(symbol.description != undefined ? symbol.description:'') + (symbol.secMt != undefined ? ' '+symbol.secMt:'')" class="symbol-name" readonly />
              <button class="btn-primary blue" @click="popupOrder" v-if="this.getLoginUserInfo != undefined && this.getLoginUserInfo.popupCnt != undefined && this.getLoginUserInfo.popupCnt > 0 ">팝업주문</button>
              <!-- <button class="btn-primary blue" @click="sendEvtToChild">호출테스트</button> -->
              <button class="btn-primary blue" @click="getServerStat" v-if="getLoginUserInfo != undefined && getLoginUserInfo.usrId != undefined && getLoginUserInfo.cpnId == 1 && (getLoginUserInfo.usrId == 'test16' || getLoginUserInfo.usrId == 'lee')">서버상태</button>
              <!-- <button class="btn-primary blue" @click="setServerExec" v-if="getLoginUserInfo != undefined && getLoginUserInfo.usrId != undefined && (getLoginUserInfo.usrId == 'test16' || getLoginUserInfo.usrId == 'lee')">장마감</button> -->
            </div>
            <div class="white-label-table-container" v-show="futUseYn == 'Y' && (showInstruments === 'korea' || showInstruments === 'global')">
              <table class="white-label-table">
                <tbody>
                  <tr>
                    <th>평가손익</th>
                    <td><span :class="{ 'color-red' : dailyPlMn.totalValPl > 0, 'color-blue' : dailyPlMn.totalValPl < 0 }">{{ thousand(dailyPlMn.totalValPl) }}</span></td>
                    <th>실현손익</th>
                    <td><span :class="{ 'color-red' : dailyPlMn.totNPlMn > 0, 'color-blue' : dailyPlMn.totNPlMn < 0 }">{{ thousand(dailyPlMn.totNPlMn) }}</span></td>
                    <th>평가담보</th>
                    <td><span :class="{ 'color-red' : dailyPlMn.balMn > 0, 'color-blue' : dailyPlMn.balMn < 0 }">{{ thousand(Number(dailyPlMn.balMn) + Number(dailyPlMn.totalValPl)) }}</span></td>
                    <th>로스컷</th>
                    <td>{{ thousand(dailyPlMn.totalLossCut) }}</td>
                  </tr>
                  <tr>
                    <th>잔고</th>
                    <td>{{ thousand(symbolPlMn.balQty) }}</td>
                    <th>평균단가</th>
                    <td>{{ thousand(symbolPlMn.execPrc) }}</td>
                    <th>종목평가</th>
                    <td><span :class="{ 'color-red' : symbolPlMn.valPl > 0, 'color-blue' : symbolPlMn.valPl < 0 }">{{ thousand(symbolPlMn.valPl) }}</span></td>
                    <th>종목실현</th>
                    <td><span :class="{ 'color-red' : symbolPlMn.plMn > 0, 'color-blue' : symbolPlMn.plMn < 0 }">{{ thousand(symbolPlMn.plMn) }}</span></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="stop-profit-row" v-if="futUseYn == 'Y' && (showInstruments === 'korea' || showInstruments === 'global')">
              <div class="white-label-table-container">
                <table class="white-label-table">
                  <tbody>
                    <tr>
                      <th><span class="color-blue">매도</span></th>
                      <td><span class="color-blue">{{ thousand(sellAbleQty) }}</span></td>
                      <th><span class="color-red">매수</span></th>
                      <td><span class="color-red">{{ thousand(buyAbleQty) }}</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="inner">
                <div class="checkbox-default">
                  <input type="checkbox" id="stopProfit" name="stopProfit" v-bind:checked="slStockVal2 == true" @click="sellSlStock" />
                  <label for="stopProfit">손절</label>
                </div>
                <CounterComp :disabled="slStockVal2" :maxCnt="200" :maxLength=3 @childEvent="setSellSlTick" :value="sellSlTick" />
              </div>
              <div class="inner">
                <div class="checkbox-default">
                  <input type="checkbox" id="takeProfit" name="takeProfit" v-bind:checked="slStockVal1 == true" @click="buySlStock" />
                  <label for="takeProfit">익절</label>
                </div>
                <CounterComp :disabled="slStockVal1" :maxCnt="200" :maxLength=3 @childEvent="setBuySlTick" :value="buySlTick" />
              </div>
              <div class="inner-leverage" v-if="getLoginUserInfo.lvTpCd != undefined && getLoginUserInfo.lvTpCd == 'Y'">
                <div class="white-label-table-leverage">
                  <table class="white-label-table">
                    <tbody>
                      <tr>
                        <th>레버리지</th>
                        <td>
                          <!-- <CodeDropDown width="63" cdHeight="26" grpCd="LVTP" v-model="leverage" height="200" popup @input="selectLeverage" /> -->
                          <BaseDropdown id="leverageCd" cdHeight="26" :options="leverageList" v-model="leverage" @leverageCd="selectLeverage" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div class="dom-top" v-if="futUseYn == 'Y' && (showInstruments === 'korea' || showInstruments === 'global')">
              <button class="btn-line blue" @click="sellMarket">시장가 매도</button>
              <div class="inner">
                <button class="btn-line" @click="clearOrderData('all')">전종목 청산</button>
                <button class="btn-line" @click="clearOrderData('symbol')">현종목 청산</button>
              </div>
              <button class="btn-line red" @click="buyMarket">시장가 매수</button>
            </div>
          </div>

          <div class="dom-container" v-if="futUseYn == 'Y' && (showInstruments === 'korea' || showInstruments === 'global')" >
            <DomGridClick
              :account="account"
              :selectOrderData="selectOrderData"
              :ordClick="ordClick"
              :avgPrice="avgPrice"
              :rStock="rStock"
              :slStockVal1="slStockVal1"
              :slStockVal2="slStockVal2"
              :buySlTick="buySlTick"
              :sellSlTick="sellSlTick"
            />
            <!-- :setSlStockVal="setSlStockVal"
              :symbol="symbol"
            :slStockVal1="slStockVal1"
            :slStockVal2="slStockVal2"
            :sellSlTick="sellSlTick"
            :buySlTick="buySlTick"
            :selectOrderData="selectOrderData"
            :sendOrderData="sendOrderData"
            :sellAbleQty="sellAbleQty"
            :buyAbleQty="buyAbleQty"
            :clickGridCenter="clickGridCenter"
            :selectLoadCustom="selectLoadCustom"
            :account="account"
            :avgPrice="avgPrice"
            @completeOrder="completeOrder"
            @updateSlStock="updateSlStock"
            @PanelComp5SelectOrder="PanelComp5SelectOrder" -->
          </div>
          <!-- <div class="dom-container" v-show="coinUseYn == 'Y' && showInstruments === 'coin'" >
            <CoinGrid :coinAccount="coinAccount" @updateCoinPrice="updateCoinPrice" />
          </div> -->
          <div class="lower">
            <div class="ag-container" v-if="futUseYn == 'Y' && (showInstruments === 'korea' || showInstruments === 'global')">
              <AgPositionTable
              :account="account"
              @updateTotalValPl="updateTotalValPl"
              @updateSubscribeOnPrice="updateRtPosSymbol"
              />
            </div>
          </div>
        </div>

        <div class="right" v-show="showPanel && getLoginUserInfo != undefined && getLoginUserInfo.authId != undefined">
          <button class="panel-btn" @click="setShowPanel(false)">
            <img src="@/assets/icons/chevron-right.svg" alt="" />
            확장
          </button>
          <div class="order-row" v-show="showInstruments === 'korea' || showInstruments === 'global'">
            <div class="label">주문수량</div>
            <div class="inner">
              <CounterComp :maxCnt="200" :maxLength=3 @childEvent="setClickOrd" v-bind:value="ordClick" />
              <button class="btn-line" @click="setOrdClick(clickSetList[0])">{{ clickSetList[0] }}</button>
              <button class="btn-line" @click="setOrdClick(clickSetList[1])">{{ clickSetList[1] }}</button>
              <button class="btn-line" @click="setOrdClick(clickSetList[2])">{{ clickSetList[2] }}</button>
              <button class="btn-line" @click="setOrdClick(clickSetList[3])">{{ clickSetList[3] }}</button>
              <button class="btn-line" @click="setOrdClick(clickSetList[4])">{{ clickSetList[4] }}</button>
            </div>
          </div>
          <div class="white-label-table-container right-panel">
            <table class="white-label-table">
              <tbody>
                <tr>
                  <th>현재가</th>
                  <td><span :class="{ 'color-red' : nowPrice.ydiffSign != undefined && nowPrice.ydiffSign == '2' , 'color-blue' : nowPrice.ydiffSign != undefined && nowPrice.ydiffSign == '5' }">{{ nowPrice.curPr }}</span></td>
                  <th>대비</th>
                  <td><span :class="{ 'color-red' : nowPrice.ydiffSign != undefined && nowPrice.ydiffSign == '2' , 'color-blue' : nowPrice.ydiffSign != undefined && nowPrice.ydiffSign == '5' }">{{ nowPrice.ydiffPr }}</span></td>
                </tr>
                <tr>
                  <th>시가</th>
                  <td>{{ nowPrice.open }}</td>
                  <th>%</th>
                  <td><span :class="{ 'color-red' : nowPrice.ydiffSign != undefined && nowPrice.ydiffSign == '2' , 'color-blue' : nowPrice.ydiffSign != undefined && nowPrice.ydiffSign == '5' }">{{ nowPrice.chGrate }}</span></td>
                </tr>
                <tr>
                  <th>고가</th>
                  <td>{{ nowPrice.high }}</td>
                  <th>환율</th>
                  <td>{{ symbol.symbolno == 2 ? thousand(dailyPlMn.hkdExR):thousand(dailyPlMn.usdExR)  }}</td>
                </tr>
                <tr>
                  <th>저가</th>
                  <td>{{ nowPrice.low }}</td>
                  <th>틱가치</th>
                  <td>{{ thousand(symbolTikPrice != undefined && Number(symbolTikPrice) > 0 && getLoginUserInfo.lvTpCd != undefined && getLoginUserInfo.lvTpCd == 'Y' ? symbolTikPrice / leverage : symbolTikPrice) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="order-standard-container" v-show="stdOrdShow == true && (showInstruments === 'korea' || showInstruments === 'global')">
            <div class="order-standard short">
              <div class="row">
                <h3 class="label">종목명</h3>
                <h3 class="value">{{ symbol.description }}</h3>
              </div>
              <div class="row">
                <h3 class="label">유형</h3>
                <div class="order-type">
                  <div class="radio-default">
                    <input type="radio" id="market" name="orderType" value="1" v-model="ordStdType" />
                    <label for="market">시장가</label>
                  </div>
                  <div class="radio-default">
                    <input type="radio" id="limit" name="orderType" value="2" v-model="ordStdType" />
                    <label for="limit">지정가</label>
                  </div>
                  <div class="radio-default">
                    <input type="radio" id="mit" name="orderType" value="3" v-model="ordStdType" />
                    <label for="mit">MIT</label>
                  </div>
                </div>
              </div>

              <div class="row">
                <h3 class="label">수량</h3>
                <CounterComp full :maxCnt="200" :maxLength=3 @childEvent="setStdOrdQty" :value="ordStdQty" />
              </div>

              <div class="row">
                <h3 class="label">가격</h3>
                <OrderInput modal :symbol="symbol" :nowPrice="Number(nowPrice.curPr)" :value="Number(ordStdPrc)" @updateOrdPrc="setOrdStdPrc" />
              </div>
              <div class="row end">
                <button class="btn-line" @click="stdReset()">초기화</button>
                <button class="btn-line blue" @click="stdSellBuy('sell')">매도</button>
                <button class="btn-line red" @click="stdSellBuy('buy')">매수</button>
              </div>
            </div>
          </div>
          <div class="instruments-table-container" v-if="showInstruments === 'korea'">
            <div class="ag-container">
              <!-- <AgOptionsTable/> -->
              <AgFuturesTable/><!-- 국내선물 종목 만들기전까지 해외선물 계속 출력-->
            </div>
          </div>
          <div class="instruments-table-container" v-if="showInstruments === 'global'">
            <div class="ag-container">
              <AgFuturesTable/>
            </div>
          </div>
          <div class="filled-table-container">
            <div class="ag-container">
              <AgFilledTable></AgFilledTable>
            </div>
          </div>
        </div>
        <div class="desktop-chart-container">
          <div class="chart-panel" v-show="showInstruments === 'korea' || showInstruments === 'global'">
            <chartBox :symbol="chartSymbol" :symbolList="chartSymbolList" :height="height" />
          </div>
        </div>
      </main>
      <ModalDepositWithdraw
       v-on:depositWithdraw-complete="completeDepositWithdraw"
      />
      <ModalDepositWithdrawTable />
      <ModalTransactionTable />
      <ModalProfitLossTable />
      <ModalSettings
       v-on:order-setting-complete="completeOrderSetting"
       />
      <ModalNotice />
      <ModalNoticeDetail />
      <ModalLogout />
      <ModalBasic />
    </div>
  </div>
</template>

<script>
import CounterComp from '@/components/CounterComp.vue';
import OrderInput from '@/components/OrderInput.vue';

import ModalSettings from '@/components/ModalSettings.vue';
import ModalDepositWithdraw from '@/components/ModalDepositWithdraw.vue';
import ModalDepositWithdrawTable from '@/components/ModalDepositWithdrawTable.vue';
import ModalTransactionTable from '@/components/ModalTransactionTable.vue';
import ModalProfitLossTable from '@/components/ModalProfitLossTable.vue';
import DomGridClick from '@/components/DomGridClick.vue';
import ChartBox from '@/components/ChartBox';
import ModalBasic from '@/components/ModalBasic.vue';

import ModalNotice from '@/components/ModalNotice.vue';
import ModalNoticeDetail from '@/components/ModalNoticeDetail.vue';
import AgFilledTable from '@/components/agTables/AgFilledTable.vue';
import AgPositionTable from '@/components/agTables/AgPositionTable.vue';
import Header from '@/components/Header.vue';
import ModalLogout from '@/components/ModalLogout.vue';
import AgFuturesTable from '@/components/agTables/AgFuturesTable.vue';
import AgOptionsTable from '@/components/agTables/AgOptionsTable.vue';
import EventBus from '@/modules/event-bus';
import { stringCount } from '@/modules/helper';
import Utils from '@/modules/utils';
import Const from '@/const';
import { bus } from '@/main';
import CodeDropDown from '@/components/CodeDropDown.vue'
import BaseDropdown from '@/components/BaseDropdown.vue'

export default {
  name: 'WhiteLabelDesktopPage',
  components: {
    CounterComp,
    ModalSettings,
    ModalDepositWithdraw,
    ModalDepositWithdrawTable,
    ModalTransactionTable,
    ModalProfitLossTable,
    ModalNotice,
    ModalNoticeDetail,
    AgFilledTable,
    AgPositionTable,
    Header,
    ModalLogout,
    AgFuturesTable,
    AgOptionsTable,
    DomGridClick,
    //DomGridStandard,
    ChartBox,
    OrderInput,
    ModalBasic,
    CodeDropDown,
    BaseDropdown,
  },   
  computed: {
    // 로그인 여부
    isLogin () {
      return window.$store.getters['Login/isLogin'];
    },
    //소켓시세
    changeOvcData: function(){
      let ovcData = window.$store.getters['SocketPrice/getOvcData'];
      return ovcData;
    },
    //소켓호가
    changeOvhData: function(){
      let ovhData = window.$store.getters['SocketPrice/getOvhData']
      return ovhData
    },
    //폴링시세
    changeOvcDataList: function(){
      let ovcData = window.$store.getters['SocketPrice/getOvcDataList'];
      return ovcData;
    },
    //폴링호가
    changeOvhDataList: function(){
      let ovhData = window.$store.getters['SocketPrice/getOvhDataList']
      return ovhData
    },
    changeFvSymbol: function(){
      let custom = window.$store.getters['Layout/getFavoriteGridCustom'];
      if (custom == undefined){
        return null;
      }
      return {socketSymbol: custom.socketSymbol, socketSymbolCnt: this.socketSymbolCnt+1};
    },
    getSelectSymbol: function(){
      return window.$store.getters['SocketPrice/getSelectSymbol'];
    },
    tikDotSz(){
      return stringCount(this.symbol.pricescale);
    },
    resultOms: function(){
      return window.$store.getters['SocketOms/getCompleteOrder'];
    },
    // resultCoinOms: function(){
    //     return window.$store.getters['SocketCoinOms/getCompleteOrder'];
    // },
    getAbleQty: function(){
      return window.$store.getters['SocketOms/getAbleQty'];
    },
    resultMsg: function(){
      return window.$store.getters['SocketMsg/getMessage'];
    },
    getDailyPlMn: function(){
      return window.$store.getters['SocketOms/getDailyPlMn'];
    },
    getPosOrdStList: function(){
      return window.$store.getters['SocketOms/getPosOrdStList'];
    },
    getLoginUserInfo: function(){
      let loginInfo = window.$store.getters['Login/getLoginUserInfo'];
      return loginInfo;
    },
  },
  watch: {
    //소켓시세
    changeOvcData(newPrice){
      if (this.symbol && newPrice.symbol == this.symbol.name) {
        this.nowPrice = newPrice;
        this.setSymbolPrice();

        //일반주문 가격 시세 첫번째 값으로 저장
        // if (this.ordStdPrc == 0){
        //   this.ordStdPrc = Number(newPrice.curPr).toFixed(this.tikDotSz);
        // }
      }
      //팝업 전송
      this.sendPopupData('S_OVC', newPrice);
    },
    //소켓호가
    changeOvhData(newPrice){
      //팝업 전송
      this.sendPopupData('S_OVH', newPrice);
    },
    //폴링시세
    changeOvcDataList(newPrice){
      if (newPrice != null && newPrice.length > 0) {
        newPrice.forEach(data=>{
          if (this.symbol && data.symbol == this.symbol.name){
            // this.curPr = data.curPr;
            // this.setValProLoss(this.grid.rowData[0]); //평가손익 계산
            // this.gridApi.refreshCells();
            this.setSymbolPrice();
          }
        });

        //팝업 전송
        this.sendPopupData('P_OVC', newPrice);
      }
    },
    //폴링호가
    changeOvhDataList(newPrice){
      if (newPrice != null && newPrice.length > 0) {
        //팝업 전송
        this.sendPopupData('P_OVH', newPrice);
      }
    },
    changeFvSymbol(val){
      this.updateSubscribeOnPrice()
    },
    getSelectSymbol(newVal){
      if (newVal == undefined) return;
      this.chartSymbol = newVal;
      this.rStock = Object.assign({}, null);

      //종목이 변경될경우 소켓데이터 연결 갱신
      this.symbol = Object.assign({}, newVal);

      this.nowPrice = {curPr:0, ydiffPr:0, open:0, chGrate:0, high:0, low:0, ydiffSign:0};

      this.tikSize = newVal.minmov / newVal.pricescale;

      this.ordStdPrc = 0; //일반주문 가격

      this.setStockInfo(newVal, null);

      //this.symbolDropdown(newVal);

      this.updateSubscribeOnPrice();

      this.selectSymbolTikPrice();

      this.selectOrder(this.symbol.name);     
      
      //accId가 있으면
      if (this.account.accId && this.account.accId > 0){
        this.searchOrdAbleQty();
      }else{
        //accId가 로딩중이면 기다렸다 실행
        window.$store.watch(() => this.account.accId, isAccount => {
          if (isAccount) {
            this.searchOrdAbleQty();
          }
        })
      }

      //종목 저장
      if (this.account.accId && this.account.accId > 0 && (this.favoriteSymbol == null || this.favoriteSymbol != this.symbol.symbolno)){
        this.favoriteSymbol = this.symbol.symbolno;
        window.$store.dispatch('Layout/saveCustom', { type: 'SYMBOL', custom: this.favoriteSymbol});
      }else if ((!this.account.accId || this.account.accId == 0) && (this.favoriteSymbol == null || this.favoriteSymbol != this.symbol.symbolno)){
        //accId가 로딩중이면 기다렸다 실행
        window.$store.watch(() => this.account.accId, isAccount => {
          if (isAccount) {
            window.$store.dispatch('Layout/saveCustom', { type: 'SYMBOL', custom: this.favoriteSymbol});
          }
        })
      }

      //국내선물일때 해외선물 종목 선택시 해외선물 표시로 변경
      if (this.showInstruments === 'korea' && newVal.symbolno > 0 && newVal.symbolno < 10 ){
        this.showInstruments = 'global';
      }
    },
    resultOms(res){
      //팝업 전송
      this.sendPopupData('OMS', res);

      if (res.result == "S"){
        this.completeOrder(res);
      }else{
        if (res.apiCode == "OMS9990"){
          if (this.getLoginUserInfo != undefined && this.getLoginUserInfo.authId != undefined){
            window.$http.post('/auth/login/refresh', {params : {}});
          }else{
            this.omsloginFailCnt = this.omsloginFailCnt + 1;

            //oms 접속 실패 3회일때 접속해지
            if (this.omsloginFailCnt > 2){
              this.disconnect();
            }
          }
          return;
        }else if (res.apiCode == "OMS9989"){ //OMS 중복접속
          this.disconnect();
        }else{
          this.procFailMessage(res);

          //실패시 리스트목록 주문 목록 재조회
          if (res.body.code != "OMSFL007" && res.body.code != "OMSFL011" && res.body.code != "OMSFL002" && res.body.code != "OMSFL012"
            && res.body.code != "OMSFL014" && res.body.code != "OMSFL016" && res.body.code != "OMSFL017"){
            this.selectOrder(this.symbol.name);
          }
        }
      }
    },
    // resultCoinOms(res){ 
    //   //팝업 전송
    //   this.sendPopupData('COMS', res);

    //   if (res.result == "S"){
    //     this.completeCoinOrder(res);
    //   }else{
    //     if (res.apiCode == "COMS9990"){
    //       if (this.getLoginUserInfo != undefined && this.getLoginUserInfo.authId != undefined){
    //         window.$http.post('/auth/login/refresh', {params : {}});
    //       }
    //       return;
    //     }else{
    //       this.procFailMessage(res);

    //       //실패시 리스트목록 주문 목록 재조회
    //       if (res.body.code != "OMSFL007" && res.body.code != "OMSFL011" && res.body.code != "OMSFL002" && res.body.code != "OMSFL012"
    //         && res.body.code != "OMSFL014" && res.body.code != "OMSFL016" && res.body.code != "OMSFL017"){
    //         this.selectCoinOrder(this.symbol.name);
    //       }
    //     }
    //   }
    // },
    getAbleQty:{
      handler(res){
        this.sellAbleQty = res.ordAbleSellQty;
        this.buyAbleQty = res.ordAbleBuyQty;
      },
      deep:true
    },
    resultMsg:{
      handler(res){
        //console.log("resultMsg >>>> ", res)
        if (res.apiCode == Const.MSG_API_TYPE.BANK){
          //유저 데이터 재조회
          window.$store.dispatch('SocketOms/selectUsrData', {accId:this.account.accId, symCd:this.symbol.name, symNo:this.symbol.symbolno, lv:this.leverage});
        } else if ( res.apiCode == Const.MSG_API_TYPE.LOGOUT ) {

          window.$store.dispatch('Login/logout');

          this.disconnect();

          this.sendPopupData('CLOSE'); //팝업창 닫기

          this.$alert.alert('다른 브라우저에서 로그인하여 <span style="color:red;">로그아웃</span> 되었습니다.<br/><br/>로그인을 다시 시도하시거나 사용을 종료 해 주십시오.', '안내')
          .then(() => {
            this.$router.push('/login');
          });

          return;
        }else if (res.apiCode == Const.MSG_API_TYPE.LOGINKILL){
          //LOGINKILL("MSG0103"),		// 관리자 로그아웃처리
          window.$store.dispatch('Login/logout');

          this.disconnect();

          this.sendPopupData('CLOSE'); //팝업창 닫기

          this.$alert.alert('접속할 수 없습니다.<br/>관리자에게 문의하시기 바랍니다.', '안내')
          .then(() => {
            this.$router.push('/login');
          });
          return;
        }else if ( res.apiCode == Const.MSG_API_TYPE.NOTLOGIN){ //로그인 정보가 없으면 브라우저 로그인 되어있는지 확인 후 로그아웃처리
          //console.log("this.getLoginUserInfo ", this.getLoginUserInfo)
          if (this.getLoginUserInfo != undefined && this.getLoginUserInfo.authId != undefined){
              window.$http.post('/auth/login/refresh', {params : {}});
              //console.log("login/refresh ");
          }
          return;
        }else if(res.apiCode == Const.MSG_API_TYPE.LVCHANGE){
          this.selectLeverageList();
          //팝업창 갱신
          this.sendPopupData('selectLeverageList');
        }

        // 알림 재조회
        //EventBus.$emit('Alarm:event:refresh', {});

        //공지 팝업 호출
        if (res.body.post != undefined && res.body.post.popupFlg != undefined && res.body.post.popupFlg == "Y"){
          this.$modal.show("modalBasic", res.body);
           return;
        }else{
          this.$notify({
            group : 'notification',
            //text : '<span style="color: var(--main-red)">알림</span> '+ res.body.msg
            text :  res.body.msg
          });
          return;
        }

      },
      deep:true
    },
    getDailyPlMn:{
      handler(res){
        this.dailyPlMn.fxPlMn = res.fxPlMn; // 해외손익금액
        this.dailyPlMn.fxCms = res.fxCms; // 해외수수료
        this.dailyPlMn.nFxPlMn = res.nFxPlMn; // 해외순손익
        this.dailyPlMn.futPlMn = res.futPlMn; // 국내손익금액
        this.dailyPlMn.futCms = res.futCms; // 국내수수료
        this.dailyPlMn.nFutPlMn = res.nFutPlMn; // 국내순손익
        this.dailyPlMn.totPlMn = res.totPlMn; // 총손익금액
        this.dailyPlMn.totNPlMn = res.totNPlMn; // 총순손익
        this.dailyPlMn.totCms = res.totCms; // 총수수료
        this.dailyPlMn.niPlMn = res.niPlMn;
        this.dailyPlMn.balMn = res.balMn;
        this.dailyPlMn.hkdExR = res.hkdExR;
        this.dailyPlMn.usdExR = res.usdExR;
        this.dailyPlMn.usdExHkd = res.usdExHkd;

        this.sendPopupData('DAILY', res); //팝업 전송
      },        
      deep:true
    },
    getPosOrdStList(newList){
      this.sendPopupData('POS_ORD', newList); //팝업 잔고리스트 전송
      if (newList.length > 0){
        let balQty = 0;
        let execPrc = 0;
        let ordSdCd = "0";
        let plMn = 0;
        let valPl = 0;
        let plData = {};
        newList.forEach(data=>{
          if (data.ordType == "POS" && data.symCd == this.symbol.name){
            balQty = data.balQty;
            ordSdCd = data.ordSdCd;
            if (balQty > 0) execPrc = data.execPrc;
            plMn = data.plMn;
            valPl = data.valPl;
            plData = data;
          }
        })

        this.symbolPlMn.balQty = balQty;
        this.symbolPlMn.execPrc = execPrc;
        this.symbolPlMn.plMn = plMn;
        this.symbolPlMn.data = plData;

        this.avgPrice = Object.assign({}, {avgPrice:execPrc, ordSdCd:ordSdCd, balQty:balQty})
        

        this.setSymbolPrice();
        
        //symbolPlMn:{balQty:0, execPrc:0, fxPlMn:0, valPl:0},
      }
    },
    getLoginUserInfo:{
      handler(userInfo){
        this.loginUserInfo = userInfo;
      }
    },
  },
  data() {
    return {
      // selectedTable: 'held',
      showPanel: true,
      showInstruments: 'global',
      setIntervalId: null,
      selectedTheme: 'black',
      symbol:{name:null, base_name:null},
      symbolList:[],
      accId: null,
      account: {
        accNm: '',
        accPass: '',
        accCnt: 0,
        accId: 0,
      },
      coinAccount: {
        accNm: '',
        accPass: '',
        accCnt: 0,
        accId: 0,
      },
      accountList:[],
      chgSymbol: null,
      priceReqType: "SCKTP02", //시세 받는방식 socket(SCKTP01), polling(SCKTP02/SCKTP03)
      symbolCdList:[],
      rtPosSymbolList:[],
      sellAbleQty:0,
      buyAbleQty:0,
      orderSetting: null,
      favoriteSymbol: null,
      btnSellMarketFlag : true,
      btnBuyMarketFlag : true,
      btnCancelSellStAllFlag : true,
      btnCancelBuyStAllFlag : true,
      btnCancelSellAllFlag : true,
      btnCancelBuyAllFlag : true,
      btnCancelAllFlag : true,
      sellSlTick:1,
      buySlTick:1,
      clickSetList: ['1','2','3','4','5'],
      ordClick: 1,
      slStockVal1:false,
      slStockVal2:false,
      leverage : '1',
      leverageList: [{cd:'1', name:'1:1'}],
      selectOrderData:null,
      nowPrice:{curPr:0, ydiffPr:0, open:0, chGrate:0, high:0, low:0, ydiffSign:0},
      dailyPlMn:{fxPlMn:"0", fxCms:"0", nFxPlMn:"0", futPlMn:"0", futCms:"0", nFutPlMn:"0", totPlMn:"0", totNPlMn:"0", totCms:"0", niPlMn:"0", balMn:"0", hkdExR:"0", usdExR:"0", usdExHkd:"0", totalValPl:"0", totalLossCut:"0", data:null},
      symbolPlMn:{balQty:"0", execPrc:"0", plMn:"0", valPl:"0", data:null},
      totalValPl: null,
      orderType: 'click',
      tikSize: 0,
      avgPrice:null,      
      width: 757,
      height: 850,
      currencyInfo:{currency:"WON"},
      ordStdType:"1",
      ordStdQty:1,
      ordStdPrc:0,
      btnStdFlag:true,
      layout : {},
      noticePop: null,
      rStock: null,
      symbolTikPrice:0,
      chartSymbolList:[],
      chartSymbol:null,
      //팝업 창 관련
      dataChannel:null,
      popupId1:null,
      dataChannel1:null,
      ovcChannel1:null,
      popupId2:null,
      dataChannel2:null,
      ovcChannel2:null,
      //팝업 창 관련
      //코인주문 관련
      //coinSymbolList: [],
      //코인주문 관련
      futUseYn: "N",
      coinUseYn: "N",
      stdOrdShow: false, //일반주문 표시여부
      omsloginFailCnt: 0, //oms 로그인 실패건수
    }
  },
  filters: {},
  created(){
    this.domainCheck();

    if (this.$route.params.instruments != undefined){
      this.showInstruments = this.$route.params.instruments;
    }

    EventBus.$on('selectedTheme', (theme) => {
      this.selectedTheme = theme;
      console.log(this.selectedTheme);
    })
    
    //this.loginChk();
    // 로그인 여부 폴링으로 체크
    // this.setIntervalId = setInterval(() => {
    //     if (!this.isLogin){
    //       clearInterval(this.setIntervalId);
    //     }else{
    //       this.loginChk();
    //     }
    //   }, 3000)
    
    this.loadLayout();

    EventBus.$on('Layout:event:loadLayout', (layout) => {
      this.setLayout(layout)
    })

    EventBus.$on('Favorite:event:setSymbol', (symbol) => {      
      this.symbolList.forEach(item => {
        if (item.name == symbol){
          this.chgSymbol = item;
          return;
        }
      })
    })

    this.selectSymbolList();

    //message 소켓 서버 연결
    this.connectMsg();

    //팝업 전송용 채널
    this.dataChannel = new BroadcastChannel('dataChannel');

    //팝업창 호출 이벤트
    window.addEventListener("message", this.popupData, false);
  },
  mounted() {
    const chartPanel = document.querySelector('.desktop-chart-container');

    //차트 사이즈 자동인식
    const observer = new ResizeObserver(entries => {
      for (let entry of entries) {
        const {width, height} = entry.contentRect;
        this.height = height;
        this.width = width;
      }
    });

    //자동으로 인식할 부분 추가
    observer.observe(chartPanel);

    //공지팝업 테스트용
    // let test = {postNo:22};
    // this.$modal.show("modalBasic", test);

    //window.addEventListener('beforeunload', this.handleBeforeUnload);
  },

  methods: {
    //창이 닫힐때 호출
    handleBeforeUnload(event) {
      this.sendPopupData('CLOSE');
    },
    async domainCheck(){
      const host = window.location.host;
      const formData = {hostUrl: host};

      await this.$lhttp.post('/auth/domainChk', formData).then((response) => {
        //console.log("response... ", response);
        if (response.code == 200){
          if (response.data != undefined && response.data.cpnId > 0){
            //접속방식 설정
            window.$store.dispatch('SocketPrice/setPollingType', {sckTpCd:response.data.sckTpCd, sckTpDsc:response.data.sckTpDsc});
            this.priceReqType = window.$store.getters['SocketPrice/getPriceReqType'];

            //커스텀 화면 설정
            let cpnAuthList = window.$store.getters['Layout/getCpnAuthList']
            if (cpnAuthList == undefined || cpnAuthList == null || cpnAuthList.length){
              window.$store.dispatch('Layout/setCpnAuth', {data:response.data.cpnAuthList})
              cpnAuthList = response.data.cpnAuthList;
              this.setCpnAuth(cpnAuthList);
            }

            //선물, 코인 사용여부
            if (response.data.futUseYn != undefined){
              this.futUseYn = response.data.futUseYn;
            }
            if (response.data.coinUseYn != undefined){
              this.coinUseYn = response.data.coinUseYn;
            }

            if (this.futUseYn == "N" && this.coinUseYn == "Y"){
              this.$router.push('/coinDeskTop');
            }
          }else{
            this.$alert.alert('접속하신 도메인정보의 회사정보가 없습니다.\n관리자에게 문의하세요.', '알림');
            return;
          }
        }else{
          this.$alert.alert('접속하신 도메인정보의 회사정보가 없습니다.\n관리자에게 문의하세요.', '알림');
          return;
        }
      })
      
      this.selectAccountList();      

      //소켓연결시
      //console.log("시세 연결 방식 >>> ", this.priceReqType);
      if (this.priceReqType == "SCKTP01"){
          this.connectPrice();
      }

      //주문 소켓 서버 연결
      if (this.futUseYn == "Y"){
        this.connectOms();
      }

    },
    async loginChk() {
      //await window.$http.post('/auth/login/refresh', {params : {}});
      const response = await window.$http.post('/auth/login/checkLogin', {params : {}});
      if (response.data.code != 200){ // 로그인 정보가 없으면
        await window.$http.post('/auth/login/refresh', {params : {}});
        //clearInterval(this.setIntervalId);
      }
    },
    thousand(value, digits) {
      //return value.toLocaleString('en-US', { minimumFractionDigits: digits });
      if (value == undefined){
        return 0;
      }else{
        return value.toLocaleString('en-US', { minimumFractionDigits: 0 });
      } 
    },
    setShowPanel(flag){
      this.showPanel = flag;
      if (flag){
        this.sendOmsLogMessage("축소 클릭");
      }else{
        this.sendOmsLogMessage("확장 클릭");
      }
    },
    checkInstruments(e) {
      if(e.target.value === this.showInstruments) {
        //this.showInstruments = '';
      } else {
        if (e.target.value == "korea"){
          //국내선물
          this.sendOmsLogMessage("국내선물 클릭");
          for (let item of this.symbolList) {
            if (item.symbolno == 10){ //국내선물
              this.symbol = item;

              // window.$store.dispatch('SocketCoinPrice/resetCoinSymbol');
              window.$store.dispatch('SocketPrice/setSelectSymbol', item);
              this.setStockInfo(item, null);
              this.updateSubscribeOnPrice();
              this.selectSymbolTikPrice();
              this.selectOrder(this.symbol.name);

              this.chartSymbol = item;
              this.chartSymbolList = this.symbolList;
              break;
            }
          }
          this.showInstruments = e.target.value;
        }else if (e.target.value == "global"){
          //해외선물
          this.sendOmsLogMessage("해외선물 클릭");
          for (let item of this.symbolList) {
            if (item.symbolno == 1){ //나스닥
              this.symbol = item;

              // window.$store.dispatch('SocketCoinPrice/resetCoinSymbol');
              window.$store.dispatch('SocketPrice/setSelectSymbol', item);
              this.setStockInfo(item, null);
              this.updateSubscribeOnPrice();
              this.selectSymbolTikPrice();
              this.selectOrder(this.symbol.name);

              this.chartSymbol = item;
              this.chartSymbolList = this.symbolList;
              break;
            }
          }
          this.showInstruments = e.target.value;
        }else if (e.target.value == "coin"){
          window.$loading.show();
          //코인
          //해외선물 시세 초기화
          window.$store.dispatch('SocketPrice/resetSymbol');
          
          this.sendOmsLogMessage("코인선물 클릭");
          setTimeout(()=>{
            this.$router.push('/coinDesktop');
          }, 1000);
          // this.searchCoinUsrData();
          // for (let item of this.coinSymbolList) {
          //   if (item.symbolno == 101){ //BTC
          //     this.coinSymbol = item;
          //     window.$store.dispatch('SocketCoinPrice/setSelectCoinSymbol', item);


          //     this.chartSymbol = item;
          //     this.chartSymbolList = this.coinSymbolList;
          //     break;
          //   }
          // }

          // this.selectUsrPos();
        }
        
      }
    },
    loadLayout() {
      window.$store
        .dispatch('Layout/load')
        .then(() => {
          this.layout = window.$store.getters['Layout/getLayout']

          EventBus.$emit('Layout:event:loadLayout', this.layout)

          //this.isCompleteLoadLayout = true
        })         
    },
    setLayout(layout) {
      //this.orderSetting = layout.orderSetting;
      this.loadCustom();
    },
    loadCustom() {
      this.orderSetting = window.$store.getters['Layout/getOrderSetting']
      this.favoriteSymbol = window.$store.getters['Layout/getFavoriteSymbol']

      //this.orderSetting = Object.assign({}, window.$store.getters['Layout/getOrderSetting']);

      if (this.orderSetting != null){
        //this.selectLoadCustom = this.selectLoadCustom +1;

        //손절
        if (this.slStockVal2 == false){
          this.setSellSlTick(this.orderSetting.slTikVal1.tikVal)
        }

        //익절
        if (this.slStockVal1 == false){
          this.setBuySlTick(this.orderSetting.slTikVal2.tikVal);
        }

        if (this.orderSetting != null){
          //주문수량 설정
          this.clickSetList[0] = this.orderSetting.clickVal1.val.toString();
          this.clickSetList[1] = this.orderSetting.clickVal2.val.toString();
          this.clickSetList[2] = this.orderSetting.clickVal3.val.toString();
          this.clickSetList[3] = this.orderSetting.clickVal4.val.toString();
          this.clickSetList[4] = this.orderSetting.clickVal5.val.toString();

          this.ordClick = Number(this.clickSetList[0]);

          //원클릭, 더블클릭 설정
          // if (this.orderSetting.oneClick == true){
          //   this.itemColumnDoubleClickTime = 0;
          // }else if (this.orderSetting.dbClick == true){
          //   this.itemColumnDoubleClickTime = 200;
          // }
        }

        //레버리지
        if (this.orderSetting.leverage && this.getLoginUserInfo.lvTpCd == "Y"){
            this.leverage = this.orderSetting.leverage;
        }

        //팝업창 정보 갱신
        //this.dataChannel.postMessage({type:'orderSetting'});
        this.sendPopupData('orderSetting');
      }
    },
    async selectLeverageList(){
      const list = await window.$http.get('/api/order/lv/limitlist', { params: { accNo:this.account.accNo } } );

      let lvList = [];
      if (list.data != undefined && list.data.length > 0){
        list.data.forEach((item)=>{
          lvList.push({cd:item.cd, name:item.cdNm});
        });
        this.leverageList = lvList;

        if (Number(this.leverage) < Number(lvList[0].cd) && this.getLoginUserInfo.lvTpCd == "Y"){
          this.selectLeverage(lvList[0]);
        }
      }

    },
    //종목 조회
    async selectSymbolList() {
      await window.$store.dispatch('Symbol/getSecMt'); //월물 조회
      await window.$store.dispatch('Symbol/load'); //종목 조회
      //await window.$store.dispatch('Symbol/coinLoad'); //코인 종목 조회
      this.symbolList = window.$store.getters['Symbol/getSymbolList'];
      // this.coinSymbolList = window.$store.getters['Symbol/getCoinSymbolList'];

      //차트 심볼리스트 디폴트 선물 종목설정
      this.chartSymbolList = window.$store.getters['Symbol/getSymbolList'];

      //저장된 종목이 있으면 비교후 저장된 종목으로 실행
      let favSymbol = null;
      if (this.favoriteSymbol != null){
        for(let item of this.symbolList){
          if (this.favoriteSymbol == item.symbolno){
            favSymbol = item;
          }
        }
      }else{
        favSymbol = this.symbolList[0];
      } 

      let korSymbol = null;
      for(let item of this.symbolList){
        if (item.symbolno == 10){
          korSymbol = item;
          break;
        }
      }

      

      //코인 > 선물 이동시 국내선물 저장되어있으면 나스닥으로 변경
      if (this.$route.params.instruments == "global" && favSymbol.symbolno == 10){
        favSymbol = this.symbolList[0];
      }else if (this.$route.params.instruments == "korea"){
        favSymbol = korSymbol;
      }

      //소켓 시세
      if (this.priceReqType == "SCKTP01"){
        if (this.futUseYn == "Y"){

          if (!window.$store.getters['SocketPrice/getIsConnectPrice']) {
            window.$store.watch(() => window.$store.getters['SocketPrice/getIsConnectPrice'], socketPrice => {
              window.$store.dispatch('SocketPrice/setSubscribeOnPriceList', [])
              if (this.$route.params.instruments == "korea" && korSymbol != null){
                this.symbolDropdown(korSymbol);
              }else{
                this.symbolDropdown(favSymbol);

                //국내선물
                if (favSymbol.symbolno == 10){
                  this.showInstruments = 'korea';
                }
              }
            })
          }else{
            window.$store.dispatch('SocketPrice/setSubscribeOnPriceList', [])
            if (this.$route.params.instruments == "korea" && korSymbol != null){
              this.symbolDropdown(korSymbol);
            }else{
              this.symbolDropdown(favSymbol);

              //국내선물
              if (favSymbol.symbolno == 10){
                this.showInstruments = 'korea';
              }
            }
          }
        }
      //폴링 시세
      }else if (this.priceReqType == "SCKTP02" || this.priceReqType == "SCKTP03"){
        let symbolList = [];
        if (this.$route.params.instruments == "korea" && korSymbol != null){
          this.symbolDropdown(korSymbol);
        }else{
          this.symbolDropdown(favSymbol);
        }
        
        this.connectPrice(symbolList);
      }

      //this.setStockInfo(this.symbolList, null);
    },
    // 시세 socket 연결
    connectPrice(symbolList) {
      if (this.priceReqType == "SCKTP01"){
        window.$store.dispatch('SocketPrice/setConectPrice', {sessionStorage:this.$sessionStorage.get('xDkToken')});
      }else if (this.priceReqType == "SCKTP02" || this.priceReqType == "SCKTP03"){
        window.$store.dispatch('SocketPrice/setPollingPrice', {symCd:this.symbolList[0].name, symbolList:symbolList});
      }
    },
    updateRtPosSymbol(dataList){
      this.rtPosSymbolList = [];
      dataList.forEach(data=>{
        if (data.ordType == "POS"){
          this.rtPosSymbolList.push(data);
        }
      })
      
      this.updateSubscribeOnPrice();
    },
    updateSubscribeOnPrice(){
      //소켓 구독 종목 업데이트
      this.symbolCdList = [];

      //잔고목록
      this.rtPosSymbolList.forEach(data =>{
        if (data.balQty > 0){
          this.symbolCdList.push(data.symCd);
        }
      });

      //선택 종목
      const selSymbol = window.$store.getters['SocketPrice/getSelectSymbol'];

      //팝업 선택 종목
      let popupSelSymbolList = [];
      //stock_trader_popup1SelSymbol

      if (JSON.parse(this.$sessionStorage.get('popup1SelSymbol')) != null){
        const popupSymbol = JSON.parse(this.$sessionStorage.get('popup1SelSymbol'));
        popupSelSymbolList.push(popupSymbol.name);
      }

      if (this.$sessionStorage.get('popup2SelSymbol') != null){
        const popupSymbol = JSON.parse(this.$sessionStorage.get('popup2SelSymbol'));
        popupSelSymbolList.push(popupSymbol.name);
      }

      //팝업 종목 추가
      popupSelSymbolList.forEach(item =>{
        let chkYn = "N";
        this.rtPosSymbolList.forEach(data =>{
          if (data == item){
            chkYn = "Y";
            return;
          }
        });

        if (chkYn == "N"){
          this.symbolCdList.push(item);
        }
      });

      if (selSymbol != undefined && selSymbol.name){
        let chkYn = "N";
        for (let item of this.symbolCdList){
          if (selSymbol.name == item){
            chkYn = "Y";
            return;
          }
        }
        if (chkYn == "N"){
          this.symbolCdList.push(selSymbol.name);
        }
      }      

      //console.log("소켓 구독 수정", this.symbolCdList)
      
      this.subscribeOnPriceModify(this.symbolCdList, popupSelSymbolList);
    },
    subscribeOnPriceModify(symbolList, popupSelSymbolList) {
      if (this.priceReqType == "SCKTP01"){
        window.$store.dispatch('SocketPrice/setUpdateSubscribeOnPriceList', {symbolList, popupSelSymbolList});
      }else if (this.priceReqType == "SCKTP02" || this.priceReqType == "SCKTP03"){
        window.$store.dispatch('SocketPrice/setPollingPrice', {symCd:this.symbol.name, symbolList:symbolList, popupSelSymbolList:popupSelSymbolList});
      }
    },
    async setStockInfo(newSymbol, oldSymbol) {

      //const rStock = await window.$http.get('chart/datafeed/stockdata', { params: {  } } )
      //console.log("rStock search >>> ", newSymbol)
      const rStock = await window.$http.get('apisise/getLastPrice', { params: { symCd: newSymbol.name} } )
      
      if ( rStock != null ) {
        //console.log("rStock >>>>> ", rStock)
        if (rStock.data.ovc != null){
          this.nowPrice = rStock.data.ovc;
          this.ordStdPrc = Number(this.nowPrice.curPr).toFixed(this.tikDotSz);
        }else{
          this.nowPrice = {curPr:0, ydiffPr:0, open:0, chGrate:0, high:0, low:0, ydiffSign:0};
        }
        
        this.rStock = Object.assign({}, rStock.data);
        
        // let ovc = this.ovcData.ovcData;

        // //시세 db값으로 초기 설정
        // rStock.data.forEach(item => {
        //   ovc.set(item.symbol, item);
        // });
        
        // let cnt = this.ovcData.cnt; //map으로 전달하면 PanelComp2 watch에서 감지를 못하여 cnt값을 계속 증가시켜 감지
        // const param = {cnt:cnt+1, ovcData:ovc};         
        // this.ovcData = param;
      }
    },
    // oms socket 연결
    async connectOms() {
      await window.$store.dispatch('SocketOms/setConectOms', {sessionStorage:this.$sessionStorage.get('xDkToken'), subscriptionUID:sessionStorage.getItem('subscriptionUID')})
    },
    // coin oms socket 연결
    // async connectCoinOms(){
    //   await window.$store.dispatch('SocketCoinOms/setConectCoinOms', {sessionStorage:this.$sessionStorage.get('xDkToken'), subscriptionUID:sessionStorage.getItem('subscriptionUID')})
    // },
    async connectMsg() {
      if (!window.$store.getters['SocketMsg/getIsConnectMsg']) {
        await window.$store.dispatch('SocketMsg/setConectMsg', {sessionStorage:this.$sessionStorage.get('xDkToken'), subscriptionUID:sessionStorage.getItem('subscriptionUID')})
      }
    },
    //종목셀렉트 선택
    symbolDropdown(value) {
      window.$store.dispatch('SocketPrice/setSelectSymbol', value);
    },
    selectSymbolTikPrice(){
      this.$http
      .get('/api/symbol/getSymbolTikPrice', { params: {symNo:this.symbol.symbolno} })
      .then((result) => {
        if ( result && result.data !== null ) {          
          this.symbolTikPrice = result.data.tikValPrc;
        }
      })
    },
    async selectAccountList(){
      await this.$http
      .get('/api/account/list')
      .then((result) => {
        if ( result && result.data !== null ) {
          //console.log("account result ", result)
          
          this.accountList = result.data;

          //첫번째 계좌는 선물계좌로 사용(순번 desc 순서)
          if (result.data.length > 0){
            for (let item of result.data){
              //console.log("account item ", item)
              if (item.accDivCd == 'ACCDIV04' && this.account.accId == 0){
                this.account = item;
                this.$sessionStorage.set('account', JSON.stringify(item));
              }

              // if (item.accDivCd == 'ACCDIV05' && this.coinAccount.accId == 0){
              //   this.coinAccount = item;
              //   this.$sessionStorage.set('coinAccount', JSON.stringify(item));
              // }
            }

            //레버리지 유저별 목록
            if (this.futUseYn == "Y"){
              this.selectLeverageList();
            }
          }
        }
      });
    },
    async selectOrder(symbol) {
      const response = await window.$http.get('api/order/list', { params: { accId: this.account.accId, symCd: symbol } } );
      //console.log("selectOrder response", response);



      //STOP LOSS 초기화
      // this.slStockVal1 = false;
      // this.slStockVal2 = false;
      // this.sellSlTick = 1; //db저장된 디폴트 값을 셋팅
      // this.buySlTick = 1; //db저장된 디폴트 값을 셋팅

      this.slStockVal2 = false;
      this.slStockVal1 = false;


      if (response.data) {
        this.selectOrderData = {selectOrderList: response.data};

        for (let order of response.data) {
          if (order.ordType == "SL" && order.symCd == this.symbol.name){
            if (order.ordSdCd == "1"){ //CHK_VAL 값 //1:익절 , 2:손절
              //ordPrc -> TICK_VAL 설정값
              if (order.ordPrc != null && order.ordPrc > 0){
                if (order.ordTpCd == 0){ // ORD_STAT_CD = 0 이면 체크
                  this.slStockVal1 = true;
                  this.setBuySlTick(order.ordPrc);
                }
              }
            }else if (order.ordSdCd == "2"){
              if (order.ordPrc != null && order.ordPrc > 0){
                if (order.ordTpCd == 0){ // ORD_STAT_CD = 0 이면 체크
                  this.slStockVal2 = true;
                  this.setSellSlTick(order.ordPrc);
                }
              }
            }
          }
        }

        //손절, 익절 포지션 설정값이 없으면 셋팅설정값으로 설정

        //손절
        if (this.slStockVal2 != undefined && this.slStockVal2 == false && this.orderSetting.slTikVal2 != undefined && this.orderSetting.slTikVal2.tikVal){
          this.setSellSlTick(this.orderSetting.slTikVal2.tikVal)
        }

        //익절
        if (this.slStockVal1 != undefined && this.slStockVal1 == false && this.orderSetting.slTikVal1 != undefined && this.orderSetting.slTikVal1.tikVal){
          this.setBuySlTick(this.orderSetting.slTikVal1.tikVal);
        }
      }else{
        this.selectOrderData = {selectOrderList: []};
      }
    },
    completeOrder(res){
      //console.log("desk completeOrder ??? >>> ", res)
      if (res != undefined && res.notify != undefined){
        this.notifyAlert(res.notify)
      }
      const apiCode = res.apiCode;
      const body = res.body;

      if ( apiCode == 'OMS0001' && body.ordTpCd == "2" && body.execYn == "Y"){ //시장가 버튼 활성화
        if (body.ordSdCd == "1"){ //매도
          this.btnSellMarketFlag = true;
        }else if (body.ordSdCd == "2"){ //매수
          this.btnBuyMarketFlag = true;
        }        
      }else if ( apiCode == 'OMS0007') {
        if ( body.chkVal == '1' && body.accId == this.account.accId && this.symbol.name == body.symCd) {
            //this.$emit('updateSlStock', {chkType:'1', chkVal:!this.slStockVal1});
            if (body.execYn == "Y"){
              this.updateSlStock({chkType:'1', chkVal:true, tickVal:res.body.tickVal});
            }else{
              this.updateSlStock({chkType:'1', chkVal:false, tickVal:res.body.tickVal});
            }            
        } else if ( body.chkVal == '2' && body.accId == this.account.accId && this.symbol.name == body.symCd) {
            //this.$emit('updateSlStock', {chkType:'2', chkVal:!this.slStockVal2});
            
            if (body.execYn == "Y"){
              this.updateSlStock({chkType:'2', chkVal:true, tickVal:res.body.tickVal});
            }else{
              this.updateSlStock({chkType:'2', chkVal:false, tickVal:res.body.tickVal});
            }
        }
      // STOP LOSS 취소 
      } else if ( apiCode == 'OMS0008') {
        if ( body.chkVal == '1' && body.accId == this.account.accId && this.symbol.name == body.symCd) {
          if ( body.execYn == 'Y' ) {
            this.updateSlStock({chkType:'1', chkVal:false, tickVal:res.body.tickVal});
          }
        } else if ( body.chkVal == '2' && body.accId == this.account.accId && this.symbol.name == body.symCd) {
          if ( body.execYn == 'Y' ) {
            this.updateSlStock({chkType:'2', chkVal:false, tickVal:res.body.tickVal});
          }
        }
       // 전/현 종목 청산
      } else if ( apiCode == 'OMS0013') {
        if (body.delList != undefined){
          body.delList.forEach(del => {
              //같은 종목만 삭제 처리
              if (del.symCd == this.symbol.name){
                if ( del.chkVal == '1' ) {
                  if ( del.execYn == 'Y' ) {
                    this.updateSlStock({chkType:'1', chkVal:false});
                  }
                } else if ( del.chkVal == '2' ) {
                  if ( del.execYn == 'Y' ) {        
                    this.updateSlStock({chkType:'2', chkVal:false});
                  }
                }
              }           
            });
        }
      //RMS 청산
      }else if (apiCode == 'OMS0017'){
        this.updateSlStock({chkType:'1', chkVal:false});
        this.updateSlStock({chkType:'2', chkVal:false});
      //배치 청산
      } else if ( apiCode == 'OMS9995'){ 
        if (body.execYn == "Y"){
          this.updateSlStock({chkType:'1', chkVal:false});
          this.updateSlStock({chkType:'2', chkVal:false});
        }
      }
      

      //포지션 조회
      if (this.account.accId && this.account.accId > 0){
        //this.search(this.symbol.name);
        this.searchOrdAbleQty();
        //this.$emit("PanelComp4Select", this.symbol.name);
      }
    },
    // completeCoinOrder(res){
    //   if (res != undefined && res.notify != undefined){
    //     this.notifyAlert(res.notify)
    //   }
    //   const apiCode = res.apiCode;
    //   const body = res.body;
    // },
    procFailMessage(res) {
      let body = res.body;

      //기존 알림창 닫기
      this.$notify({ group: 'notification', clean: true })

      this.$notify({
        group: 'notification',
        title: 'FAIL',
        text: body.message
      })
    },
    //유저데이터 조회
    searchOrdAbleQty() {
      //window.$store.dispatch('SocketOms/selectAbleQty', {accId:this.account.accId, symcd:this.symbol.name, symNo:this.symbol.symbolno});
      window.$store.dispatch('SocketOms/selectUsrData', {accId:this.account.accId, symCd:this.symbol.name, symNo:this.symbol.symbolno, lv:this.leverage});
    },
    //코인 유저데이터 조회
    // searchCoinUsrData(){
    //   window.$store.dispatch('SocketCoinOms/selectUsrData', {accId:this.coinAccount.accId, symCd:this.coinSymbol.name, symNo:this.coinSymbol.symbolno, lv:this.coinLeverage, ordType:'coin'});
    // },
    tikVal(symbol){
      if (symbol == null){
        return 0;
      }
      return (symbol.minmov / symbol.pricescale);
    },
    notifyAlert(msgInfo){ //주문 알림 메시지
      //console.log("msgInfo", msgInfo)
      
      if (msgInfo != null){
        if (this.orderSetting != null && this.orderSetting.soundAlert){
           // 사운드 실행
           if (msgInfo.apiCode == 'OMS0001'){ //주문완료
            if (msgInfo.body.ordSdCd == '1'){
              this.soundOn('/SELL_COMP.WAV')
            }else{
              this.soundOn('/BUY_COMP.WAV')
            }
          }else if (msgInfo.apiCode == 'OMS0002'){  //취소주문
            this.soundOn('/CANCEL_COMP.WAV')
          }else if (msgInfo.apiCode == 'OMS0003'){  //정정주문  
            this.soundOn('/MODIFY_COMP.WAV')
          }else if (msgInfo.apiCode == 'OMS0005'){  //MIT취소완료
            this.soundOn('/CANCEL_COMP.WAV')
          }else if (msgInfo.apiCode == 'OMS0006'){  //MIT정정완료
            this.soundOn('/MODIFY_COMP.WAV')
          }else if (msgInfo.apiCode == 'OMS0010'){ //종목선택취소 
            this.soundOn('/CANCEL_COMP.WAV')
          }else if (msgInfo.apiCode == 'OMS0011'){ //MIT종목선택취소
            this.soundOn('/CANCEL_COMP.WAV')
          }else if (msgInfo.apiCode == 'OMS0012'){ //일괄취소
            this.soundOn('/CANCEL_COMP.WAV')
          }
        }

        if (this.orderSetting != null && this.orderSetting.messageAlert){
        
          
          this.$notify({ group: msgInfo.group, clean: true })

          this.$notify(msgInfo)
        }        
      }
    },
    soundOn(file){
      //if (this.orderSetting != null && this.orderSetting.soundAlert){
        new Audio("/wav"+file).play();
      //}
    },
    delayAlert(){ // 버튼 딜레이 시간까지 활성화 안되면 알림창
      window.$alert.alert(' 네트워크 상태가 좋지 않습니다.<br/>주문 누락 등의 위험이 있으니, 네트워크 상태를 점검해 보시기 바랍니다.', '알림');
    },
    sellMarket(){
      //주문내역이 있으면 리턴 결과 오기전 재주문 불가
      if (!this.btnSellMarketFlag){
        return;
      } 

      this.btnSellMarketFlag = false

      const order = {
        usrKey: Utils.getUuidv4(),
        accId : this.account.accId,
        symCd : this.symbol.name,
        symNo : this.symbol.symbolno,
        trdDt : '',
        ordId : 0,
        orgOrdNo: 0,
        ordSdCd : '1',
        ordTpCd : '2',
        ordPrc : 0,
        ordQty : this.ordClick,
        repReqQty : 0,
        cxlReqQty : 0,
        lv : this.leverage,
      }

      let message = "시장가 매도 클릭";
      this.sendOmsLogMessage(message);

      if (this.orderSetting != undefined && this.orderSetting.ordMessageAlert){
        // this.$alert.confirm(
        //   '<b style="font-weight:600;">종목명</b> : ' + this.symbol.description + '</br>' +
        //   '<b style="font-weight:600;">구분</b> : 매도(시장가)' + '</br>' +
        //   '<b style="font-weight:600;">수량</b> : ' + this.ordClick + '</br>' +
        //   '<b style="font-weight:600;">가격</b> : 시장가' + '</br>' +
        //   '<b style="font-weight:600;">매도 주문을 하시겠습니까?</b>', '주문 확인')
        // .then((response) => {
        //   if (!response) {
        //     this.btnSellMarketFlag = true
        //     return;
        //   }else{
        //     this.sendOmsMessage('OMS0001', order)
        //   }
        // })
        const orderData = {apiCode:"OMS0001", order: order, symbol:this.symbol ,qty:this.ordClick, price: 0, message:message};
        this.$modal.show("modalConfirmOrder", orderData);
      }else{
        this.sendOmsMessage('OMS0001', order)
      }

      //1.5초후 버튼 누를 수 있게 설정
      if (!this.btnSellMarketFlag){
        setTimeout(() => {
          this.btnSellMarketFlag = true
        }, 1500)
      }
    },
    buyMarket(){
      //주문내역이 있으면 리턴 결과 오기전 재주문 불가
      if (!this.btnBuyMarketFlag){
        return;
      } 

      this.btnBuyMarketFlag = false

      const order = {
        usrKey: Utils.getUuidv4(),
        accId : this.account.accId,
        symCd : this.symbol.name,
        symNo : this.symbol.symbolno,
        trdDt : '',
        ordId : 0,
        orgOrdNo: 0,
        ordSdCd : '2',
        ordTpCd : '2',
        ordPrc : 0,
        ordQty : this.ordClick,
        repReqQty : 0,
        cxlReqQty : 0,
        lv : this.leverage,
      }

      let message = "시장가 매수 클릭";
      this.sendOmsLogMessage(message);

      if (this.orderSetting != undefined && this.orderSetting.ordMessageAlert){
        // this.$alert.confirm(
        //   '<b style="font-weight:600;">종목명</b> : ' + this.symbol.description + '</br>' +
        //   '<b style="font-weight:600;">구분</b> : 매수(시장가)' + '</br>' +
        //   '<b style="font-weight:600;">수량</b> : ' + this.ordClick + '</br>' +
        //   '<b style="font-weight:600;">가격</b> : 시장가' + '</br>' +
        //   '<b style="font-weight:600;">매수 주문을 하시겠습니까?</b>', '주문 확인')
        // .then((response) => {
        //   if (!response) {
        //     this.btnBuyMarketFlag = true
        //     return;
        //   }else{
        //     this.sendOmsMessage('OMS0001', order)
        //   }
        // })
        const orderData = {apiCode:"OMS0001", order: order, symbol:this.symbol ,qty:this.ordClick, price: 0, message:message};
        this.$modal.show("modalConfirmOrder", orderData);
      }else{
        this.sendOmsMessage('OMS0001', order)
      }

      //1.5초후 버튼 누를 수 있게 설정
      if (!this.btnBuyMarketFlag){
        setTimeout(() => {
          this.btnBuyMarketFlag = true;
        }, 1500)
      }
    },
    cancelSellAll(){ //SELL 전체 취소
      if (this.account == undefined || this.account.accId == undefined ||this.account.accId == 0){
        window.$alert.alert('선택된 계좌가 없습니다.', '알림')
        return;
      }

      // if (!this.getUsrAccPass){
      //   window.$alert.alert('계좌 비밀번호를 확인하세요.', '알림')
      //   return;
      // }

      //주문내역이 있으면 리턴 결과 오기전 재주문 불가
      if (!this.btnCancelSellAllFlag){
        return;
      }

      this.btnCancelSellAllFlag = false;

      const order = {
        usrKey: Utils.getUuidv4(),
        accId : this.account.accId,
        symCd : this.symbol.name,
        symNo : this.symbol.symbolno,
        trdDt : '',
        ordSdCd : '1',
        //accPass : this.getUsrAccPass
      };

      if (this.orderSetting != undefined && this.orderSetting.clearMessageAlert){
        // this.$alert.confirm('매도주문을 취소 하시겠습니까?', '주문 확인')
        // .then((response) => {
        //   if (!response) {
        //     this.btnCancelSellAllFlag = true;
        //     return;
        //   }else{
        //     this.sendOmsMessage("OMS0010", order);
        //   }
        // });
        const orderData = {apiCode:"OMS0010", order: order, symbol:this.symbol, qty:0, price: 0};
        this.$modal.show("modalConfirmOrder", orderData);
      }else{
        this.sendOmsMessage("OMS0010", order);
      }

      //1.5초후 버튼 누를 수 있게 설정
      if (!this.btnCancelSellAllFlag){
        setTimeout(() => {
          this.btnCancelSellAllFlag = true;
        }, 1500);
      }
    },
    cancelBuyAll(){ //BUY 전체 취소
      if (this.account == undefined || this.account.accId == undefined ||this.account.accId == 0){
        window.$alert.alert('선택된 계좌가 없습니다.', '알림');
        return;
      }

      // if (!this.getUsrAccPass){
      //   window.$alert.alert('계좌 비밀번호를 확인하세요.', '알림');
      //   return;
      // }

      //주문내역이 있으면 리턴 결과 오기전 재주문 불가
      if (!this.btnCancelBuyAllFlag){
        return;
      }

      this.btnCancelBuyAllFlag = false;

      const order = {
        usrKey: Utils.getUuidv4(),
        accId : this.account.accId,
        symCd : this.symbol.name,
        symNo : this.symbol.symbolno,
        trdDt : '',
        ordSdCd : '2',
        //accPass : this.getUsrAccPass
      };

      if (this.orderSetting != undefined && this.orderSetting.clearMessageAlert){
        // this.$alert.confirm('매수주문을 취소 하시겠습니까?', '주문 확인')
        // .then((response) => {
        //   if (!response) {
        //     this.btnCancelBuyAllFlag = true;
        //     return;
        //   }else{
        //     this.sendOmsMessage("OMS0010", order);
        //   }
        // });
        const orderData = {apiCode:"OMS0010", order: order, symbol:this.symbol, qty:0, price: 0};
        this.$modal.show("modalConfirmOrder", orderData);
      }else{
        this.sendOmsMessage("OMS0010", order);
      }

      //1.5초후 버튼 누를 수 있게 설정
      if (!this.btnCancelBuyAllFlag){
        setTimeout(() => {
          this.btnCancelBuyAllFlag = true;
        }, 1500);
      }
    },
    cancelSellStAll(){ //SELL STOP 전체 취소
      if (this.account == undefined || this.account.accId == undefined ||this.account.accId == 0){
        window.$alert.alert('선택된 계좌가 없습니다.', '알림');
        return;
      }

      // if (!this.getUsrAccPass){
      //   window.$alert.alert('계좌 비밀번호를 확인하세요.', '알림');
      //   return;
      // }

      //주문내역이 있으면 리턴 결과 오기전 재주문 불가
      if (!this.btnCancelSellStAllFlag){
        return;
      }

      this.btnCancelSellStAllFlag = false;

      const order = {
        usrKey: Utils.getUuidv4(),
        accId : this.account.accId,
        symCd : this.symbol.name,
        symNo : this.symbol.symbolno,
        trdDt : '',
        ordSdCd : '1',
        //accPass : this.getUsrAccPass
      };

      if (this.orderSetting != undefined && this.orderSetting.clearMessageAlert){
        // this.$alert.confirm('ST매도주문을 취소 하시겠습니까?', '주문 확인')
        // .then((response) => {
        //   if (!response) {
        //     this.btnCancelSellStAllFlag = true;
        //     return;
        //   }else{
        //     this.sendOmsMessage("OMS0011", order)
        //   }
        // });
        const orderData = {apiCode:"OMS0011", order: order, symbol:this.symbol, qty:0, price: 0};
        this.$modal.show("modalConfirmOrder", orderData);
      }else{
        this.sendOmsMessage("OMS0011", order)
      }

      //1.5초후 버튼 누를 수 있게 설정
      if (!this.btnCancelSellStAllFlag){
        setTimeout(() => {
          this.btnCancelSellStAllFlag = true;
        }, 1500);
      }
    },
    cancelBuyStAll(){ //BUY STOP 전체 취소
      if (this.account == undefined || this.account.accId == undefined ||this.account.accId == 0){
        window.$alert.alert('선택된 계좌가 없습니다.', '알림');
        return;
      }

      // if (!this.getUsrAccPass){
      //   window.$alert.alert('계좌 비밀번호를 확인하세요.', '알림');
      //   return;
      // }
      
      //주문내역이 있으면 리턴 결과 오기전 재주문 불가
      if (!this.btnCancelBuyStAllFlag){
        return;
      }

      this.btnCancelBuyStAllFlag = false;

      const order = {
        usrKey: Utils.getUuidv4(),
        accId : this.account.accId,
        symCd : this.symbol.name,
        symNo : this.symbol.symbolno,
        trdDt : '',
        ordSdCd : '2',
        //accPass : this.getUsrAccPass
      };

      if (this.orderSetting != undefined && this.orderSetting.clearMessageAlert){
        // this.$alert.confirm('ST매수주문을 취소 하시겠습니까?', '주문 확인')
        // .then((response) => {
        //   if (!response) {
        //     this.btnCancelBuyStAllFlag = true;
        //     return;
        //   }else{
        //     this.sendOmsMessage("OMS0011", order);
        //   }
        // });
        const orderData = {apiCode:"OMS0011", order: order, symbol:this.symbol, qty:0, price: 0};
        this.$modal.show("modalConfirmOrder", orderData);
      }else{
        this.sendOmsMessage("OMS0011", order);
      }
      
      //1.5초후 버튼 누를 수 있게 설정
      if (!this.btnCancelBuyStAllFlag){
        setTimeout(() => {
          this.btnCancelBuyStAllFlag = true;
        }, 1500);
      }
    },

    sendOmsMessage(apiCode, body) {
      if ( !window.$store.getters['SocketOms/getIsConnectOms'] ) {
        window.$alert.alert('주문서버 연결이 불안정합니다.\n새로고침 후 다시 시도해주세요.');
        return;
      }

      //비밀번호 추가
      //body.accPass = this.getUsrAccPass;

      window.$store.dispatch('SocketOms/sendOmsMessage', {apiCode:apiCode, body:body});
    }, 
    sendOmsLogMessage(message){ // 사용자 로그 저장
      window.$store.dispatch('SocketOms/setLogMsg', {message:message});
    },
    //손절 틱설정값 저장
    setSellSlTick(tickVal){
      this.sellSlTick = Object.assign(Number(tickVal));
    },
    //익절 틱설정값 저장
    setBuySlTick(tickVal){
      this.buySlTick = Object.assign(Number(tickVal));
    },
    setClickOrd(val){
      this.ordClick = Object.assign(Number(val));
    },
    setOrdClick(val){
      this.ordClick = Object.assign(Number(val));
    },
    sellSlStock() {
      if (this.account == undefined || this.account.accId == undefined ||this.account.accId == 0){
        window.$alert.alert('선택된 계좌가 없습니다.', '알림');
        return;
      }

      // if (!this.getUsrAccPass){
      //   window.$alert.alert('계좌 비밀번호를 확인하세요.', '알림')
      //   return;
      // }

      const order = {
        usrKey: Utils.getUuidv4(),
        accId : this.account.accId,
        symCd : this.symbol.name,
        symNo : this.symbol.symbolno,
        trdDt : '',
        ordId : 0,
        orgOrdNo: 0,
        ordSdCd : '1',
        ordTpCd : '1',
        ordPrc : 0,
        tickVal : this.sellSlTick,
        chkVal : '2', // '1' 익절 '2' 손절
        lv : this.leverage,
        //accPass : this.getUsrAccPass
      };
      let message = "손절";
      
      let apiCode = "OMS0007";
      if (this.slStockVal2){ //체크값이 true이면 삭제처리
        apiCode = "OMS0008";
        message += "해제";
      }else{
        message += "체크";
      }

      message += ":"+this.sellSlTick;

      this.sendOmsLogMessage(message);

      this.sendOmsMessage(apiCode, order);
    },
    buySlStock() {
      if (this.account == undefined || this.account.accId == undefined ||this.account.accId == 0){
        window.$alert.alert('선택된 계좌가 없습니다.', '알림')
        return;
      }

      // if (!this.getUsrAccPass){
      //   window.$alert.alert('계좌 비밀번호를 확인하세요.', '알림')
      //   return;
      // }

      const order = {
        usrKey: Utils.getUuidv4(),
        accId : this.account.accId,
        symCd : this.symbol.name,
        symNo : this.symbol.symbolno,
        trdDt : '',
        ordId : 0,
        orgOrdNo: 0,
        ordSdCd : '1',
        ordTpCd : '1',
        ordPrc : 0,
        tickVal : this.buySlTick,
        chkVal : '1', // '1' 익절 '2' 손절 
        lv : this.leverage,
        //accPass : this.getUsrAccPass
      };

      let message = "익절";      
      let apiCode = "OMS0007";
      if (this.slStockVal1){ //체크값이 true이면 삭제처리
        apiCode = "OMS0008";
        message += "해제";
      }else{
        message += "체크";
      }
      message += ":"+this.buySlTick;
      
      this.sendOmsLogMessage(message);
      this.sendOmsMessage(apiCode, order);
    },
    updateSlStock(data){
      if (data.chkType == "2"){ //손절
        this.slStockVal2 = data.chkVal;
        if (this.slStockVal2) this.sellSlTick = data.tickVal;
      }else if (data.chkType == "1"){ //익절
        this.slStockVal1 = data.chkVal;
        if (this.slStockVal1) this.buySlTick = data.tickVal;
      }      
    },
    clearOrderData(type){ //전/현 종목 청산
      if (this.account == undefined || this.account.accId == undefined ||this.account.accId == 0){
        window.$alert.alert('선택된 계좌가 없습니다.', '알림')
        return;
      }

      // if (!this.getUsrAccPass){
      //   window.$alert.alert('계좌 비밀번호를 확인하세요.', '알림')
      //   return;
      // }

      //주문내역이 있으면 리턴 결과 오기전 재주문 불가
      if (!this.btnCancelAllFlag){
        return;
      }
      
      if (type == 'all' || type == 'symbol'){
        let msg = "현종목";
        let symCd = this.symbol.name;
        let symNo = this.symbol.symbolno;
        if (type == 'all'){
          msg = "전종목";
          symCd = "";
        }

        this.btnCancelAllFlag = false;

        const order = {
          usrKey: Utils.getUuidv4(),
          accId : this.account.accId,
          symCd : symCd,
          symNo : symNo,
          trdDt : '',
          clearType : type,
          accPass : this.getUsrAccPass,
        }

        let message = msg;
        message += " 청산 클릭";
        this.sendOmsLogMessage(message);

        if (this.orderSetting != undefined && this.orderSetting.clearMessageAlert){
          const orderData = {apiCode:"OMS0013", order: order, symbol:this.symbol, qty:0, price: 0, message:message};
          this.$modal.show("modalConfirmOrder", orderData);
        }else{
          this.sendOmsMessage("OMS0013", order);
        }
      }

      //1.5초후 버튼 누를 수 있게 설정
      if (!this.btnCancelAllFlag){
        setTimeout(() => {
          this.btnCancelAllFlag = true
        }, 1500)
      }
    },
    //잔고 내역 업데이트
    updateTotalValPl(newVal){
      this.totalValPl = Object.assign({}, newVal);
      this.dailyPlMn.totalLossCut = newVal.totalLossCut != 0 && Number(newVal.totalLossCut) > 0 ? newVal.totalLossCut / this.leverage : newVal.totalLossCut;
      this.dailyPlMn.totalValPl = newVal.totalValPl;
      //this.dailyPlMn.balMn = newVal.balMn;
    },
    //담보금 업데이트
    updateDailyPlMn(newVal){
      //console.log("updateDailyPlMn ", newVal)
    },
    popupOrder(){
      const popupId1 = localStorage.getItem('popupId1');
      const popupId2 = localStorage.getItem('popupId2');

      const popWidth = 1136;
      const popHeight = 828;

      let popupCnt = 0;
      if (this.getLoginUserInfo.popupCnt != undefined){
        popupCnt = this.getLoginUserInfo.popupCnt;
      }

      this.sendOmsLogMessage("팝업주문 클릭");

      if (this.popupId1 == null && popupCnt > 0){
        //팝업창 호출
        this.popupId1 = window.open("/orderPop", "orderPop1", "resizeable=no, width="+popWidth+", height="+popHeight);
      }else if (this.popupId1 != null && this.popupId1.closed && popupCnt > 0){ //팝업창이 닫혀있으면 갱신
        //팝업창 호출
        this.popupId1 = window.open("/orderPop", "orderPop1", "resizeable=no, width="+popWidth+", height="+popHeight);
      }else if (this.popupId1 != null && this.popupId2 == null && popupCnt > 1){
        //팝업창 호출
        this.popupId2 = window.open("/orderPop", "orderPop2", "resizeable=no, width="+popWidth+", height="+popHeight);
      }else if (this.popupId1 != null && this.popupId2 != null && this.popupId2.closed && popupCnt > 1){ //팝업창이 닫혀있으면 갱신
        //팝업창 호출
        this.popupId2 = window.open("/orderPop", "orderPop2", "resizeable=no, width="+popWidth+", height="+popHeight);
      }else if (popupCnt == 0)   {
        window.$alert.alert('팝업주문 권한이 없습니다.', '알림');
      }else{
        //팝업창 포커스
        if (popupCnt == 1){
          this.popupId1.focus();
        }else if (popupCnt == 2){
          this.popupId2.focus();
        }
      }
    },
    //팝업창 데이터 채널로 전달
    sendPopupData(type, data){
        this.dataChannel.postMessage({type:type, data:data});
    },
    sendEvtToChild( evt ){
      if(this.popupId == undefined ||this.popupId == null) {
        return;
      }

      this.popupId.document.getElementById("popText").value = '테스트'; 

      // if( g_winPopup == null ) {
      //   return;
      // }

      // 4. 윈도팝업창(g_winPopup)에 함수 실행 
      //  g_winPopup 변수는 본 소스에서 선언하지 않고 아래 ChildWinPop.vue 소스인 윈도우 팝업창 측에서 선언하고 설정함
      
    },
    //종목평가 업데이트
    setSymbolPrice(){
      const usdExr = this.dailyPlMn.usdExR;
      const hkdExR = this.dailyPlMn.hkdExR;
      const usdExHkd = this.dailyPlMn.usdExHkd;
      let data = this.symbolPlMn.data;

      // 계산 공통사용변경
      // data - 그리드 1개 row data
      // nowPrice OVC 데이터로 전달
      // symbolList
      // usdExr 환율
      // usdExHkd 홍콩달러환율
      // hkdExR 홍콩환율
      // lvTpCd 레버리지여부
      // currency 원/달러 구분 WON
      // return {data, }
      if (data != null){
        let res = Utils.symbolCalPrc(data, this.nowPrice, this.symbolList, usdExr, usdExHkd, hkdExR, data.lvTpCd, this.currencyInfo.currency);
        data = res.data; //데이터가 갱신 안되면 주석해제
        this.symbolPlMn.valPl = res.valProLoss;
      }

      // let pricescale = 1;
      // if (this.symbol.pricescale > 0){
      //   pricescale = this.symbol.pricescale;
      // }
      // if (this.nowPrice != undefined && this.nowPrice.curPr){
      //   //평가손익 계산
      //   let val1 = 0;
      //   let val2 = 0;
      //   let val3 = 0;
      //   let val4 = 0;
      //   let valProLoss = 0;
      //   if (this.nowPrice.curPr == 0 || data == undefined || data.ordSdCd == undefined || data.ordSdCd == 0){
      //     valProLoss = 0;
      //   }else{
      //     if (data.ordSdCd == "1"){ //매도
      //       val1 = ((data.execPrc.toFixed(this.tikDotSz) * pricescale) *  data.balQty);
      //       val2 = ((this.nowPrice.curPr * pricescale) * data.balQty);
      //     }else if (data.ordSdCd == "2"){ //매수
      //       val1 = ((this.nowPrice.curPr * pricescale) * data.balQty) ;
      //       val2 = (((data.execPrc.toFixed(this.tikDotSz) * pricescale) * data.balQty));
      //     }
          

      //     //달러로 계산
      //     if (data.symNo == "2"){ //홍콩달러
      //       valProLoss = ((val1 - val2) * data.trdUnt);
      //     }else{
      //       valProLoss = ((val1 - val2) / this.tikVal(this.symbol)) * data.trdUnt; //달러로 계산
      //     }

      //     if (isNaN(valProLoss)) valProLoss = 0;
          

      //     //원화로 표시
      //     if (this.currencyInfo.currency == "WON"){
      //       if (data.symNo == "2"){
      //         valProLoss = Math.round((valProLoss * hkdExR));
      //       }else if (data.symNo == "10"){
      //         valProLoss = Math.round(valProLoss / pricescale) ;
      //       }else{
      //         valProLoss = Math.round((valProLoss * usdExr) / pricescale ) ;
      //       }

      //       //data.valPl = valProLoss.toFixed(1);
      //     }else{ //달러
      //       //data.valPl = valProLoss.toFixed(2);
      //     }
      //   }
      //   this.symbolPlMn.valPl = valProLoss;
      // }
    },
    //일반주문 수량 설정
    setStdOrdQty(stdQty){
      this.ordStdQty = Number(stdQty);
    },
    setOrdStdPrc(stdPrc){
      this.ordStdPrc = Number(stdPrc);
    },
    stdReset(){ //일반주문 초기화
      this.ordStdQty = 1;
      this.ordStdPrc = this.nowPrice.curPr;
      this.sendOmsLogMessage("일반 주문창 초기화 버튼 클릭");
    },
    stdSellBuy(type){
      if (!this.ordStdType){
        window.$alert.alert('유형을 선택하세요.', '알림');
        return;
      }else if (!this.ordStdQty || this.ordStdQty < 1){
        window.$alert.alert('수량을 입력 해주세요.', '알림');
        return;        
      }else if (!this.ordStdPrc || this.ordStdPrc <= 0){
        window.$alert.alert('가격을 입력 해주세요.', '알림');
        return;        
      }

      if ((type == "buy" && this.buyAbleQty == 0) || (type == "sell" && this.sellAbleQty == 0)){
        // this.$notify({ group: 'notification', clean: true })
        // this.$notify({
        //   group : 'notification',
        //   title : 'FAIL',
        //   text : '[주문거부] 주문가능 수량이 부족합니다.'
        // });

        window.$alert.alert('주문가능 수량이 부족합니다.', '알림');
        return;
      }

      //주문내역이 있으면 리턴 결과 오기전 재주문 불가
      if (!this.btnStdFlag){
        return;
      }

      let ordSdCd = "0";
      let message = "일반 주문창 주문";
      if (type == "sell"){
        ordSdCd = "1";
        message += " 매도";
      }else if (type == "buy"){
        ordSdCd = "2";
        message += " 매수";
      }

      message += " " + this.symbol.name;

      let ordTpCd = "0";
      let apiCode = "";
      let stTpCd = "2";
      let ordPrc = 0;

      if (this.ordStdType == "1"){ //시장가
        ordTpCd = "2";
        apiCode = "OMS0001";
        message += "/시장가";
      }else if (this.ordStdType == "2"){ //지정가
        ordTpCd = "1";
        apiCode = "OMS0001";
        ordPrc = this.ordStdPrc;
        message += "/지정가";
      }else if (this.ordStdType == "3"){ //MIT
        ordTpCd = "2";
        apiCode = "OMS0004";
        ordPrc = this.ordStdPrc;
        message += "/MIT";
        
        //stTpCd : 스탑유형코드 // '1' 위방향 '2' 아래방향 (주문가격이 현재가보다 클경우 1 아닌경우 2)
        if (Number(this.nowPrice.curPr) == Number(this.ordStdPrc)){
          this.$notify({ group: 'notification', clean: true })
  
          this.$notify({
            group : 'notification',
            title : 'FAIL',
            text : '[주문거부] 현재가에 MIT주문이 불가능합니다.'
          });
          return;
        } else if (this.nowPrice.curPr < Number(this.ordStdPrc)){
          stTpCd = "1";
        }
      }

      message += "/수량 "+this.ordStdQty + "/가격 "+ordPrc;

      this.btnStdFlag = false;

      const order = {
        usrKey: Utils.getUuidv4(),
        accId : this.account.accId,
        symCd : this.symbol.name,
        symNo : this.symbol.symbolno,
        trdDt : '',
        ordId : 0,
        orgOrdNo: 0,
        ordSdCd : ordSdCd,
        ordTpCd : ordTpCd,
        ordPrc : ordPrc,
        ordQty : this.ordStdQty,
        repReqQty : 0,
        cxlReqQty : 0,
        stTpCd : stTpCd,
        accPass : this.getUsrAccPass,
        lv : this.leverage,
      }

      this.sendOmsLogMessage(message);

      if (this.orderSetting != undefined && this.orderSetting.ordMessageAlert){
        const orderData = {apiCode:apiCode, order: order, symbol:this.symbol ,qty:this.ordStdQty, price: Number(ordPrc), message:message};
        this.$modal.show("modalConfirmOrder", orderData);
      }else{
        this.sendOmsMessage(apiCode, order);
      }

      //0.3초후 버튼 누를 수 있게 설정
      if (!this.btnStdFlag){
        setTimeout(() => {
          this.btnStdFlag = true;
        }, 300);
      }
    },
    completeOrderSetting(){
      EventBus.$emit('Layout:event:loadLayout', this.layout)
    },
    completeDepositWithdraw(){ //입출금 완료 후
      //유저 데이터 재조회
      window.$store.dispatch('SocketOms/selectUsrData', {accId:this.account.accId, symCd:this.symbol.name, symNo:this.symbol.symbolno, lv:this.leverage});
    },
    setLogMsg(){ //사용자 로그 설정
      let logOrder = {
        usrKey: Utils.getUuidv4(),
        accId : this.account.accId,
        symCd : this.symbol.name,
        symNo : this.symbol.symbolno,
        message : "",
        time : Utils.getNowTime(),
      }
  
      //console.log("logOrder >>>> ",logOrder)
        //this.sendOmsMessage("OMS9991", logOrder);
    },
    setServerExec(){ //서버 주문테스트
        const trdDt = Utils.getToday().toString().replace(/[-]/g, '');

        // 청산 테스트용
        let order = {
          usrKey: Utils.getUuidv4(),
          accId : this.account.accId,
          symCd : this.symbol.name,
          symNo : this.symbol.symbolno,
          trdDt : trdDt,
          cpnId : this.getLoginUserInfo.cpnId
        }
  
        this.sendOmsMessage("OMS9995", order);
    },
    getServerStat(){
      // 서버구분  주문서버 ORDER, 체결서버 : EXEC, 체결처리: EXECU, 스탑 : STOP , 스탑로스 : STOPLS
      let order = {
      usrKey: Utils.getUuidv4(),
      accId : this.account.accId,
      symCd : this.symbol.name,
      symNo : this.symbol.symbolno,
      serverType : "STOP",
      }

      this.sendOmsMessage("OMS9997", order);

      order = {
      usrKey: Utils.getUuidv4(),
      accId : this.account.accId,
      symCd : this.symbol.name,
      symNo : this.symbol.symbolno,
      serverType : "EXEC",
      }

      this.sendOmsMessage("OMS9997", order);

      order = {
      usrKey: Utils.getUuidv4(),
      accId : this.account.accId,
      symCd : this.symbol.name,
      symNo : this.symbol.symbolno,
      serverType : "STOPLS",
      }

      this.sendOmsMessage("OMS9997", order);

      order = {
      usrKey: Utils.getUuidv4(),
      accId : this.account.accId,
      symCd : this.symbol.name,
      symNo : this.symbol.symbolno,
      serverType : "ORDER",
      }

      this.sendOmsMessage("OMS9997", order);

      order = {
      usrKey: Utils.getUuidv4(),
      accId : this.account.accId,
      symCd : this.symbol.name,
      symNo : this.symbol.symbolno,
      serverType : "EXECU",
      }

      this.sendOmsMessage("OMS9997", order);

      order = {
      usrKey: Utils.getUuidv4(),
      accId : this.account.accId,
      symCd : this.symbol.name,
      symNo : this.symbol.symbolno,
      serverType : "RMS",
      }

      this.sendOmsMessage("OMS9997", order);

      order = {
      usrKey: Utils.getUuidv4(),
      accId : this.account.accId,
      symCd : this.symbol.name,
      symNo : this.symbol.symbolno,
      serverType : "LOG",
      }

      this.sendOmsMessage("OMS9997", order);
    },
    //팝업 데이터 처리
    async popupData(e){
      //console.log(e.origin, e.data);
      //팝업창 종료시 호출
      const data = e.data;
      if (data.type != undefined && data.type == "closePopup"){
        if (data.popupId != undefined && data.popupId == "orderPop1"){
          this.popupId1 = null;
          this.$sessionStorage.remove('popup1SelSymbol'); //팝업1 symbol 설정 삭제
        }else if (data.popupId != undefined && data.popupId == "orderPop2"){
          this.popupId2 = null;
          this.$sessionStorage.remove('popup2SelSymbol'); //팝업1 symbol 설정 삭제
        }
        //시세,호가 목록 업데이트
        this.updateSubscribeOnPrice();
      }else if (data.type != undefined && data.type == "addSymbol"){
        if (data.popupId != undefined && data.popupId == "orderPop1"){
          this.$sessionStorage.set('popup1SelSymbol', JSON.stringify(data.symbol));
        }else if (data.popupId != undefined && data.popupId == "orderPop2"){
          this.$sessionStorage.set('popup2SelSymbol', JSON.stringify(data.symbol));
        }


        //console.log("popup1SelSymbol", this.$sessionStorage.get('popup1SelSymbol'))

        //시세,호가 목록 업데이트
        this.updateSubscribeOnPrice();
      }else if (data.type != undefined && data.type == "resizeTo"){
        if (data.popupId != undefined && data.popupId == "orderPop1"){
          if (this.popupId1){
            this.popupId1.resizeTo(data.width, data.height);
          }else{
            //this.$alert.alert('팝업주문창을 찾을 수 없습니다. 팝업창을 닫고 다시 시도 해주세요.', '안내');
            this.sendPopupData("resizeTo", data);
            return;
          }
        }else if (data.popupId != undefined && data.popupId == "orderPop2"){
          if (this.popupId2){
            this.popupId2.resizeTo(data.width, data.height);
          }else{
            //this.$alert.alert('팝업주문창을 찾을 수 없습니다. 팝업창을 닫고 다시 시도 해주세요.', '안내');
            this.sendPopupData("resizeTo", data);
            return;
          }
        }
      }else if (data.type != undefined && data.type == "selectLeverage"){
        this.selectLeverage(data.leverage);
      }
    },
    async selectLeverage(leverage){
      //레버리지 선택 주문정보 확인
      
      const data = {accNo:this.account.accNo, symCd:this.symbol.name};

      await this.$lhttp.get('/api/order/rtPosOrdChk', { params: data }).then((response) => {
        if (response.code == 200){
          if (response.data == null){
            this.$alert.alert('잔고/미체결 조회중 에러가 발생했습니다.', '안내');
            return;
          }else if (response.data.ordQty != undefined && response.data.ordQty > 0){
            this.$alert.alert('잔고/미체결 내역이 있으면 레버리지 변경 불가합니다.', '안내');
            this.leverage = this.orderSetting.leverage;
            return;
          }else{
            this.leverage = leverage.cd;
            this.orderSetting.leverage = leverage.cd;

            //가능 수량 조회
            window.$store.dispatch('SocketOms/selectAbleQty', {accId:this.account.accId, symCd:this.symbol.name, symNo:this.symbol.symbolno, lv:leverage.cd});

            window.$store.dispatch('Layout/saveCustom', { type: 'OS', custom: this.orderSetting});
            
            EventBus.$emit('Layout:event:loadLayout', this.layout);
          }
        }
      });
    },
    setCpnAuth(cpnAuthList){
      //커스텀 화면 설정
      //console.log("cpnAuthList", cpnAuthList)

      if (cpnAuthList != undefined && cpnAuthList.length > 0){ //커스텀 권한 설정
        let stdordYn = "N";
        cpnAuthList.forEach(item=>{
          if (item.authTpCd == "PCSTDORD"){ //일반주문 표시여부
            this.stdOrdShow = false;
            stdordYn = "Y";
            if (item.useYn == "N"){
              this.stdOrdShow = false;
            }else{
              this.stdOrdShow = true;
            }
          }
        });
        //일반주문 표시여부가 없으면 보이도록 설정
        if (stdordYn == "N"){
          this.stdOrdShow = true;
        }
      }else{
        this.stdOrdShow = true;
      }

    },
    disconnect(){
      console.log("disconnect....");
      //oms
      if (window.$store.getters['SocketOms/getIsConnectOms']) {
        //
        window.$store.dispatch('SocketOms/setDisconnect');
      }

      //소켓 시세
      if (window.$store.getters['SocketPrice/getIsConnectPrice']) {
        //setDisconnect
        window.$store.dispatch('SocketPrice/setDisconnect');
      }

      //폴링 시세
      if (window.$store.getters['SocketPrice/getIsPolingPrice']) {
        //clear polling
        window.$store.dispatch('SocketPrice/setClearPolling');
      }

      // //메시지
      // if (window.$store.getters['SocketMsg/getIsConnectMsg']) {
      //   //clear polling
      //   window.$store.dispatch('SocketMsg/setDisconnect');
      // }
    },
  },
  destroyed() {
    this.sendPopupData('CLOSE');
    this.disconnect();   
  },
}
</script>
